{ 
        "DancerFemale" : {"hp":124, "move":4,  "rank":55, "desc" : "Dancer Female's base stats: 124 HP, 47 MP, 3 Move, 3 Jump, 9 Speed, 8 PA, 9 MA, 10% C-EV. Innates: Dance. Equips: Knives, Bags, Fabrics, Hats, Clothing."},
        "SquireFemale" : {"hp":162, "move":4,  "rank":49, "desc" : "Squire Female's base stats: 162 HP, 50 MP, 4 Move, 3 Jump, 9 Speed, 7 PA, 8 MA, 7% C-EV. Innates: Basic Skill. Equips: Knives, Swords, Axes, Flails, Crossbows, Hats, Clothing, uncommonly [Shields, Armor, and Helmets]."},
        "ChemistFemale" : {"hp":135, "move":4,  "rank":42, "desc" : "Chemist Female's base stats: 135 HP, 45 MP, 3 Move, 3 Jump, 8 Speed, 5 PA, 7 MA, 5% C-EV. Innates: Item, Throw Item. Equips: Knives, Guns, Bags, Hats, Clothing."},
        "KnightFemale" : {"hp":189, "move":4,  "rank":40, "desc" : "Knight Female's base stats: 189 HP, 44 MP, 3 Move, 3 Jump, 8 Speed, 10 PA, 7 MA, 12% C-EV. Innates: Battle Skill. Equips: Swords, Knight Swords, Axes, Shields, Helmets, Armor, Robes."},
        "ArcherFemale" : {"hp":154, "move":4,  "rank":41, "desc" : "Archer Female's base stats: 154 HP, 39 MP, 3 Move, 3 Jump, 10 Speed, 9 PA, 8 MA, 17% C-EV. Innates: Charge. Equips: Guns, Crossbows, Bows, Shields, Hats, Clothing."},
        "MonkFemale" : {"hp":196, "move":4,  "rank":61, "desc" : "Monk Female's base stats: 196 HP, 55 MP, 3 Move, 4 Jump, 9 Speed, 10 PA, 8 MA, 22% C-EV. Innates: Punch Art, Martial Arts. Equips: Hats, Clothing."},
        "PriestFemale" : {"hp":150, "move":4,  "rank":20, "desc" : "Priest Female's base stats: 150 HP, 101 MP, 3 Move, 3 Jump, 9 Speed, 7 PA, 10 MA, 5% C-EV. Innates: White Magic. Equips: Staves, Flails, Hats, Clothing, Robes."},
        "WizardFemale" : {"hp":121, "move":4,  "rank":11, "desc" : "Wizard Female's base stats: 121 HP, 109 MP, 3 Move, 3 Jump, 8 Speed, 4 PA, 14 MA, 5% C-EV. Innates: Black Magic. Equips: Knives, Rods, Hats, Clothing, Robes."},
        "TimeMageFemale" : {"hp":132, "move":4,  "rank":32, "desc" : "TimeMage Female's base stats: 132 HP, 101 MP, 3 Move, 3 Jump, 8 Speed, 4 PA, 13 MA, 7% C-EV. Innates: Time Magic. Equips: Staves, Books, Hats, Clothing, Robes."},
        "SummonerFemale" : {"hp":110, "move":4,  "rank":19, "desc" : "Summoner Female's base stats: 110 HP, 124 MP, 3 Move, 3 Jump, 8 Speed, 3 PA, 12 MA, 5% C-EV. Innates: Summon Magic. Equips: Rods, Staves, Hats, Clothing, Robes."},
        "ThiefFemale" : {"hp":145, "move":4,  "rank":51, "desc" : "Thief Female's base stats: 145 HP, 36 MP, 4 Move, 4 Jump, 10 Speed, 8 PA, 7 MA, 30% C-EV. Innates: Steal. Equips: Knives, Ninja Blades, Swords, Hats, Clothing."},
        "MediatorFemale" : {"hp":140, "move":4,  "rank":54, "desc" : "Mediator Female's base stats: 140 HP, 41 MP, 3 Move, 3 Jump, 9 Speed, 6 PA, 9 MA, 5% C-EV. Innates: Talk Skill, Monster Talk. Equips: Knives, Guns, Books, Hats, Clothing, Robes."},
        "OracleFemale" : {"hp":131, "move":4,  "rank":34, "desc" : "Oracle Female's base stats: 131 HP, 92 MP, 3 Move, 3 Jump, 8 Speed, 4 PA, 11 MA, 7% C-EV. Innates: Yin Yang Magic. Equips: Books, Poles, Hats, Clothing, Robes."},
        "GeomancerFemale" : {"hp":173, "move":4,  "rank":28, "desc" : "Geomancer Female's base stats: 173 HP, 74 MP, 4 Move, 3 Jump, 8 Speed, 9 PA, 10 MA, 10% C-EV. Innates: Elemental. Equips: Swords, Katanas, Axes, Shields, Hats, Clothing, Robes."},
        "LancerFemale" : {"hp":181, "move":4,  "rank":65, "desc" : "Lancer Female's base stats: 181 HP, 34 MP, 3 Move, 4 Jump, 8 Speed, 10 PA, 5 MA, 15% C-EV. Innates: Jump. Equips: Spears, Poles, Shields, Helmets, Hats, Armor, Robes."},
        "SamuraiFemale" : {"hp":127, "move":4,  "rank":24, "desc" : "Samurai Female's base stats: 127 HP, 59 MP, 3 Move, 3 Jump, 8 Speed, 11 PA, 9 MA, 20% C-EV. Innates: Draw Out. Equips: Katanas, Spears, Helmets, Hats, Armor, Robes."},
        "NinjaFemale" : {"hp":112, "move":4,  "rank":37, "desc" : "Ninja Female's base stats: 112 HP, 34 MP, 4 Move, 3 Jump, 11 Speed, 9 PA, 6 MA, 25% C-EV. Innates: Throw, Dual Wield. Equips: Knives, Ninja Swords, Flails, Hats, Clothing."},
        "CalculatorFemale" : {"hp":101, "move":4,  "rank":67, "desc" : "Calculator Female's base stats: 101 HP, 67 MP, 3 Move, 3 Jump, 7 Speed, 5 PA, 8 MA, 3% C-EV. Innates: Math Skill. Equips: Rods, Books, Poles, Hats, Clothing, Robes, if Blue Mage [Helmets and Armor]."},
        "SquireMale" : {"hp":173, "move":4,  "rank":56, "desc" : "Squire Male's base stats: 173 HP, 47 MP, 4 Move, 3 Jump, 9 Speed, 8 PA, 7 MA, 7% C-EV. Innates: Basic Skill. Equips: Knives, Swords, Axes, Flails, Crossbows, Hats, Clothing, uncommonly [Shields, Armor, and Helmets]."},
        "ChemistMale" : {"hp":144, "move":4,  "rank":36, "desc" : "Chemist Male's base stats: 144 HP, 42 MP, 3 Move, 3 Jump, 8 Speed, 6 PA, 6 MA, 5% C-EV. Innates: Item, Throw Item. Equips: Knives, Guns, Bags, Hats, Clothing."},
        "KnightMale" : {"hp":202, "move":4,  "rank":18, "desc" : "Knight Male's base stats: 202 HP, 41 MP, 3 Move, 3 Jump, 8 Speed, 12 PA, 6 MA, 12% C-EV. Innates: Battle Skill. Equips: Swords, Knight Swords, Axes, Shields, Helmets, Armor, Robes."},
        "ArcherMale" : {"hp":165, "move":4,  "rank":17, "desc" : "Archer Male's base stats: 165 HP, 36 MP, 3 Move, 3 Jump, 10 Speed, 11 PA, 6 MA, 17% C-EV. Innates: Charge. Equips: Guns, Crossbows, Bows, Shields, Hats, Clothing."},
        "MonkMale" : {"hp":210, "move":4,  "rank":21, "desc" : "Monk Male's base stats: 210 HP, 52 MP, 3 Move, 4 Jump, 9 Speed, 12 PA, 7 MA, 22% C-EV. Innates: Punch Art, Martial Arts. Equips: Hats, Clothing."},
        "PriestMale" : {"hp":160, "move":4,  "rank":45, "desc" : "Priest Male's base stats: 160 HP, 94 MP, 3 Move, 3 Jump, 9 Speed, 9 PA, 8 MA, 5% C-EV. Innates: White Magic. Equips: Staves, Flails, Hats, Clothing, Robes."},
        "WizardMale" : {"hp":130, "move":4,  "rank":59, "desc" : "Wizard Male's base stats: 130 HP, 102 MP, 3 Move, 3 Jump, 8 Speed, 5 PA, 11 MA, 5% C-EV. Innates: Black Magic. Equips: Knives, Rods, Hats, Clothing, Robes."},
        "TimeMageMale" : {"hp":133, "move":4,  "rank":44, "desc" : "TimeMage Male's base stats: 133 HP, 94 MP, 3 Move, 3 Jump, 8 Speed, 6 PA, 10 MA, 7% C-EV. Innates: Time Magic. Equips: Staves, Books, Hats, Clothing, Robes."},
        "SummonerMale" : {"hp":118, "move":4,  "rank":73, "desc" : "Summoner Male's base stats: 118 HP, 116 MP, 3 Move, 3 Jump, 8 Speed, 4 PA, 9 MA, 5% C-EV. Innates: Summon Magic. Equips: Rods, Staves, Hats, Clothing, Robes."},
        "ThiefMale" : {"hp":156, "move":4,  "rank":33, "desc" : "Thief Male's base stats: 156 HP, 33 MP, 4 Move, 4 Jump, 10 Speed, 10 PA, 5 MA, 30% C-EV. Innates: Steal. Equips: Knives, Ninja Blades, Swords, Hats, Clothing."},
        "MediatorMale" : {"hp":149, "move":4,  "rank":46, "desc" : "Mediator Male's base stats: 149 HP, 38 MP, 3 Move, 3 Jump, 9 Speed, 8 PA, 7 MA, 5% C-EV. Innates: Talk Skill, Monster Talk. Equips: Knives, Guns, Books, Hats, Clothing, Robes."},
        "OracleMale" : {"hp":140, "move":4,  "rank":47, "desc" : "Oracle Male's base stats: 140 HP, 86 MP, 3 Move, 3 Jump, 8 Speed, 5 PA, 9 MA, 7% C-EV. Innates: Yin Yang Magic. Equips: Books, Poles, Hats, Clothing, Robes."},
        "GeomancerMale" : {"hp":185, "move":4,  "rank":23, "desc" : "Geomancer Male's base stats: 185 HP, 70 MP, 4 Move, 3 Jump, 8 Speed, 12 PA, 8 MA, 10% C-EV. Innates: Elemental. Equips: Swords, Katanas, Axes, Shields, Hats, Clothing, Robes."},
        "LancerMale" : {"hp":194, "move":4,  "rank":22, "desc" : "Lancer Male's base stats: 194 HP, 32 MP, 3 Move, 4 Jump, 8 Speed, 13 PA, 4 MA, 15% C-EV. Innates: Jump. Equips: Spears, Poles, Shields, Helmets, Hats, Armor, Robes."},
        "SamuraiMale" : {"hp":136, "move":4,  "rank":38, "desc" : "Samurai Male's base stats: 136 HP, 55 MP, 3 Move, 3 Jump, 8 Speed, 14 PA, 7 MA, 20% C-EV. Innates: Draw Out. Equips: Katanas, Spears, Helmets, Hats, Armor, Robes."},
        "NinjaMale" : {"hp":113, "move":4,  "rank":16, "desc" : "Ninja Male's base stats: 113 HP, 32 MP, 4 Move, 3 Jump, 11 Speed, 11 PA, 5 MA, 25% C-EV. Innates: Throw, Dual Wield. Equips: Knives, Ninja Swords, Flails, Hats, Clothing."},
        "CalculatorMale" : {"hp":108, "move":4,  "rank":66, "desc" : "Calculator Male's base stats: 108 HP, 63 MP, 3 Move, 3 Jump, 7 Speed, 7 PA, 6 MA, 3% C-EV. Innates: Math Skill. Equips: Rods, Books, Poles, Hats, Clothing, Robes, if Blue Mage [Helmets and Armor]."},
        "BardMale" : {"hp":123, "move":4,  "rank":39, "desc" : "Bard Male's base stats: 123 HP, 48 MP, 3 Move, 3 Jump, 9 Speed, 8 PA, 9 MA, 10% C-EV. Innates: Sing. Equips: Bows, Harps, Hats, Clothing."},
        "Chocobo" : {"hp":277, "move":4,  "rank":76, "desc" : "Chocobo's base stats: 277 HP, 21 MP, 6 Move, 5 Jump, 9 Speed, 12 PA, 36 MA, 20% C-EV. Innates: Counter. Elements: Half-Lightning, Weak-Wind."},
        "BlackChocobo" : {"hp":236, "move":4,  "rank":35, "desc" : "BlackChocobo's base stats: 236 HP, 32 MP, 6 Move, 5 Jump, 8 Speed, 18 PA, 41 MA, 25% C-EV. Innates: Counter, Fly. Elements: Half-Earth&Dark, Weak-Wind."},
        "MimeFemale" : {"hp":218, "move":4,  "rank":71, "desc" : "Mime Male's base stats: 218 HP, 18 MP, 3 Move, 4 Jump, 10 Speed, 13 PA, 11 MA, 7% C-EV. Innates: Mimic, Concentrate, Beastmaster. Equips: Hats, Clothing, one of [Shields, Helmets, Armor or Robes]."},
        "MimeMale" : {"hp":218, "move":4,  "rank":43, "desc" : "Mime Male's base stats: 218 HP, 18 MP, 3 Move, 4 Jump, 10 Speed, 13 PA, 11 MA, 7% C-EV. Innates: Mimic, Concentrate, Beastmaster. Equips: Hats, Clothing, one of [Shields, Helmets, Armor or Robes]."},
        "RedChocobo" : {"hp":356, "move":4,  "rank":8, "desc" : "RedChocobo's base stats: 356 HP, 19 MP, 6 Move, 4 Jump, 10 Speed, 15 PA, 38 MA, 10% C-EV. Innates: Counter, Ignore Height. Elements: Weak Wind."},
        "Goblin" : {"hp":309, "move":4,  "rank":93, "desc" : "Goblin's base stats: 309 HP, 8 MP, 4 Move, 3 Jump, 8 Speed, 11 PA, 33 MA, 18% C-EV. Innates: Counter, Beastmaster. Elements: Half-Lightning, Weak-Ice."},
        "BlackGoblin" : {"hp":251, "move":4,  "rank":81, "desc" : "BlackGoblin's base stats: 251 HP, 7 MP, 5 Move, 3 Jump, 8 Speed, 12 PA, 34 MA, 21% C-EV. Innates: Counter, Beastmaster. Elements: Half-Lightning, Weak-Ice."},
        "Gobbledeguck" : {"hp":303, "move":4,  "rank":75, "desc" : "Gobbledeguck's base stats: 303 HP, 16 MP, 4 Move, 3 Jump, 10 Speed, 14 PA, 36 MA, 19% C-EV. Innates: Counter. Elements: Half-Lightning, Weak-Ice."},
        "Bomb" : {"hp":221, "move":4,  "rank":70, "desc" : "Bomb's base stats: 221 HP, 4 MP, 4 Move, 3 Jump, 10 Speed, 13 PA, 38 MA, 14% C-EV. Innates: Counter, Levitate. Elements: Absorb-Fire, Cancel-Earth, Half-Ice, Weak-Water."},
        "Grenade" : {"hp":241, "move":4,  "rank":53, "desc" : "Grenade's base stats: 241 HP, 6 MP, 4 Move, 3 Jump, 11 Speed, 11 PA, 41 MA, 14% C-EV. Innates: Counter, Levitate. Elements: Absorb-Fire, Cancel-Earth, Half-Ice, Weak-Water."},
        "Explosive" : {"hp":322, "move":4,  "rank":69, "desc" : "Explosive's base stats: 322 HP, 8 MP, 4 Move, 3 Jump, 11 Speed, 15 PA, 39 MA, 12% C-EV. Innates: Counter, Levitate. Elements: Absorb-Fire, Cancel-Earth, Half-Ice&Fire, Weak-Water."},
        "RedPanther" : {"hp":338, "move":4,  "rank":74, "desc" : "RedPanther's base stats: 338 HP, 10 MP, 4 Move, 5 Jump, 12 Speed, 12 PA, 35 MA, 31% C-EV. Innates: Counter, Landlocked, Ignore Height, Retreat. Elements: Half-Fire, Weak-Earth."},
        "Coeurl" : {"hp":268, "move":4,  "rank":31, "desc" : "Coeurl's base stats: 268 HP, 13 MP, 4 Move, 5 Jump, 11 Speed, 14 PA, 41 MA, 28% C-EV. Innates: Counter, Landlocked, Ignore Height, Retreat. Elements: Half-Ice, Weak-Earth."},
        "Vampire" : {"hp":291, "move":4,  "rank":48, "desc" : "Vampire's base stats: 291 HP, 15 MP, 4 Move, 4 Jump, 10 Speed, 16 PA, 33 MA, 24% C-EV. Innates: Counter, Landlocked, Ignore Height, Retreat. Elements: Cancel-Dark, Weak-Earth&Holy."},
        "PiscoDemon" : {"hp":299, "move":4,  "rank":82, "desc" : "PiscoDemon's base stats: 299 HP, 26 MP, 3 Move, 3 Jump, 8 Speed, 11 PA, 37 MA, 7% C-EV. Innates: Counter, Waterbreathing. Elements: Absorb-Water, Weak-Lightning."},
        "Squidraken" : {"hp":345, "move":4,  "rank":88, "desc" : "Squidraken's base stats: 345 HP, 25 MP, 4 Move, 3 Jump, 7 Speed, 14 PA, 37 MA, 14% C-EV. Innates: Counter, Waterbreathing. Elements: Absorb-Water, Half-Fire, Weak-Lightning."},
        "Mindflayer" : {"hp":239, "move":4,  "rank":63, "desc" : "Mindflayer's base stats: 239 HP, 35 MP, 3 Move, 3 Jump, 8 Speed, 15 PA, 36 MA, 10% C-EV. Innates: Counter, Waterbreathing. Elements: Absorb-Water, Weak-Lightning."},
        "Skeleton" : {"hp":398, "move":4,  "rank":79, "desc" : "Skeleton's base stats: 398 HP, 1 MP, 3 Move, 4 Jump, 9 Speed, 14 PA, 37 MA, 11% C-EV. Innates: Counter, Undead. Elements: Absorb-Dark, Half-Ice, Weak-Fire&Holy."},
        "Draugr" : {"hp":388, "move":4,  "rank":72, "desc" : "Draugr's base stats: 388 HP, 1 MP, 3 Move, 4 Jump, 8 Speed, 16 PA, 38 MA, 12% C-EV. Innates: Counter, Undead. Elements: Absorb-Dark, Half-Ice, Weak-Fire&Holy."},
        "Reaper" : {"hp":364, "move":4,  "rank":83, "desc" : "Reaper's base stats: 364 HP, 1 MP, 3 Move, 4 Jump, 8 Speed, 15 PA, 38 MA, 13% C-EV. Innates: Counter, Undead. Elements: Absorb-Dark, Half-Ice, Weak-Fire&Holy."},
        "Ghoul" : {"hp":231, "move":4,  "rank":90, "desc" : "Ghoul's base stats: 231 HP, 27 MP, 5 Move, 4 Jump, 8 Speed, 11 PA, 41 MA, 36% C-EV. Innates: Counter, Undead, Levitate, Teleport. Elements: Absorb-Dark, Cancel-Earth, Weak-Fire&Holy."},
        "Ghost" : {"hp":228, "move":4,  "rank":58, "desc" : "Ghost's base stats: 228 HP, 21 MP, 5 Move, 4 Jump, 8 Speed, 11 PA, 41 MA, 40% C-EV. Innates: Counter, Undead, Levitate, Teleport. Elements: Absorb-Dark, Cancel-Earth, Weak-Fire&Holy."},
        "Revenant" : {"hp":265, "move":4,  "rank":85, "desc" : "Revenant's base stats: 265 HP, 14 MP, 6 Move, 4 Jump, 9 Speed, 11 PA, 43 MA, 30% C-EV. Innates: Counter, Undead, Levitate, Teleport. Elements: Absorb-Dark, Cancel-Earth, Weak-Fire&Holy."},
        "FloatingEye" : {"hp":248, "move":4,  "rank":89, "desc" : "FloatingEye's base stats: 248 HP, 17 MP, 5 Move, 5 Jump, 8 Speed, 12 PA, 34 MA, 22% C-EV. Innates: Counter, Fly, Landlocked, Magic Defense UP. Elements: Half-Ice&Wind&Earth&Water&Dark, Weak-Lightning."},
        "Ahriman" : {"hp":218, "move":4,  "rank":84, "desc" : "Ahriman's base stats: 218 HP, 20 MP, 5 Move, 5 Jump, 8 Speed, 16 PA, 37 MA, 19% C-EV. Innates: Counter, Fly, Landlocked, Magic Defense UP. Elements: Half-Wind&Earth&Water&Holy&Dark, Weak-Lightning."},
        "Plague" : {"hp":224, "move":4,  "rank":62, "desc" : "Plague's base stats: 224 HP, 30 MP, 5 Move, 5 Jump, 8 Speed, 15 PA, 47 MA, 17% C-EV. Innates: Counter, Fly, Landlocked, Magic Defense UP. Elements: Half-Fire&Wind&Earth&Water&Dark, Weak-Lightning."},
        "Juravis" : {"hp":234, "move":4,  "rank":77, "desc" : "Juravis's base stats: 234 HP, 8 MP, 6 Move, 6 Jump, 8 Speed, 12 PA, 33 MA, 38% C-EV. Innates: Counter, Fly, Landlocked. Elements: Absorb-Wind, Weak-Earth."},
        "IronHawk" : {"hp":226, "move":4,  "rank":80, "desc" : "IronHawk's base stats: 226 HP, 13 MP, 6 Move, 6 Jump, 10 Speed, 13 PA, 35 MA, 35% C-EV. Innates: Counter, Fly, Landlocked. Elements: Absorb-Wind, Weak-Earth."},
        "Cockatrice" : {"hp":262, "move":4,  "rank":14, "desc" : "Cockatrice's base stats: 262 HP, 2 MP, 6 Move, 6 Jump, 10 Speed, 18 PA, 39 MA, 34% C-EV. Innates: Counter, Fly, Landlocked. Elements: Absorb-Wind, Weak-Earth."},
        "Swine" : {"hp":256, "move":4,  "rank":91, "desc" : "Swine's base stats: 256 HP, 1 MP, 3 Move, 3 Jump, 10 Speed, 8 PA, 43 MA, 32% C-EV. Innates: Counter, Landlocked. Elements: Half-Holy, Weak-Dark."},
        "Porky" : {"hp":286, "move":4,  "rank":86, "desc" : "Porky's base stats: 286 HP, 1 MP, 3 Move, 3 Jump, 10 Speed, 9 PA, 43 MA, 26% C-EV. Innates: Counter, Landlocked. Elements: Half-Holy, Weak-Dark."},
        "WildBoar" : {"hp":273, "move":4,  "rank":50, "desc" : "WildBoar's base stats: 273 HP, 1 MP, 3 Move, 3 Jump, 10 Speed, 19 PA, 43 MA, 29% C-EV. Innates: Counter, Landlocked. Elements: Half-Holy, Weak-Dark."},
        "Dryad" : {"hp":465, "move":4,  "rank":78, "desc" : "Dryad's base stats: 465 HP, 35 MP, 3 Move, 2 Jump, 7 Speed, 12 PA, 39 MA, 2% C-EV. Innates: Counter, Landlocked, Defense UP. Elements: Absorb-Earth, Half-Water, Weak-Fire."},
        "Treant" : {"hp":421, "move":4,  "rank":68, "desc" : "Trent's base stats: 421 HP, 39 MP, 3 Move, 2 Jump, 7 Speed, 10 PA, 40 MA, 1% C-EV. Innates: Counter, Landlocked, Defense UP. Elements: Absorb-Earth, Half-Water, Weak-Fire."},
        "Taiju" : {"hp":509, "move":4,  "rank":60, "desc" : "Taiju's base stats: 509 HP, 32 MP, 3 Move, 2 Jump, 7 Speed, 11 PA, 38 MA, 3% C-EV. Innates: Counter, Landlocked, Non-Charge, Defense UP. Elements: Absorb-Earth, Half-Water, Weak-Fire."},
        "BullDemon" : {"hp":429, "move":4,  "rank":52, "desc" : "BullDemon's base stats: 429 HP, 1 MP, 5 Move, 3 Jump, 8 Speed, 18 PA, 39 MA, 16% C-EV. Innates: Counter, Landlocked. Elements: Half-Earth, Weak-Water."},
        "Minotaur" : {"hp":478, "move":4,  "rank":57, "desc" : "Minotaur's base stats: 478 HP, 1 MP, 4 Move, 4 Jump, 9 Speed, 15 PA, 43 MA, 11% C-EV. Innates: Counter, Landlocked. Elements: Half-Fire, Weak-Water."},
        "Sekhret" : {"hp":446, "move":4,  "rank":29, "desc" : "Sekhret's base stats: 446 HP, 2 MP, 3 Move, 4 Jump, 9 Speed, 21 PA, 39 MA, 15% C-EV. Innates: Counter, Landlocked. Elements: Half-Holy, Weak-Water."},
        "Malboro" : {"hp":442, "move":4,  "rank":92, "desc" : "Malboro's base stats: 442 HP, 3 MP, 3 Move, 3 Jump, 7 Speed, 13 PA, 14 MA, 1% C-EV. Innates: Counter, Waterwalking. Elements: Half-Water, Weak-Ice."},
        "Ochu" : {"hp":369, "move":4,  "rank":87, "desc" : "Ochu's base stats: 369 HP, 3 MP, 3 Move, 3 Jump, 8 Speed, 13 PA, 16 MA, 2% C-EV. Innates: Counter, Waterwalking. Elements: Half-Water&Lightning, Weak-Ice."},
        "GreatMalboro" : {"hp":453, "move":4,  "rank":64, "desc" : "GreatMalboro's base stats: 453 HP, 2 MP, 3 Move, 3 Jump, 7 Speed, 11 PA, 15 MA, 1% C-EV. Innates: Counter, Waterwalking. Elements: Half-Water, Weak-Ice."},
        "Behemoth" : {"hp":475, "move":4,  "rank":26, "desc" : "Behemoth's base stats: 475 HP, 26 MP, 4 Move, 4 Jump, 9 Speed, 17 PA, 41 MA, 13% C-EV. Innates: Counter, Landlocked, Secret Hunt. Elements: Half-Wind&Earth, Weak-Dark."},
        "KingBehemoth" : {"hp":515, "move":4,  "rank":7, "desc" : "KingBehemoth's base stats: 515 HP, 30 MP, 4 Move, 3 Jump, 9 Speed, 19 PA, 39 MA, 15% C-EV. Innates: Counter, Landlocked. Elements: Half-Earth&Holy, Weak-Dark."},
        "DarkBehemoth" : {"hp":495, "move":4,  "rank":3, "desc" : "DarkBehemoth's base stats: 495 HP, 35 MP, 4 Move, 3 Jump, 9 Speed, 26 PA, 41 MA, 18% C-EV. Innates: Counter, Landlocked. Elements: Half-Earth&Dark, Weak-Holy."},
        "Dragon" : {"hp":461, "move":4,  "rank":10, "desc" : "Dragon's base stats: 461 HP, 21 MP, 5 Move, 3 Jump, 10 Speed, 18 PA, 39 MA, 10% C-EV. Innates: Counter, Landlocked. Elements: Absorb-Lightning, Weak-Fire."},
        "BlueDragon" : {"hp":481, "move":4,  "rank":15, "desc" : "BlueDragon's base stats: 481 HP, 24 MP, 5 Move, 3 Jump, 9 Speed, 17 PA, 41 MA, 9% C-EV. Innates: Counter, Landlocked. Elements: Absorb-Ice, Weak-Lightning."},
        "RedDragon" : {"hp":470, "move":4,  "rank":13, "desc" : "RedDragon's base stats: 470 HP, 25 MP, 5 Move, 3 Jump, 9 Speed, 17 PA, 43 MA, 8% C-EV. Innates: Counter, Landlocked. Elements: Absorb-Fire, Weak-Ice."},
        "Wyvern" : {"hp":440, "move":4,  "rank":30, "desc" : "Wyvern's base stats: 440 HP, 10 MP, 4 Move, 4 Jump, 11 Speed, 16 PA, 13 MA, 4% C-EV. Innates: Counter, Fly, Landlocked, Sicken. Elements: Half-Water, Weak-Ice&Wind."},
        "Hydra" : {"hp":488, "move":4,  "rank":27, "desc" : "Hydra's base stats: 488 HP, 35 MP, 4 Move, 4 Jump, 10 Speed, 18 PA, 13 MA, 5% C-EV. Innates: Counter, Fly, Landlocked. Elements: Half-Fire, Weak-Ice&Wind."},
        "Tiamat" : {"hp":498, "move":4,  "rank":2, "desc" : "Tiamat's base stats: 498 HP, 19 MP, 4 Move, 4 Jump, 10 Speed, 22 PA, 16 MA, 3% C-EV. Innates: Counter, Fly, Landlocked. Elements: Half-Lightning, Weak-Ice&Wind&Dark."},
        "ArchaicDemon" : {"hp":319, "move":4,  "rank":6, "desc" : "ArchaicDemon's base stats: 319 HP, 109 MP, 4 Move, 3 Jump, 8 Speed, 15 PA, 16 MA, 6% C-EV. Innates: Counter, Landlocked, Secret Hunt, Short Charge. Elements: Cancel-Holy, Half-Lightning&Dark."},
        "UltimaDemon" : {"hp":333, "move":4,  "rank":1, "desc" : "UltimaDemon's base stats: 333 HP, 104 MP, 5 Move, 2 Jump, 7 Speed, 16 PA, 14 MA, 13% C-EV. Innates: Counter, Landlocked, Martial Arts, Short Charge. Elements: Cancel-Holy, Half-Water&Dark."},
        "Apanda" : {"hp":408, "move":4,  "rank":12, "desc" : "Apanda's base stats: 408 HP, 72 MP, 4 Move, 6 Jump, 7 Speed, 13 PA, 12 MA, 11% C-EV. Innates: Counter, Landlocked, Ignore Height, Short Charge. Elements: Half-Ice, Weak-Fire."},
        "SteelGiant" : {"hp":500, "move":4,  "rank":5, "desc" : "SteelGiant's base stats: 500 HP, 1 MP, 2 Move, 5 Jump, 8 Speed, 20 PA, 1 MA, 0% C-EV. Innates: Counter, Landlocked, Innocent, Ignore Terrain. Elements: Cancel-Ice&Wind&Holy&Dark, Half-Earth, Weak-Lightning."},
        "Byblos" : {"hp":379, "move":4,  "rank":25, "desc" : "Byblos's base stats: 379 HP, 66 MP, 3 Move, 6 Jump, 8 Speed, 12 PA, 12 MA, 33% C-EV. Innates: Counter, Landlocked, Ignore Height, Move-HP Up. Elements: Half-Ice, Weak-Fire."},
        "HolyDragon" : {"hp":414, "move":4,  "rank":4, "desc" : "HolyDragon's base stats: 414 HP, 25 MP, 4 Move, 3 Jump, 10 Speed, 15 PA, 42 MA, 5% C-EV. Innates: Counter, Landlocked, Beastmaster. Elements: Absorb-Holy, Weak-Dark."},
        "Serpentarius" : {"hp":505, "move":4,  "rank":9, "desc" : "Serpentarius's base stats: 505 HP, 82 MP, 4 Move, 3 Jump, 6 Speed, 9 PA, 13 MA, 12% C-EV. Innates: Counter, Landlocked, Sicken, Move-MP Up. Elements: Cancel-Ice, Half-Holy, Weak-Earth."}
}