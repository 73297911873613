<template>

    <div class="unit">
        <div class="bg-and-holder">
        <div class="image-stats">
        <div class="image-holder">
        <img v-if="unit.Gender == 'Monster'"  :src="IMG_URL + unit.Class + '.gif'" />
        <template v-else>
        <img v-if="teamColor != 'Champion'" :src="IMG_URL + unit.Class + unit.Gender[0] + '_' + teamColor + '.gif'" />
        <img v-else :src="IMG_URL + unit.Class + unit.Gender[0] + '.gif'" />
        </template>
        </div>

        <div class="name-and-stats">
        

          <div><b><template v-if="unit.RaidBoss">[!] </template>{{ unit.Name }}</b></div>

          <div class="hasToolTip">
            {{unit.Sign}}
            <span class="toolTip"><p>{{unit.Sign}}</p>{{toolTips.Zodiac[unit.Sign]}}</span>
          </div>


          <div class="hasToolTip" @click="e => e.target.classList.toggle('active')">{{ unit.Class }}
                <span v-if="unit.Gender != 'Monster'" class="toolTip"><p>{{unit.Gender}} {{unit.Class}}</p>{{toolTips.Class[unit.Class.replace(/\s+/g, '')+ ' ' + unit.Gender]}}</span>
                <span v-else class="toolTip"><p>{{unit.Class}}</p>{{toolTips.Class[unit.Class.replace(/\s+/g, '')]}}</span>
            </div> 

            <div>
              <span class="hasToolTip">  Champ Rate: {{classRank[unit.Class+unit.Gender].champRate}} ({{classRank[unit.Class+unit.Gender].Rank}})
              <span class="toolTip"><p><template v-if="unit.Gender !='Monster'">{{unit.Gender}}&nbsp;</template>{{unit.Class}}</p>Unit Type Champ %: {{classRank[unit.Class+unit.Gender].champRate}},  Champ Rate Ranking: {{classRank[unit.Class+unit.Gender].Rank}}, Total # of Champs: {{classRank[unit.Class+unit.Gender].numChamps}}, Total # Rolled: {{classRank[unit.Class+unit.Gender].numRolls}}, Average Streak: {{classRank[unit.Class+unit.Gender].avStreak}} </span>
              </span> 
            </div>

          <div class="stats">
            <div class="stat">
              <span @click="e => e.target.classList.toggle('active')" :class="{ goodBonus: ((itemschanged[unit.Head].hp + itemschanged[unit.Armor].hp + itemschanged[unit.Mainhand].hp + itemschanged[unit.Offhand].hp + itemschanged[unit.Accessory].hp) > 100),  hasToolTip: ((itemschanged[unit.Head].hp + itemschanged[unit.Armor].hp + itemschanged[unit.Mainhand].hp + itemschanged[unit.Offhand].hp + itemschanged[unit.Accessory].hp) > 0) }" > HP: {{ charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].hp + itemschanged[unit.Head].hp + itemschanged[unit.Armor].hp + itemschanged[unit.Mainhand].hp + itemschanged[unit.Offhand].hp + itemschanged[unit.Accessory].hp}}
                  <span v-if="(itemschanged[unit.Head].hp + itemschanged[unit.Armor].hp + itemschanged[unit.Mainhand].hp + itemschanged[unit.Offhand].hp + itemschanged[unit.Accessory].hp) > 0" class="toolTip">
                  <p><span v-if="unit.Gender != 'Monster'">{{unit.Gender}}</span> 
                  {{unit.Class}} Default HP ({{charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].hp}})
                  <span v-if="itemschanged[unit.Mainhand].hp > 0"> + {{itemschanged[unit.Mainhand].name}} (+{{itemschanged[unit.Mainhand].hp}})</span>
                  <span v-if="itemschanged[unit.Offhand].hp > 0"> + {{itemschanged[unit.Offhand].name}} (+{{itemschanged[unit.Offhand].hp}})</span>
                  <span v-if="itemschanged[unit.Head].hp > 0"> + {{itemschanged[unit.Head].name}} (+{{itemschanged[unit.Head].hp}})</span>
                  <span v-if="itemschanged[unit.Armor].hp > 0"> + {{itemschanged[unit.Armor].name}} (+{{itemschanged[unit.Armor].hp}})</span>
                  <span v-if="itemschanged[unit.Accessory].hp > 0"> + {{itemschanged[unit.Accessory].name}} (+{{itemschanged[unit.Accessory].hp}})</span>
                  </p>
              </span>
              </span>
              <span> - </span>
            <span @click="e => e.target.classList.toggle('active')" :class="{ goodBonus: ((itemschanged[unit.Head].mp + itemschanged[unit.Armor].mp + itemschanged[unit.Mainhand].mp + itemschanged[unit.Offhand].mp + itemschanged[unit.Accessory].mp) > 40),  hasToolTip: ((itemschanged[unit.Head].mp + itemschanged[unit.Armor].mp + itemschanged[unit.Mainhand].mp + itemschanged[unit.Offhand].mp + itemschanged[unit.Accessory].mp) > 0) }" >MP: {{ charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].mp + itemschanged[unit.Head].mp + itemschanged[unit.Armor].mp + itemschanged[unit.Mainhand].mp + itemschanged[unit.Offhand].mp + itemschanged[unit.Accessory].mp}}
              <span v-if="(itemschanged[unit.Head].mp + itemschanged[unit.Armor].mp + itemschanged[unit.Mainhand].mp + itemschanged[unit.Offhand].mp + itemschanged[unit.Accessory].mp) > 0" class="toolTip">
              <p><span v-if="unit.Gender != 'Monster'">{{unit.Gender}}</span> 
              {{unit.Class}} Default MP ({{charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].mp}})
              <span v-if="itemschanged[unit.Mainhand].mp > 0"> + {{itemschanged[unit.Mainhand].name}} (+{{itemschanged[unit.Mainhand].mp}})</span>
              <span v-if="itemschanged[unit.Offhand].mp > 0"> + {{itemschanged[unit.Offhand].name}} (+{{itemschanged[unit.Offhand].mp}})</span>
              <span v-if="itemschanged[unit.Head].mp > 0"> + {{itemschanged[unit.Head].name}} (+{{itemschanged[unit.Head].mp}})</span>
              <span v-if="itemschanged[unit.Armor].mp > 0"> + {{itemschanged[unit.Armor].name}} (+{{itemschanged[unit.Armor].mp}})</span>
              <span v-if="itemschanged[unit.Accessory].mp > 0"> + {{itemschanged[unit.Accessory].name}} (+{{itemschanged[unit.Accessory].mp}})</span>
              </p>
          </span>
          </span>

            </div>
            <div class="stat">

              <span @click="e => e.target.classList.toggle('active')" v-if="calcSpeed = charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].speed + itemschanged[unit.Head].speed + itemschanged[unit.Armor].speed + itemschanged[unit.Mainhand].speed + itemschanged[unit.Offhand].speed + itemschanged[unit.Accessory].speed" :class="{ goodBonus: ((itemschanged[unit.Head].speed + itemschanged[unit.Armor].speed + itemschanged[unit.Mainhand].speed + itemschanged[unit.Offhand].speed + itemschanged[unit.Accessory].speed) > 2),  hasToolTip: ((itemschanged[unit.Head].speed + itemschanged[unit.Armor].speed + itemschanged[unit.Mainhand].speed + itemschanged[unit.Offhand].speed + itemschanged[unit.Accessory].speed) > 0) }" >Speed: {{ charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].speed + itemschanged[unit.Head].speed + itemschanged[unit.Armor].speed + itemschanged[unit.Mainhand].speed + itemschanged[unit.Offhand].speed + itemschanged[unit.Accessory].speed}}
                <span v-if="(itemschanged[unit.Head].speed + itemschanged[unit.Armor].speed + itemschanged[unit.Mainhand].speed + itemschanged[unit.Offhand].speed + itemschanged[unit.Accessory].speed) > 0" class="toolTip">
                <p><span v-if="unit.Gender != 'Monster'">{{unit.Gender}}</span>
                {{unit.Class}} Default Speed ({{charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].speed}})
                <span v-if="itemschanged[unit.Mainhand].speed > 0"> + {{itemschanged[unit.Mainhand].name}} (+{{itemschanged[unit.Mainhand].speed}})</span>
                <span v-if="itemschanged[unit.Offhand].speed > 0"> + {{itemschanged[unit.Offhand].name}} (+{{itemschanged[unit.Offhand].speed}})</span>
                <span v-if="itemschanged[unit.Head].speed > 0"> + {{itemschanged[unit.Head].name}} (+{{itemschanged[unit.Head].speed}})</span>
                <span v-if="itemschanged[unit.Armor].speed > 0"> + {{itemschanged[unit.Armor].name}} (+{{itemschanged[unit.Armor].speed}})</span>
                <span v-if="itemschanged[unit.Accessory].speed > 0"> + {{itemschanged[unit.Accessory].name}} (+{{itemschanged[unit.Accessory].speed}})</span>
                </p>
                </span>
              </span>


              <span> - </span>


              <span @click="e => e.target.classList.toggle('active')" :class="{ goodBonus: ((itemschanged[unit.Head].move + itemschanged[unit.Armor].move + itemschanged[unit.Mainhand].move + itemschanged[unit.Offhand].move + itemschanged[unit.Accessory].move + notAbles.movementSkills[unit.MoveSkill].move) > 2),  hasToolTip: ((itemschanged[unit.Head].move + itemschanged[unit.Armor].move + itemschanged[unit.Mainhand].move + itemschanged[unit.Offhand].move + itemschanged[unit.Accessory].move + notAbles.movementSkills[unit.MoveSkill].move) > 0) }" > Move: {{ charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].move + itemschanged[unit.Head].move + itemschanged[unit.Armor].move + itemschanged[unit.Mainhand].move + itemschanged[unit.Offhand].move + itemschanged[unit.Accessory].move + notAbles.movementSkills[unit.MoveSkill].move}}
                  <span v-if="(itemschanged[unit.Head].move + itemschanged[unit.Armor].move + itemschanged[unit.Mainhand].move + itemschanged[unit.Offhand].move + itemschanged[unit.Accessory].move + notAbles.movementSkills[unit.MoveSkill].move) > 0" class="toolTip">
                  <p><span v-if="unit.Gender != 'Monster'">{{unit.Gender}}</span>
                  {{unit.Class}} Default Move ({{charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].move}})
                  <span v-if="itemschanged[unit.Mainhand].move > 0"> + {{itemschanged[unit.Mainhand].name}} (+{{itemschanged[unit.Mainhand].move}})</span>
                  <span v-if="itemschanged[unit.Offhand].move > 0"> + {{itemschanged[unit.Offhand].name}} (+{{itemschanged[unit.Offhand].move}})</span>
                  <span v-if="itemschanged[unit.Head].move > 0"> + {{itemschanged[unit.Head].name}} (+{{itemschanged[unit.Head].move}})</span>
                  <span v-if="itemschanged[unit.Armor].move > 0"> + {{itemschanged[unit.Armor].name}} (+{{itemschanged[unit.Armor].move}})</span>
                  <span v-if="itemschanged[unit.Accessory].move > 0"> + {{itemschanged[unit.Accessory].name}} (+{{itemschanged[unit.Accessory].move}})</span>
                  <span v-if="notAbles.movementSkills[unit.MoveSkill].move > 0"> + {{unit.MoveSkill}} (+{{notAbles.movementSkills[unit.MoveSkill].move}})</span>
                  </p>
              </span>
                </span>


              <span> - </span>

              <span @click="e => e.target.classList.toggle('active')" :class="{ goodBonus: ((itemschanged[unit.Head].jump + itemschanged[unit.Armor].jump + itemschanged[unit.Mainhand].jump + itemschanged[unit.Offhand].jump + itemschanged[unit.Accessory].jump + notAbles.movementSkills[unit.MoveSkill].jump) > 2),  hasToolTip: ((itemschanged[unit.Head].jump + itemschanged[unit.Armor].jump + itemschanged[unit.Mainhand].jump + itemschanged[unit.Offhand].jump + itemschanged[unit.Accessory].jump + notAbles.movementSkills[unit.MoveSkill].jump) > 0) }" >Jump: {{ charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].jump + itemschanged[unit.Head].jump + itemschanged[unit.Armor].jump + itemschanged[unit.Mainhand].jump + itemschanged[unit.Offhand].jump + itemschanged[unit.Accessory].jump + notAbles.movementSkills[unit.MoveSkill].jump}}
                  <span v-if="(itemschanged[unit.Head].jump + itemschanged[unit.Armor].jump + itemschanged[unit.Mainhand].jump + itemschanged[unit.Offhand].jump + itemschanged[unit.Accessory].jump + notAbles.movementSkills[unit.MoveSkill].jump) > 0" class="toolTip">
                  <p><span v-if="unit.Gender != 'Monster'">{{unit.Gender}}</span> 
                  {{unit.Class}} Default Jump ({{charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].jump}})
                  <span v-if="itemschanged[unit.Mainhand].jump > 0"> + {{itemschanged[unit.Mainhand].name}} (+{{itemschanged[unit.Mainhand].jump}})</span>
                  <span v-if="itemschanged[unit.Offhand].jump > 0"> + {{itemschanged[unit.Offhand].name}} (+{{itemschanged[unit.Offhand].jump}})</span>
                  <span v-if="itemschanged[unit.Head].jump > 0"> + {{itemschanged[unit.Head].name}} (+{{itemschanged[unit.Head].jump}})</span>
                  <span v-if="itemschanged[unit.Armor].jump > 0"> + {{itemschanged[unit.Armor].name}} (+{{itemschanged[unit.Armor].jump}})</span>
                  <span v-if="itemschanged[unit.Accessory].jump > 0"> + {{itemschanged[unit.Accessory].name}} (+{{itemschanged[unit.Accessory].jump}})</span>
                  <span v-if="notAbles.movementSkills[unit.MoveSkill].jump > 0"> + {{unit.MoveSkill}} (+{{notAbles.movementSkills[unit.MoveSkill].jump}})</span>

                  </p>
              </span>
              </span>

            </div>
            <div class="stat">
              <template v-if="calcPAU = charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].pa + itemschanged[unit.Head].pa + itemschanged[unit.Armor].pa + itemschanged[unit.Accessory].pa"></template>
              <span @click="e => e.target.classList.toggle('active')" v-if="calcPA = charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].pa + itemschanged[unit.Head].pa + itemschanged[unit.Armor].pa + itemschanged[unit.Mainhand].pa + itemschanged[unit.Offhand].pa + itemschanged[unit.Accessory].pa" :class="{  goodBonus: ((itemschanged[unit.Head].pa + itemschanged[unit.Armor].pa + itemschanged[unit.Mainhand].pa + itemschanged[unit.Offhand].pa + itemschanged[unit.Accessory].pa) > 2), hasToolTip: ((itemschanged[unit.Head].pa + itemschanged[unit.Armor].pa + itemschanged[unit.Mainhand].pa + itemschanged[unit.Offhand].pa + itemschanged[unit.Accessory].pa) > 0) }" >PA: {{ charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].pa + itemschanged[unit.Head].pa + itemschanged[unit.Armor].pa + itemschanged[unit.Mainhand].pa + itemschanged[unit.Offhand].pa + itemschanged[unit.Accessory].pa}}
                  <span v-if="(itemschanged[unit.Head].pa + itemschanged[unit.Armor].pa + itemschanged[unit.Mainhand].pa + itemschanged[unit.Offhand].pa + itemschanged[unit.Accessory].pa) > 0" class="toolTip">
                  <p><span v-if="unit.Gender != 'Monster'">{{unit.Gender}}</span> 
                  {{unit.Class}} Default PA ({{charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].pa}})
                  <span v-if="itemschanged[unit.Mainhand].pa > 0"> + {{itemschanged[unit.Mainhand].name}} (+{{itemschanged[unit.Mainhand].pa}})</span>
                  <span v-if="itemschanged[unit.Offhand].pa > 0"> + {{itemschanged[unit.Offhand].name}} (+{{itemschanged[unit.Offhand].pa}})</span>
                  <span v-if="itemschanged[unit.Head].pa > 0"> + {{itemschanged[unit.Head].name}} (+{{itemschanged[unit.Head].pa}})</span>
                  <span v-if="itemschanged[unit.Armor].pa > 0"> + {{itemschanged[unit.Armor].name}} (+{{itemschanged[unit.Armor].pa}})</span>
                  <span v-if="itemschanged[unit.Accessory].pa > 0"> + {{itemschanged[unit.Accessory].name}} (+{{itemschanged[unit.Accessory].pa}})</span>
                  </p>
              </span>
              </span>
              <span> - </span>

              <span @click="e => e.target.classList.toggle('active')" v-if="calcMA = charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].ma + itemschanged[unit.Head].ma + itemschanged[unit.Armor].ma + itemschanged[unit.Mainhand].ma + itemschanged[unit.Offhand].ma + itemschanged[unit.Accessory].ma" :class="{  goodBonus: ((itemschanged[unit.Head].ma + itemschanged[unit.Armor].ma + itemschanged[unit.Mainhand].ma + itemschanged[unit.Offhand].ma + itemschanged[unit.Accessory].ma) > 2), hasToolTip: ((itemschanged[unit.Head].ma + itemschanged[unit.Armor].ma + itemschanged[unit.Mainhand].ma + itemschanged[unit.Offhand].ma + itemschanged[unit.Accessory].ma) > 0) }" >MA: {{ charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].ma + itemschanged[unit.Head].ma + itemschanged[unit.Armor].ma + itemschanged[unit.Mainhand].ma + itemschanged[unit.Offhand].ma + itemschanged[unit.Accessory].ma}}
                <span v-if="(itemschanged[unit.Head].ma + itemschanged[unit.Armor].ma + itemschanged[unit.Mainhand].ma + itemschanged[unit.Offhand].ma + itemschanged[unit.Accessory].ma) > 0" class="toolTip">
                <p><span v-if="unit.Gender != 'Monster'">{{unit.Gender}}</span> 
                {{unit.Class}} Default MA ({{charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].ma}})
                <span v-if="itemschanged[unit.Mainhand].ma > 0"> + {{itemschanged[unit.Mainhand].name}} (+{{itemschanged[unit.Mainhand].ma}})</span>
                <span v-if="itemschanged[unit.Offhand].ma > 0"> + {{itemschanged[unit.Offhand].name}} (+{{itemschanged[unit.Offhand].ma}})</span>
                <span v-if="itemschanged[unit.Head].ma > 0"> + {{itemschanged[unit.Head].name}} (+{{itemschanged[unit.Head].ma}})</span>
                <span v-if="itemschanged[unit.Armor].ma > 0"> + {{itemschanged[unit.Armor].name}} (+{{itemschanged[unit.Armor].ma}})</span>
                <span v-if="itemschanged[unit.Accessory].ma > 0"> + {{itemschanged[unit.Accessory].name}} (+{{itemschanged[unit.Accessory].ma}})</span>
                </p>
            </span>
            </span>


            </div>
            <div class="stat">
              <span :class="{ bold: (unit.Brave > 70 || unit.Brave < 45) }">Brave: {{unit.Brave}}</span>
              - 
              <span :class="{ bold: (unit.Faith > 70 || unit.Faith < 45) }">Faith: {{unit.Faith}}</span>
            </div>
          </div>




        </div>
        </div>

          <div class="evasion stats">


            <p class="hasToolTip">C-Ev: {{charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].cEvPercent}}%
              <span class="toolTip"><p>Class Evasion</p>This evasion is only effective against physical attacks from the front.</span>
            </p>

            <p class="hasToolTip" v-if="unit.ReactionSkill == 'Parry'">W-Ev: Phys: {{itemschanged[unit.Mainhand].wevade}}%, {{itemschanged[unit.Offhand].wevade}}%
              <span class="toolTip"><p>Weapon Evasion</p>This evasion is only effective against physical attacks from the front and sides.</span>
            </p>

            <p class="hasToolTip" v-if="itemschanged[unit.Offhand].type == 'Shield'">S-Ev: Phys: {{itemschanged[unit.Offhand].pevade}}% Magic: {{itemschanged[unit.Offhand].mevade}}%
              <span class="toolTip"><p>Shield Evasion</p>This evasion is only effective against attacks from the front and the sides.</span>
            </p>

            <p class="hasToolTip" v-if="itemschanged[unit.Accessory].type == 'Accessory' && ( itemschanged[unit.Accessory].pevade > 0 || itemschanged[unit.Accessory].mevade > 0 )">A-Ev: Phys: {{itemschanged[unit.Accessory].pevade}}% Magic: {{itemschanged[unit.Accessory].mevade}}%
              <span class="toolTip"><p>Accessory Evasion</p>This evasion is effective against attacks from all directions.</span>
            </p>

            <div class="hit-chances">

              <div class="hasToolTip">

                <span class="toolTip"><p>Physical Attacks Evasion Chance</p>This box displays the hit % chance of a physical attack with 100% accuracy before target evasion.</span>

                <span>Phys: </span>


                <span v-if="unit.ReactionSkill == 'Parry'" >F: {{ (100 * ( 100 - charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].cEvPercent ) * ( 100 - itemschanged[unit.Mainhand].wevade) * (100 - itemschanged[unit.Offhand].wevade) * ( 100 - itemschanged[unit.Offhand].pevade) * ( 100 - itemschanged[unit.Accessory].pevade ))  /10000000000 }}%</span>
                <span v-else >F: {{ (100 * ( 100 - charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].cEvPercent ) * ( 100 - itemschanged[unit.Offhand].pevade) * ( 100 - itemschanged[unit.Accessory].pevade ))  / 1000000 }}%</span>

                <span v-if="unit.ReactionSkill == 'Parry'" > - S: {{ (100 * ( 100 - itemschanged[unit.Mainhand].wevade) * (100 - itemschanged[unit.Offhand].wevade) * ( 100 - itemschanged[unit.Offhand].pevade) * ( 100 - itemschanged[unit.Accessory].pevade ))  /100000000 }}%</span>
                <span v-else > - S: {{ (100 * ( 100 - itemschanged[unit.Offhand].pevade) * ( 100 - itemschanged[unit.Accessory].pevade ))  / 10000 }}%</span>

                <span> - B: {{ (100 * ( 100 - itemschanged[unit.Accessory].pevade ))  /100 }}%</span>
              </div>

              <div class="hasToolTip">
                <span class="toolTip"><p>Magical Attacks Evasion Chance</p>This box displays the hit % chance of a magical attack with 100% accuracy before target evasion.</span>

                <span>Magic: </span>

                <span>Any {{ (100 * ( 100 - itemschanged[unit.Offhand].mevade) * ( 100 - itemschanged[unit.Accessory].mevade ))  / 10000 }}%</span>



              </div>

            </div>




          </div>



        <div class="unit-equipment">

          <div class="equipment unarmed hasToolTip" :class="{ showUnarmed: showExtra, monster: unit.Gender == 'Monster',  hasWeapon: unit.Mainhand, hasShield: itemschanged[unit.Offhand].type == 'Shield' }">
            <span class="toolTip"><p>Unarmed</p>Pow Pow! Right in the Noggin. (PA * ((PA * Brave) / 100))</span>
            <img class="ability-icon" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/hand.png" alt="hand">
            <img class="gear-icon flip" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/hand.png" alt="hand">
            <span class="equipment-text" @click="e => e.target.classList.toggle('active')">Unarmed

              <span class="damage-number">
              <template v-if="unit.Gender == 'Monster'">
                ({{parseInt( charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].pa * parseInt((charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].pa * unit.Brave ) / 100) ) }})
              </template>
              <template v-else>
                <template v-if="unit.SupportSkill == 'Martial Arts' || unit.Class == 'Monk'">
                    ({{parseInt( 1.5 * (calcPAU * parseInt((calcPAU * unit.Brave ) / 100) )) }})
                </template>
                <template v-else>
                    ({{parseInt( calcPAU * parseInt((calcPAU * unit.Brave ) / 100) ) }})
                </template>
              </template>
                </span>
            </span>
          </div>

          <template v-if="unit.Gender != 'Monster'">



          <div v-if="unit.Mainhand" class="equipment mainhand hasToolTip">
          <span class="toolTip"><p>{{unit.Mainhand}}</p>{{itemschanged[unit.Mainhand].info}}</span>
            <img @click="showExtra = !showExtra" class="ability-icon cursor-pointer" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/hand.png" alt="hand">
            <img class="gear-icon"  :src="IMG_URL_ITEMS + unit.Mainhand.replace(/\s+/g, '') + '.gif'"  :alt='unit.Mainhand'>
            <span class="equipment-text" :class="{ bold: (notAbles.items.includes(unit.Mainhand)),  showUnarmed: showExtra }"  @click="e => e.target.classList.toggle('active')">{{ unit.Mainhand }}

              <span class="damage-number">
              <template v-if="itemschanged[unit.Mainhand].type == 'Rod' || itemschanged[unit.Mainhand].type == 'Crossbow' || itemschanged[unit.Mainhand].type == 'Spear' || itemschanged[unit.Mainhand].type == 'Sword' ">
                <template v-if="unit.SupportSkill == 'Doublehand'">
                  ({{(calcPA * itemschanged[unit.Mainhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{calcPA * itemschanged[unit.Mainhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[unit.Mainhand].type == 'Pole' || itemschanged[unit.Mainhand].type == 'Staff'">
                <template v-if="unit.SupportSkill == 'Doublehand'">
                  ({{(calcMA * itemschanged[unit.Mainhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{calcMA * itemschanged[unit.Mainhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[unit.Mainhand].type == 'Knight Sword' || itemschanged[unit.Mainhand].type == 'Katana' ">
                  <template v-if="unit.SupportSkill == 'Doublehand'">
                    ({{(parseInt(parseInt(calcPA * (unit.Brave / 100)) * itemschanged[unit.Mainhand].wp)) * 2}})
                  </template>
                  <template v-else>
                    ({{parseInt(parseInt(calcPA * (unit.Brave / 100)) * itemschanged[unit.Mainhand].wp)}})
                  </template>

              </template>

              <template v-if="itemschanged[unit.Mainhand].type == 'Harp' || itemschanged[unit.Mainhand].type == 'Book' || itemschanged[unit.Mainhand].type == 'Fabric'">
                    <template v-if="unit.SupportSkill == 'Doublehand'">
                      ({{ (Math.floor((calcPA + calcMA) /2) * itemschanged[unit.Mainhand].wp) * 2}})
                    </template>
                    <template v-else>
                      ({{ Math.floor((calcPA + calcMA) /2) * itemschanged[unit.Mainhand].wp}})
                    </template>
              </template>

              <template v-if="itemschanged[unit.Mainhand].type == 'Axe' || itemschanged[unit.Mainhand].type == 'Bag' || itemschanged[unit.Mainhand].type == 'Flail'">
                <template v-if="unit.SupportSkill == 'Doublehand'">
                  {{(1 * itemschanged[unit.Mainhand].wp) * 2}} - {{(calcPA * itemschanged[unit.Mainhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{1 * itemschanged[unit.Mainhand].wp}} - {{calcPA * itemschanged[unit.Mainhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[unit.Mainhand].type == 'Ninja Blade' || itemschanged[unit.Mainhand].type == 'Knife' || itemschanged[unit.Mainhand].type == 'Bow'">
                <template v-if="unit.SupportSkill == 'Doublehand'">
                  ({{ (Math.floor((calcPA + calcSpeed)/2) * itemschanged[unit.Mainhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{ Math.floor((calcPA + calcSpeed)/2) * itemschanged[unit.Mainhand].wp}})
                </template>
              </template>


              <template v-if="itemschanged[unit.Mainhand].type == 'Gun' && itemschanged[unit.Mainhand].element ">
                <div class="aventail">
                  <template v-if="unit.targetFaith">
                  70% ({{ Math.floor((unit.Faith * unit.targetFaith * 16 * itemschanged[unit.Mainhand].wp) / 10000)}}) -
                  20% ({{ Math.floor((unit.Faith * unit.targetFaith * 20 * itemschanged[unit.Mainhand].wp) / 10000)}}) -
                  10% ({{ Math.floor((unit.Faith * unit.targetFaith * 28 * itemschanged[unit.Mainhand].wp) / 10000)}})
                  </template>
                  <template v-else>
                    70% ({{ Math.floor((unit.Faith * 60 * 16 * itemschanged[unit.Mainhand].wp) / 10000)}}) -
                  20% ({{ Math.floor((unit.Faith * 60 * 20 * itemschanged[unit.Mainhand].wp) / 10000)}}) -
                  10% ({{ Math.floor((unit.Faith * 60 * 28 * itemschanged[unit.Mainhand].wp) / 10000)}})
                  </template>
                </div>
              </template>

              <template v-if="itemschanged[unit.Mainhand].type == 'Gun' && !itemschanged[unit.Mainhand].element ">
                ({{itemschanged[unit.Mainhand].wp * itemschanged[unit.Mainhand].wp}})
              </template>
                </span>
            </span>
          </div>
          <div v-if="unit.Offhand" class="equipment offhand hasToolTip">
          <span class="toolTip"><p>{{unit.Offhand}}</p>{{itemschanged[unit.Offhand].info}}</span>
            <img class="ability-icon" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/hand.png" alt="hand">
            <img class="gear-icon" :src="IMG_URL_ITEMS + unit.Offhand.replace(/\s+/g, '') + '.gif'" :alt='unit.Offhand'>
            <span class="equipment-text" :class="{ bold: (notAbles.items.includes(unit.Offhand)) }"  @click="e => e.target.classList.toggle('active')">{{ unit.Offhand }}

            <span class="damage-number">
              <template v-if="itemschanged[unit.Offhand].type == 'Rod' || itemschanged[unit.Offhand].type == 'Crossbow' || itemschanged[unit.Offhand].type == 'Spear' || itemschanged[unit.Offhand].type == 'Sword' ">
                <template v-if="unit.SupportSkill == 'Doublehand'">
                  ({{(calcPA * itemschanged[unit.Offhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{calcPA * itemschanged[unit.Offhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[unit.Offhand].type == 'Pole' || itemschanged[unit.Offhand].type == 'Staff'">
                <template v-if="unit.SupportSkill == 'Doublehand'">
                  ({{(calcMA * itemschanged[unit.Offhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{calcMA * itemschanged[unit.Offhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[unit.Offhand].type == 'Knight Sword' || itemschanged[unit.Offhand].type == 'Katana' ">
                  <template v-if="unit.SupportSkill == 'Doublehand'">
                    ({{(parseInt(parseInt(calcPA * (unit.Brave / 100)) * itemschanged[unit.Offhand].wp)) * 2}})
                  </template>
                  <template v-else>
                    ({{parseInt(parseInt(calcPA * (unit.Brave / 100)) * itemschanged[unit.Offhand].wp)}})
                  </template>

              </template>

              <template v-if="itemschanged[unit.Offhand].type == 'Harp' || itemschanged[unit.Offhand].type == 'Book' || itemschanged[unit.Offhand].type == 'Fabric'">
                    <template v-if="unit.SupportSkill == 'Doublehand'">
                      ({{ (Math.floor((calcPA + calcMA) /2) * itemschanged[unit.Offhand].wp) * 2}})
                    </template>
                    <template v-else>
                      ({{ Math.floor((calcPA + calcMA) /2) * itemschanged[unit.Offhand].wp}})
                    </template>
              </template>

              <template v-if="itemschanged[unit.Offhand].type == 'Axe' || itemschanged[unit.Offhand].type == 'Bag' || itemschanged[unit.Offhand].type == 'Flail'">
                <template v-if="unit.SupportSkill == 'Doublehand'">
                  {{(1 * itemschanged[unit.Offhand].wp) * 2}} - {{(calcPA * itemschanged[unit.Offhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{1 * itemschanged[unit.Offhand].wp}} - {{calcPA * itemschanged[unit.Offhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[unit.Offhand].type == 'Ninja Blade' || itemschanged[unit.Offhand].type == 'Knife' || itemschanged[unit.Offhand].type == 'Bow'">
                <template v-if="unit.SupportSkill == 'Doublehand'">
                  ({{ (Math.floor((calcPA + calcSpeed)/2) * itemschanged[unit.Offhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{ Math.floor((calcPA + calcSpeed)/2) * itemschanged[unit.Offhand].wp}})
                </template>
              </template>


              <template v-if="itemschanged[unit.Offhand].type == 'Gun' && itemschanged[unit.Offhand].element ">

            <div class="aventail">
              <template v-if="unit.targetFaith">
                70% ({{ Math.floor((unit.Faith * unit.targetFaith * 16 * itemschanged[unit.Offhand].wp) / 10000)}}) -
                20% ({{ Math.floor((unit.Faith * unit.targetFaith * 20 * itemschanged[unit.Offhand].wp) / 10000)}}) -
                10% ({{ Math.floor((unit.Faith * unit.targetFaith * 28 * itemschanged[unit.Offhand].wp) / 10000)}})
                </template>
                <template v-else>
                  70% ({{ Math.floor((unit.Faith * 60 * 16 * itemschanged[unit.Offhand].wp) / 10000)}}) -
                20% ({{ Math.floor((unit.Faith * 60 * 20 * itemschanged[unit.Offhand].wp) / 10000)}}) -
                10% ({{ Math.floor((unit.Faith * 60 * 28 * itemschanged[unit.Offhand].wp) / 10000)}})
                </template>
            </div>

              </template>

              <template v-if="itemschanged[unit.Offhand].type == 'Gun' && !itemschanged[unit.Offhand].element ">
                ({{itemschanged[unit.Offhand].wp * itemschanged[unit.Offhand].wp}})
              </template>
                </span>


            </span>
          </div>
          <div v-if="unit.Head" class="equipment head hasToolTip">
            <span class="toolTip"><p>{{unit.Head}}</p>{{itemschanged[unit.Head].info}}</span>
            <img class="ability-icon" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/head.png" alt="head">
            <img class="gear-icon" :src="IMG_URL_ITEMS + unit.Head.replace(/\s+/g, '') + '.gif'" :alt='unit.Head'>
            <span class="equipment-text" :class="{ bold: (notAbles.items.includes(unit.Head)) }"  @click="e => e.target.classList.toggle('active')">{{ unit.Head }}</span> 
          </div>
          <div v-if="unit.Armor" class="equipment armor hasToolTip">
            <span class="toolTip"><p>{{unit.Armor}}</p>{{itemschanged[unit.Armor].info}}</span>
            <img class="ability-icon" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/body.png" alt="body">
            <img class="gear-icon" :src="IMG_URL_ITEMS + unit.Armor.replace(/\s+/g, '') + '.gif'" :alt='unit.Armor'>
            <span class="equipment-text" :class="{ bold: (notAbles.items.includes(unit.Armor)) }"  @click="e => e.target.classList.toggle('active')">{{ unit.Armor }}</span> 
          </div>
          <div v-if="unit.Accessory" class="equipment accessory hasToolTip">
            <span class="toolTip"><p>{{unit.Accessory}}</p>{{itemschanged[unit.Accessory].info}}</span>
            <img class="ability-icon" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/accessory.png" alt="accessory">
            <img class="gear-icon" :src="IMG_URL_ITEMS + unit.Accessory.replace(/\s+/g, '') + '.gif'" :alt='unit.Accessory'>
            <span class="equipment-text" :class="{ bold: (notAbles.items.includes(unit.Accessory)) }"  @click="e => e.target.classList.toggle('active')">{{ unit.Accessory }}</span> 
          </div>
          <div class="status" v-if="itemschanged[unit.Mainhand].permstatus || itemschanged[unit.Offhand].permstatus || itemschanged[unit.Head].permstatus || itemschanged[unit.Armor].permstatus || itemschanged[unit.Accessory].permstatus">
            <h3>Permanent Status</h3>
            <div v-for="perm of itemschanged[unit.Mainhand].permstatus" class="hasToolTip">
              {{perm.name}} ({{unit.Mainhand}})
              <span class="toolTip"><p>{{perm.name}}</p>{{perm.info}}</span>
            </div>
            <div v-for="perm of itemschanged[unit.Offhand].permstatus" class="hasToolTip">
              {{perm.name}} ({{unit.Offhand}})
              <span class="toolTip"><p>{{perm.name}}</p>{{perm.info}}</span>
            </div>
            <div v-for="perm of itemschanged[unit.Head].permstatus" class="hasToolTip">
              {{perm.name}} ({{unit.Head}})
              <span class="toolTip"><p>{{perm.name}}</p>{{perm.info}}</span>
            </div>
            <div v-for="perm of itemschanged[unit.Armor].permstatus" class="hasToolTip">
              {{perm.name}} ({{unit.Armor}})
              <span class="toolTip"><p>{{perm.name}}</p>{{perm.info}}</span>
            </div>
            <div v-for="perm of itemschanged[unit.Accessory].permstatus" class="hasToolTip">
              {{perm.name}} ({{unit.Accessory}})
              <span class="toolTip"><p>{{perm.name}}</p>{{perm.info}}</span>
            </div>
          </div>
          <div class="status" v-if="itemschanged[unit.Mainhand].initstatus || itemschanged[unit.Offhand].initstatus || itemschanged[unit.Head].initstatus || itemschanged[unit.Armor].initstatus || itemschanged[unit.Accessory].initstatus">
            <h3>Initial Status</h3>
            <div v-for="init of itemschanged[unit.Mainhand].initstatus" class="hasToolTip">
              {{init.name}} ({{unit.Mainhand}})
              <span class="toolTip"><p>{{init.name}}</p>{{init.info}}</span>
            </div>
            <div v-for="init of itemschanged[unit.Offhand].initstatus" class="hasToolTip">
              {{init.name}} ({{unit.Offhand}})
              <span class="toolTip"><p>{{init.name}}</p>{{init.info}}</span>
            </div>
            <div v-for="init of itemschanged[unit.Head].initstatus" class="hasToolTip">
              {{init.name}} ({{unit.Head}})
              <span class="toolTip"><p>{{init.name}}</p>{{init.info}}</span>
            </div>
            <div v-for="init of itemschanged[unit.Armor].initstatus" class="hasToolTip">
              {{init.name}} ({{unit.Armor}})
              <span class="toolTip"><p>{{init.name}}</p>{{init.info}}</span>
            </div>
            <div v-for="init of itemschanged[unit.Accessory].initstatus" class="hasToolTip">
              {{init.name}} ({{unit.Accessory}})
              <span class="toolTip"><p>{{init.name}}</p>{{init.info}}</span>
            </div>

          </div>
          </template>
        </div>

        <div v-if="charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].innates || unit.Class == 'Lancer'" class="unit-innates">
            <div v-for="innate of charStats[unit.Class.replace(/\s+/g, '')+[unit.Gender]].innates" class="innate hasToolTip">
                <span class="toolTip"><p>{{innate.name}}</p>{{innate.info}}</span>
                <img class="ability-icon" :src="IMG_URL_ITEMS + innate.type + '.gif'" :alt="innate.type">
                <span class="innate-text" :class="{ bold: (notAbles.abilities.includes(innate.name)) }"  @click="e => e.target.classList.toggle('active')">{{ innate.name }}</span> 
            </div>
            <template v-if="unit.Gender != 'Monster'" >
                <div v-if="unit.Class != 'Mime'" class="innate hasToolTip" @click="e => e.target.classList.toggle('active')">
                    <span class="toolTip"><p>{{unit.ActionSkill}}</p>{{toolTips.UserSkill[unit.ActionSkill.replace(/\s+/g, '')]}}</span>
                    <img class="ability-icon" :src="IMG_URL_ITEMS + 'active.gif'" alt="active">
                    <span class="innate-text" :class="{ bold: (notAbles.abilities.includes(unit.ActionSkill)) }"  @click="e => e.target.classList.toggle('active')">{{unit.ActionSkill}}</span>
                </div>

                <div :class="{ bold: (notAbles.abilities.includes(unit.ReactionSkill)) }" class="innate hasToolTip" @click="e => e.target.classList.toggle('active')" >
                    <span class="toolTip"><p>{{unit.ReactionSkill}}</p>{{toolTips.Ability[unit.ReactionSkill]}}</span>
                    <img class="ability-icon" :src="IMG_URL_ITEMS + 'react.gif'" alt="reaction">
                    <span class="innate-text" :class="{ bold: (notAbles.abilities.includes(unit.ReactionSkill)) }"  @click="e => e.target.classList.toggle('active')">{{unit.ReactionSkill}}</span>
                </div>
                
                <div :class="{ bold: (notAbles.abilities.includes(unit.SupportSkill)) }" class="innate hasToolTip" @click="e => e.target.classList.toggle('active')" >
                    <span class="toolTip"><p>{{unit.SupportSkill}}</p>{{toolTips.Ability[unit.SupportSkill]}}</span>
                    <img class="ability-icon" :src="IMG_URL_ITEMS + 'support.gif'" alt="support">
                    <span class="innate-text" :class="{ bold: (notAbles.abilities.includes(unit.SupportSkill)) }"  @click="e => e.target.classList.toggle('active')">{{unit.SupportSkill}}</span>
                </div>

                <div :class="{ bold: (notAbles.abilities.includes(unit.MoveSkill)) }" class="innate hasToolTip" @click="e => e.target.classList.toggle('active')">
                    <span class="toolTip"><p>{{unit.MoveSkill}}</p>{{toolTips.Ability[unit.MoveSkill]}}</span>
                    <img class="ability-icon" :src="IMG_URL_ITEMS + 'move.gif'" alt="movement">
                    <span class="innate-text" :class="{ bold: (notAbles.abilities.includes(unit.MoveSkill)) }"  @click="e => e.target.classList.toggle('active')">{{unit.MoveSkill}}</span>
                </div>
            </template>
        </div>


          
          <div class="unit-skills target-faith">
            <p>Target Unit Faith: {{unit.targetFaith || 60}}</p>
            <input type="range" min="0" max="100" v-model="unit.targetFaith" class="slider" >
          </div>

          
        <div class="unit-skills" v-if="unit.Gender != 'Monster'">
          <div v-for="ability of unit.ClassSkills" :class="{ bold: (notAbles.abilities.includes(ability)) }" class="hasToolTip" @click="e => e.target.classList.toggle('active')">{{ability}}
              <span class="toolTip"><p>{{ability}}</p>{{toolTips.Ability[ability]}}</span>
                <span v-if="abilityFormula(ability)">({{thingFormula}})</span>
          </div>
        </div>
        

        <div class="unit-skills" v-if="unit.Gender != 'Monster' && unit.Class != 'Mime' ">
          <div v-for="ability of unit.ExtraSkills" :class="{ bold: (notAbles.abilities.includes(ability)) }" class="hasToolTip" @click="e => e.target.classList.toggle('active')">{{ability}}
              <span class="toolTip"><p>{{ability}}</p>{{toolTips.Ability[ability]}}</span>
                <span v-if="abilityFormula(ability)">({{thingFormula}})</span>
          </div>
        </div>


           
        <div class="unit-skills" v-if="toolTips.MonsterSkills[unit.Class]">
          <div v-for="ability of toolTips.MonsterSkills[unit.Class]" :class="{ bold: (notAbles.abilities.includes(ability)) }" class="hasToolTip" @click="e => e.target.classList.toggle('active')">{{ability}}
              <span class="toolTip"><p>{{ability}}</p>{{toolTips.Ability[ability.replace(/[^a-zA-Z1-9 ]/g, "")]}}</span>
                <span v-if="abilityFormula(ability)">({{thingFormula}})</span>
          </div>
        </div>
    </div>
    </div>
      
        

</template>
<script>


import {collection, getDocs, limit, query, where} from "firebase/firestore";

export default {
  props: {
    unit: {},
    itemschanged: {},
    JobStats: {},
    charStats: {},
    notAbles: {},
    toolTips: {},
    teamColor : "",
    classRank: {},
  },

  data: {
    thingFormula: "",
    targetunitFaith: 60,
  },


  methods: {



    abilityFormula(ability) {
      ability = ability.replace("*", "")

      if (this.unit.targetFaith) {
        this.targetUnitFaith = this.unit.targetFaith;
      } else {
        this.targetUnitFaith = 60;
      }
      switch (ability) {


        default:
          this.thingFormula = "";
          return this.thingFormula;

        case "Charge+20":
          this.thingFormula = "";
          if (this.itemschanged[this.unit.Mainhand].type == 'Rod' || this.itemschanged[this.unit.Mainhand].type == 'Crossbow' || this.itemschanged[this.unit.Mainhand].type == 'Spear' || this.itemschanged[this.unit.Mainhand].type == 'Sword') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcPA + 20) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcPA + 20) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Pole' || this.itemschanged[this.unit.Mainhand].type == 'Staff') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcMA + 20) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcMA + 20) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Knight Sword' || this.itemschanged[this.unit.Mainhand].type == 'Katana') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = parseInt(parseInt((this.calcPA + 20) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = parseInt(parseInt((this.calcPA + 20) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp);
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Harp' || this.itemschanged[this.unit.Mainhand].type == 'Book' || this.itemschanged[this.unit.Mainhand].type == 'Fabric' ) {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 20) + (this.calcMA + 20)) /2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 20) + (this.calcMA + 20)) /2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Axe' || this.itemschanged[this.unit.Mainhand].type == 'Bag' || this.itemschanged[this.unit.Mainhand].type == 'Flail') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (1 * this.itemschanged[this.unit.Mainhand].wp) * 2 + " - " + ((this.calcPA + 20) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = 1 * this.itemschanged[this.unit.Mainhand].wp + " - " + (this.calcPA + 20) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Ninja Blade' || this.itemschanged[this.unit.Mainhand].type == 'Knife' || this.itemschanged[this.unit.Mainhand].type == 'Bow') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 20) + (this.calcSpeed + 20))/2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 20) + (this.calcSpeed + 20))/2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = "70% (" + (this.unit.Faith * 60 * 16 * (this.itemschanged[this.unit.Mainhand].wp + 20)) / 10000
                + ") 20% (" + (this.unit.Faith * 60 * 20 * (this.itemschanged[this.unit.Mainhand].wp + 20)) / 10000 + ") 10% (" +
                (this.unit.Faith * 60 * 28 * (this.itemschanged[this.unit.Mainhand].wp + 20)) / 10000;
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && !this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = (this.itemschanged[this.unit.Mainhand].wp + 20) * this.itemschanged[this.unit.Mainhand].wp;
          } else {
            if (this.unit.SupportSkill == 'Martial Arts' || this.unit.Class == 'Monk') {
              this.thingFormula = parseInt(1.5 * (this.calcPAU + 20) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            } else {
              this.thingFormula = parseInt((this.calcPAU + 20) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            }
          }
          return this.thingFormula;


        case "Charge+10":
          this.thingFormula = "";
          if (this.itemschanged[this.unit.Mainhand].type == 'Rod' || this.itemschanged[this.unit.Mainhand].type == 'Crossbow' || this.itemschanged[this.unit.Mainhand].type == 'Spear' || this.itemschanged[this.unit.Mainhand].type == 'Sword') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcPA + 10) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcPA + 10) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Pole' || this.itemschanged[this.unit.Mainhand].type == 'Staff') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcMA + 10) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcMA + 10) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Knight Sword' || this.itemschanged[this.unit.Mainhand].type == 'Katana') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = parseInt(parseInt((this.calcPA + 10) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = parseInt(parseInt((this.calcPA + 10) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp);
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Harp' || this.itemschanged[this.unit.Mainhand].type == 'Book' || this.itemschanged[this.unit.Mainhand].type == 'Fabric' ) {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 10) + (this.calcMA + 10)) /2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 10) + (this.calcMA + 10)) /2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Axe' || this.itemschanged[this.unit.Mainhand].type == 'Bag' || this.itemschanged[this.unit.Mainhand].type == 'Flail') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (1 * this.itemschanged[this.unit.Mainhand].wp) * 2 + " - " + ((this.calcPA + 10) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = 1 * this.itemschanged[this.unit.Mainhand].wp + " - " + (this.calcPA + 10) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Ninja Blade' || this.itemschanged[this.unit.Mainhand].type == 'Knife' || this.itemschanged[this.unit.Mainhand].type == 'Bow') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 10) + (this.calcSpeed + 10))/2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 10) + (this.calcSpeed + 10))/2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = "70% (" + (this.unit.Faith * 60 * 16 * (this.itemschanged[this.unit.Mainhand].wp + 10)) / 10000
                + ") 20% (" + (this.unit.Faith * 60 * 20 * (this.itemschanged[this.unit.Mainhand].wp + 10)) / 10000 + ") 10% (" +
                (this.unit.Faith * 60 * 28 * (this.itemschanged[this.unit.Mainhand].wp + 10)) / 10000;
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && !this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = (this.itemschanged[this.unit.Mainhand].wp + 10) * this.itemschanged[this.unit.Mainhand].wp;
          } else {
            if (this.unit.SupportSkill == 'Martial Arts' || this.unit.Class == 'Monk') {
              this.thingFormula = parseInt(1.5 * (this.calcPAU + 10) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            } else {
              this.thingFormula = parseInt((this.calcPAU + 10) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            }
          }
          return this.thingFormula;

        case "Charge+7":
          this.thingFormula = "";
          if (this.itemschanged[this.unit.Mainhand].type == 'Rod' || this.itemschanged[this.unit.Mainhand].type == 'Crossbow' || this.itemschanged[this.unit.Mainhand].type == 'Spear' || this.itemschanged[this.unit.Mainhand].type == 'Sword') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcPA + 7) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcPA + 7) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Pole' || this.itemschanged[this.unit.Mainhand].type == 'Staff') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcMA + 7) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcMA + 7) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Knight Sword' || this.itemschanged[this.unit.Mainhand].type == 'Katana') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = parseInt(parseInt((this.calcPA + 7) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = parseInt(parseInt((this.calcPA + 7) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp);
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Harp' || this.itemschanged[this.unit.Mainhand].type == 'Book' || this.itemschanged[this.unit.Mainhand].type == 'Fabric' ) {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 7) + (this.calcMA + 7)) /2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 7) + (this.calcMA + 7)) /2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Axe' || this.itemschanged[this.unit.Mainhand].type == 'Bag' || this.itemschanged[this.unit.Mainhand].type == 'Flail') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (1 * this.itemschanged[this.unit.Mainhand].wp) * 2 + " - " + ((this.calcPA + 7) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = 1 * this.itemschanged[this.unit.Mainhand].wp + " - " + (this.calcPA + 7) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Ninja Blade' || this.itemschanged[this.unit.Mainhand].type == 'Knife' || this.itemschanged[this.unit.Mainhand].type == 'Bow') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 7) + (this.calcSpeed + 7))/2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 7) + (this.calcSpeed + 7))/2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = "70% (" + (this.unit.Faith * 60 * 16 * (this.itemschanged[this.unit.Mainhand].wp + 7)) / 10000
                + ") 20% (" + (this.unit.Faith * 60 * 20 * (this.itemschanged[this.unit.Mainhand].wp + 7)) / 10000 + ") 10% (" +
                (this.unit.Faith * 60 * 28 * (this.itemschanged[this.unit.Mainhand].wp + 7)) / 10000;
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && !this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = (this.itemschanged[this.unit.Mainhand].wp + 7) * this.itemschanged[this.unit.Mainhand].wp;
          } else {
            if (this.unit.SupportSkill == 'Martial Arts' || this.unit.Class == 'Monk') {
              this.thingFormula = parseInt(1.5 * (this.calcPAU + 7) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            } else {
              this.thingFormula = parseInt((this.calcPAU + 7) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            }
          }
          return this.thingFormula;


        case "Charge+5":
          this.thingFormula = "";
          if (this.itemschanged[this.unit.Mainhand].type == 'Rod' || this.itemschanged[this.unit.Mainhand].type == 'Crossbow' || this.itemschanged[this.unit.Mainhand].type == 'Spear' || this.itemschanged[this.unit.Mainhand].type == 'Sword') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcPA + 5) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcPA + 5) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Pole' || this.itemschanged[this.unit.Mainhand].type == 'Staff') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcMA + 5) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcMA + 5) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Knight Sword' || this.itemschanged[this.unit.Mainhand].type == 'Katana') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = parseInt(parseInt((this.calcPA + 5) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = parseInt(parseInt((this.calcPA + 5) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp);
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Harp' || this.itemschanged[this.unit.Mainhand].type == 'Book' || this.itemschanged[this.unit.Mainhand].type == 'Fabric' ) {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 5) + (this.calcMA + 5)) /2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 5) + (this.calcMA + 5)) /2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Axe' || this.itemschanged[this.unit.Mainhand].type == 'Bag' || this.itemschanged[this.unit.Mainhand].type == 'Flail') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (1 * this.itemschanged[this.unit.Mainhand].wp) * 2 + " - " + ((this.calcPA + 5) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = 1 * this.itemschanged[this.unit.Mainhand].wp + " - " + (this.calcPA + 5) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Ninja Blade' || this.itemschanged[this.unit.Mainhand].type == 'Knife' || this.itemschanged[this.unit.Mainhand].type == 'Bow') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 5) + (this.calcSpeed + 5))/2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 5) + (this.calcSpeed + 5))/2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = "70% (" + (this.unit.Faith * 60 * 16 * (this.itemschanged[this.unit.Mainhand].wp + 5)) / 10000
                + ") 20% (" + (this.unit.Faith * 60 * 20 * (this.itemschanged[this.unit.Mainhand].wp + 5)) / 10000 + ") 10% (" +
                (this.unit.Faith * 60 * 28 * (this.itemschanged[this.unit.Mainhand].wp + 5)) / 10000;
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && !this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = (this.itemschanged[this.unit.Mainhand].wp + 5) * this.itemschanged[this.unit.Mainhand].wp;
          } else {
            if (this.unit.SupportSkill == 'Martial Arts' || this.unit.Class == 'Monk') {
              this.thingFormula = parseInt(1.5 * (this.calcPAU + 5) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            } else {
              this.thingFormula = parseInt((this.calcPAU + 5) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            }
          }
          return this.thingFormula;

        case "Charge+4":
          this.thingFormula = "";
          if (this.itemschanged[this.unit.Mainhand].type == 'Rod' || this.itemschanged[this.unit.Mainhand].type == 'Crossbow' || this.itemschanged[this.unit.Mainhand].type == 'Spear' || this.itemschanged[this.unit.Mainhand].type == 'Sword') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcPA + 4) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcPA + 4) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Pole' || this.itemschanged[this.unit.Mainhand].type == 'Staff') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcMA + 4) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcMA + 4) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Knight Sword' || this.itemschanged[this.unit.Mainhand].type == 'Katana') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = parseInt(parseInt((this.calcPA + 4) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = parseInt(parseInt((this.calcPA + 4) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp);
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Harp' || this.itemschanged[this.unit.Mainhand].type == 'Book' || this.itemschanged[this.unit.Mainhand].type == 'Fabric' ) {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 4) + (this.calcMA + 4)) /2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 4) + (this.calcMA + 4)) /2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Axe' || this.itemschanged[this.unit.Mainhand].type == 'Bag' || this.itemschanged[this.unit.Mainhand].type == 'Flail') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (1 * this.itemschanged[this.unit.Mainhand].wp) * 2 + " - " + ((this.calcPA + 4) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = 1 * this.itemschanged[this.unit.Mainhand].wp + " - " + (this.calcPA + 4) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Ninja Blade' || this.itemschanged[this.unit.Mainhand].type == 'Knife' || this.itemschanged[this.unit.Mainhand].type == 'Bow') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 4) + (this.calcSpeed + 4))/2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 4) + (this.calcSpeed + 4))/2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = "70% (" + (this.unit.Faith * 60 * 16 * (this.itemschanged[this.unit.Mainhand].wp + 4)) / 10000
                + ") 20% (" + (this.unit.Faith * 60 * 20 * (this.itemschanged[this.unit.Mainhand].wp + 4)) / 10000 + ") 10% (" +
                (this.unit.Faith * 60 * 28 * (this.itemschanged[this.unit.Mainhand].wp + 4)) / 10000;
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && !this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = (this.itemschanged[this.unit.Mainhand].wp + 4) * this.itemschanged[this.unit.Mainhand].wp;
          } else {
            if (this.unit.SupportSkill == 'Martial Arts' || this.unit.Class == 'Monk') {
              this.thingFormula = parseInt(1.5 * (this.calcPAU + 4) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            } else {
              this.thingFormula = parseInt((this.calcPAU + 4) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            }
          }
          return this.thingFormula;

        case "Charge+3":
          this.thingFormula = "";
          if (this.itemschanged[this.unit.Mainhand].type == 'Rod' || this.itemschanged[this.unit.Mainhand].type == 'Crossbow' || this.itemschanged[this.unit.Mainhand].type == 'Spear' || this.itemschanged[this.unit.Mainhand].type == 'Sword') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcPA + 3) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcPA + 3) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Pole' || this.itemschanged[this.unit.Mainhand].type == 'Staff') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcMA + 3) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcMA + 3) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Knight Sword' || this.itemschanged[this.unit.Mainhand].type == 'Katana') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = parseInt(parseInt((this.calcPA + 3) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = parseInt(parseInt((this.calcPA + 3) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp);
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Harp' || this.itemschanged[this.unit.Mainhand].type == 'Book' || this.itemschanged[this.unit.Mainhand].type == 'Fabric' ) {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 3) + (this.calcMA + 3)) /2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 3) + (this.calcMA + 3)) /2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Axe' || this.itemschanged[this.unit.Mainhand].type == 'Bag' || this.itemschanged[this.unit.Mainhand].type == 'Flail') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (1 * this.itemschanged[this.unit.Mainhand].wp) * 2 + " - " + ((this.calcPA + 3) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = 1 * this.itemschanged[this.unit.Mainhand].wp + " - " + (this.calcPA + 3) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Ninja Blade' || this.itemschanged[this.unit.Mainhand].type == 'Knife' || this.itemschanged[this.unit.Mainhand].type == 'Bow') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 3) + (this.calcSpeed + 3))/2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 3) + (this.calcSpeed + 3))/2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = "70% (" + (this.unit.Faith * 60 * 16 * (this.itemschanged[this.unit.Mainhand].wp + 3)) / 10000
                + ") 20% (" + (this.unit.Faith * 60 * 20 * (this.itemschanged[this.unit.Mainhand].wp + 3)) / 10000 + ") 10% (" +
                (this.unit.Faith * 60 * 28 * (this.itemschanged[this.unit.Mainhand].wp + 3)) / 10000;
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && !this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = (this.itemschanged[this.unit.Mainhand].wp + 3) * this.itemschanged[this.unit.Mainhand].wp;
          } else {
            if (this.unit.SupportSkill == 'Martial Arts' || this.unit.Class == 'Monk') {
              this.thingFormula = parseInt(1.5 * (this.calcPAU + 3) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            } else {
              this.thingFormula = parseInt((this.calcPAU + 3) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            }
          }
          return this.thingFormula;

        case "Charge+2":
          this.thingFormula = "";
          if (this.itemschanged[this.unit.Mainhand].type == 'Rod' || this.itemschanged[this.unit.Mainhand].type == 'Crossbow' || this.itemschanged[this.unit.Mainhand].type == 'Spear' || this.itemschanged[this.unit.Mainhand].type == 'Sword') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcPA + 2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcPA + 2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Pole' || this.itemschanged[this.unit.Mainhand].type == 'Staff') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcMA + 2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcMA + 2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Knight Sword' || this.itemschanged[this.unit.Mainhand].type == 'Katana') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = parseInt(parseInt((this.calcPA + 2) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = parseInt(parseInt((this.calcPA + 2) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp);
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Harp' || this.itemschanged[this.unit.Mainhand].type == 'Book' || this.itemschanged[this.unit.Mainhand].type == 'Fabric' ) {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 2) + (this.calcMA + 2)) /2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 2) + (this.calcMA + 2)) /2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Axe' || this.itemschanged[this.unit.Mainhand].type == 'Bag' || this.itemschanged[this.unit.Mainhand].type == 'Flail') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (1 * this.itemschanged[this.unit.Mainhand].wp) * 2 + " - " + ((this.calcPA + 2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = 1 * this.itemschanged[this.unit.Mainhand].wp + " - " + (this.calcPA + 2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Ninja Blade' || this.itemschanged[this.unit.Mainhand].type == 'Knife' || this.itemschanged[this.unit.Mainhand].type == 'Bow') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 2) + (this.calcSpeed + 2))/2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 2) + (this.calcSpeed + 2))/2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = "70% (" + (this.unit.Faith * 60 * 16 * (this.itemschanged[this.unit.Mainhand].wp + 2)) / 10000
                + ") 20% (" + (this.unit.Faith * 60 * 20 * (this.itemschanged[this.unit.Mainhand].wp + 2)) / 10000 + ") 10% (" +
                (this.unit.Faith * 60 * 28 * (this.itemschanged[this.unit.Mainhand].wp + 2)) / 10000;
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && !this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = (this.itemschanged[this.unit.Mainhand].wp + 2) * this.itemschanged[this.unit.Mainhand].wp;
          } else {
            if (this.unit.SupportSkill == 'Martial Arts' || this.unit.Class == 'Monk') {
              this.thingFormula = parseInt(1.5 * (this.calcPAU + 2) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            } else {
              this.thingFormula = parseInt((this.calcPAU + 2) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            }
          }
          return this.thingFormula;

        case "Charge+1":
          this.thingFormula = "";
          if (this.itemschanged[this.unit.Mainhand].type == 'Rod' || this.itemschanged[this.unit.Mainhand].type == 'Crossbow' || this.itemschanged[this.unit.Mainhand].type == 'Spear' || this.itemschanged[this.unit.Mainhand].type == 'Sword') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcPA + 1) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcPA + 1) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Pole' || this.itemschanged[this.unit.Mainhand].type == 'Staff') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = ((this.calcMA + 1) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = (this.calcMA + 1) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Knight Sword' || this.itemschanged[this.unit.Mainhand].type == 'Katana') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = parseInt(parseInt((this.calcPA + 1) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = parseInt(parseInt((this.calcPA + 1) * (this.unit.Brave / 100)) * this.itemschanged[this.unit.Mainhand].wp);
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Harp' || this.itemschanged[this.unit.Mainhand].type == 'Book' || this.itemschanged[this.unit.Mainhand].type == 'Fabric' ) {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 1) + (this.calcMA + 1)) /2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 1) + (this.calcMA + 1)) /2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Axe' || this.itemschanged[this.unit.Mainhand].type == 'Bag' || this.itemschanged[this.unit.Mainhand].type == 'Flail') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (1 * this.itemschanged[this.unit.Mainhand].wp) * 2 + " - " + ((this.calcPA + 1) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = 1 * this.itemschanged[this.unit.Mainhand].wp + " - " + (this.calcPA + 1) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Ninja Blade' || this.itemschanged[this.unit.Mainhand].type == 'Knife' || this.itemschanged[this.unit.Mainhand].type == 'Bow') {
            if (this.unit.SupportSkill == 'Doublehand') {
              this.thingFormula = (Math.floor(((this.calcPA + 1) + (this.calcSpeed + 1))/2) * this.itemschanged[this.unit.Mainhand].wp) * 2;
            } else {
              this.thingFormula = Math.floor(((this.calcPA + 1) + (this.calcSpeed + 1))/2) * this.itemschanged[this.unit.Mainhand].wp;
            }
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = "70% (" + (this.unit.Faith * 60 * 16 * (this.itemschanged[this.unit.Mainhand].wp + 1)) / 10000
                + ") 20% (" + (this.unit.Faith * 60 * 20 * (this.itemschanged[this.unit.Mainhand].wp + 1)) / 10000 + ") 10% (" +
                (this.unit.Faith * 60 * 28 * (this.itemschanged[this.unit.Mainhand].wp + 1)) / 10000;
          } else if (this.itemschanged[this.unit.Mainhand].type == 'Gun' && !this.itemschanged[this.unit.Mainhand].element) {
            this.thingFormula = (this.itemschanged[this.unit.Mainhand].wp + 1) * this.itemschanged[this.unit.Mainhand].wp;
          } else {

            if (this.unit.SupportSkill == 'Martial Arts' || this.unit.Class == 'Monk') {
              this.thingFormula = parseInt(1.5 * (this.calcPAU + 1) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            } else {
              this.thingFormula = parseInt((this.calcPAU + 1) * parseInt((this.calcPAU * this.unit.Brave) / 100));
            }
          }
          return this.thingFormula;




        case "Potion":
          this.thingFormula = "Heal 100";
          return this.thingFormula;
        case "Hi-Potion":
          this.thingFormula = "Heal 120";
          return this.thingFormula;
        case "X-Potion":
          this.thingFormula = "Heal 150";
          return this.thingFormula;
        case "Elixir":
          this.thingFormula = "Full Heal";
          return this.thingFormula;
        case "Arm Aim":
          this.thingFormula = this.calcSpeed + 42 + "%";
          return this.thingFormula;
        case "Seal Evil":
          this.thingFormula = this.calcSpeed + 58 + "%";
          return this.thingFormula;
        case "Steal Helmet":
          this.thingFormula = this.calcSpeed + 60 + "%";
          return this.thingFormula;
        case "Leg Aim":
          this.thingFormula = this.calcSpeed + 46 + "%";
          return this.thingFormula;
        case "Steal Accessory":
          this.thingFormula = this.calcSpeed + 70 + "%";
          return this.thingFormula;
        case "Steal Shield":
          this.thingFormula = this.calcSpeed + 50 + "%";
          return this.thingFormula;
        case "Steal Armor":
          this.thingFormula = this.calcSpeed + 55 + "%";
          return this.thingFormula;
        case "Steal Weapon":
          this.thingFormula = this.calcSpeed + 65 + "%";
          return this.thingFormula;
        case "Steal Heart":
          this.thingFormula = this.calcMA + 45 + "%";
          return this.thingFormula;
        case "Level Jump1":
        case "Level Jump2":
        case "Level Jump3":
        case "Level Jump4":
        case "Level Jump5":
        case "Level Jump6":
        case "Level Jump7":
        case "Level Jump8":
        case "Vertical Jump1":
        case "Vertical Jump2":
        case "Vertical Jump3":
        case "Vertical Jump4":
        case "Vertical Jump5":
        case "Vertical Jump6":
        case "Vertical Jump7":
        case "Vertical Jump8":

          if (this.itemschanged[this.unit.Mainhand].type == 'Spear') {
            this.thingFormula =  Math.floor((this.calcPA * (this.itemschanged[this.unit.Mainhand].wp)) * 1.5);
          } else if (this.itemschanged[this.unit.Mainhand].wp) {
            this.thingFormula =  Math.floor(this.calcPA * (this.itemschanged[this.unit.Mainhand].wp));
          } else {
            this.thingFormula =  parseInt( this.calcPAU * parseInt((this.calcPAU * this.unit.Brave ) / 100) );
          }
          return this.thingFormula;
        case "Blastar Punch":
        case "Hellcry Punch":
        case "Icewolf Bite":
        case "Hellcry Crush":
        case "Shellbust Stab":
          this.thingFormula = this.calcPA * this.itemschanged[this.unit.Mainhand].wp;
          return this.thingFormula;
        case "Justice Sword":
          if (this.itemschanged[this.unit.Mainhand].wp) {
            this.thingFormula = this.calcPA * (this.itemschanged[this.unit.Mainhand].wp + 2);
          } else {
            this.thingFormula = this.calcPA * 2;
          }
          this.thingFormula = this.calcPA * (this.itemschanged[this.unit.Mainhand].wp + 2);
          return this.thingFormula;
        case "Dark Sword":
          if (this.itemschanged[this.unit.Mainhand].wp) {
            this.thingFormula = "AbsorbMP: " + this.calcPA * this.itemschanged[this.unit.Mainhand].wp;
          } else {
            this.thingFormula = "AbsorbMP: " + this.calcPA * 0;
          }
          return this.thingFormula;
        case "Night Sword":
          if (this.itemschanged[this.unit.Mainhand].wp) {
            this.thingFormula = "AbsorbHP: " + this.calcPA * this.itemschanged[this.unit.Mainhand].wp;
          } else {
            this.thingFormula = "AbsorbHP: " + this.calcPA * 0;
          }
          return this.thingFormula;
        case "Stasis Sword":
          if (this.itemschanged[this.unit.Mainhand].wp) {
            this.thingFormula =  this.calcPA * (this.itemschanged[this.unit.Mainhand].wp + 2);
          } else {
            this.thingFormula =  this.calcPA * 2;
          }
          return this.thingFormula;
        case "Surging Sword":
          if (this.itemschanged[this.unit.Mainhand].wp) {
            this.thingFormula =  this.calcPA * (this.itemschanged[this.unit.Mainhand].wp + 2);
          } else {
            this.thingFormula =  this.calcPA * 2;
          }
          return this.thingFormula;
        case "Explosion Sword":
          if (this.itemschanged[this.unit.Mainhand].wp) {
            this.thingFormula =  this.calcPA * (this.itemschanged[this.unit.Mainhand].wp + 3);
          } else {
            this.thingFormula =  this.calcPA * 3;
          }


          return this.thingFormula;
        case "Bomb":
          this.thingFormula = this.calcSpeed * 8;
          return this.thingFormula;
        case "Dictionary":
          this.thingFormula = this.calcSpeed * 10;
          return this.thingFormula;
        case "Weapon Break":
          if (this.itemschanged[this.unit.Mainhand].wp) {
            this.thingFormula = this.calcPA + this.itemschanged[this.unit.Mainhand].wp + 32 + "%";
          } else {
            this.thingFormula = this.calcPA + 32 + "%";
          }
          return this.thingFormula;
        case "Armor Break":
          if (this.itemschanged[this.unit.Mainhand].wp) {
            this.thingFormula = this.calcPA + this.itemschanged[this.unit.Mainhand].wp + 48 + "%";
          } else {
            this.thingFormula = this.calcPA + 48 + "%";
          }
          return this.thingFormula;
        case "Head Break":
          if (this.itemschanged[this.unit.Mainhand].wp) {
            this.thingFormula = this.calcPA + this.itemschanged[this.unit.Mainhand].wp + 40 + "%";
          } else {
            this.thingFormula = this.calcPA + 40 + "%";
          }
          return this.thingFormula;
        case "Shield Break":
          if (this.itemschanged[this.unit.Mainhand].wp) {
            this.thingFormula = this.calcPA + this.itemschanged[this.unit.Mainhand].wp + 56 + "%";
          } else {
            this.thingFormula = this.calcPA + 56 + "%";
          }
          return this.thingFormula;
        case "Magic Break":
          this.thingFormula = this.calcPA + 50 + "%";
          return this.thingFormula;
        case "Mind Break":
        case "Power Break":
          this.thingFormula = this.calcPA + 52 + "%";
          return this.thingFormula;
        case "Speed Break":
          this.thingFormula = this.calcPA + 54 + "%";
          return this.thingFormula;
        case "Axe":
          this.thingFormula = this.itemschanged["Giant Axe"].wp * this.calcSpeed;
          return this.thingFormula;
        case "Wand":
          this.thingFormula = 7 * this.calcSpeed;
          return this.thingFormula;
        case "Stick":
          this.thingFormula = 12 * this.calcSpeed;
          return this.thingFormula;
        case "Sword":
          this.thingFormula = 14 * this.calcSpeed;
          return this.thingFormula;
        case "Staff":
          this.thingFormula = 6 * this.calcSpeed;
          return this.thingFormula;
        case "Spear":
          this.thingFormula = 13 * this.calcSpeed;
          return this.thingFormula;
        case "Hammer":
          this.thingFormula = 16 * this.calcSpeed;
          return this.thingFormula;
        case "Ninja Sword":
          this.thingFormula = 11 * this.calcSpeed;
          return this.thingFormula;
        case "Knife":
          this.thingFormula = 10 * this.calcSpeed;
          return this.thingFormula;
        case "Shuriken":
          this.thingFormula = 9 * this.calcSpeed;
          return this.thingFormula;
        case "Revive":
          this.thingFormula = this.calcPA + 69 + "%";
          return this.thingFormula;
        case "Oink":
          this.thingFormula = this.calcPA + 72 + "%";
          return this.thingFormula;
        case "Purification":
          this.thingFormula = this.calcPA + 77 + "%";
          return this.thingFormula;
        case "Fury":
          this.thingFormula = this.calcPA + 85 + "%";
          return this.thingFormula;
        case "Pummel":
          this.thingFormula = + Math.floor(this.calcPA * 1.5) + " - " + Math.floor((9 * this.calcPA) * 1.5);
          return this.thingFormula;
        case "Blizzard":
        case "Gusty Wind":
        case "Hallowed Ground":
        case "Local Quake":
        case "Hell Ivy":
        case "Pitfall":
        case "Sand Storm":
        case "Quicksand":
        case "Static Shock":
        case "Will-O-Wisp":
        case "Water Ball":
        case "Lava Ball":
          this.thingFormula = Math.floor((this.calcPA + 2) /2 ) * this.calcMA;
          return this.thingFormula;
        case "Wave Fist":
          this.thingFormula = Math.floor((this.calcPA + 2) /2 ) * this.calcPA;
          return this.thingFormula;
        case "Spin Fist":
          this.thingFormula = Math.floor((this.calcPA + 1) /2 ) * this.calcPA;
          return this.thingFormula;
        case "Triple Thunder":
          this.thingFormula = Math.floor((this.calcMA + 12) /2 ) * this.calcMA;
          return this.thingFormula;
        case "Triple Flame":
          this.thingFormula = Math.floor((this.calcMA + 20) /2 ) * this.calcMA;
          return this.thingFormula;
        case "Nanoflare":
          this.thingFormula = Math.floor((this.calcMA + 5) /2 ) * this.calcMA;
          return this.thingFormula;
        case "Dark Whisper":
          this.thingFormula = Math.floor((this.calcMA + 3) /2 ) * this.calcMA;
          return this.thingFormula;
        case "Diamond Blade":
        case "Hydra Pit":
        case "Sky Demon":
          this.thingFormula = (this.calcMA + 10) * Math.floor(this.calcMA / 2 );
          return this.thingFormula;
        case "Space Storage":
          this.thingFormula = (this.calcMA + 7) * Math.floor(this.calcMA / 2 );
          return this.thingFormula;
        case "Destroy":
          this.thingFormula = "Hurt: " + (this.calcPA * 10) + " - Self: " + Math.floor((this.calcPA * 10) / 4);
          return this.thingFormula;
        case "Compress":
          this.thingFormula = "Hurt: " + (this.calcPA * 15) + " - Self: " + Math.floor((this.calcPA * 15) / 3);
          return this.thingFormula;
        case "Chakra":
          this.thingFormula = "Heal: " + (this.calcPA * 5) + " - HealMP: " + Math.floor((this.calcPA * 5) / 2);
          return this.thingFormula;
        case "Dispose":
          this.thingFormula = "Hurt: " + (this.calcPA * 5) + " - Self: " + Math.floor((this.calcPA * 5) / 5);
          return this.thingFormula;
        case "Dash":
          this.thingFormula = (this.calcPA) + " - " + (this.calcPA * 4);
          return this.thingFormula;
        case "Throw Stone":
          this.thingFormula = (this.calcPA) + " - " + (this.calcPA * 2);
          return this.thingFormula;
        case "Repair":
          this.thingFormula = (this.calcPA + 80) + "% Heal: " + Math.floor(this.charStats[this.unit.Class.replace(/\s+/g, '')+[this.unit.Gender]].hp * .3);
          return this.thingFormula;
        case "Wish":
          this.thingFormula = "Heal: " + Math.floor(this.charStats[this.unit.Class.replace(/\s+/g, '')+[this.unit.Gender]].hp * .4 ) + " Self Hurt: " + Math.floor(this.charStats[this.unit.Class.replace(/\s+/g, '')+[this.unit.Gender]].hp * .2);
          return this.thingFormula;
        case "Bequeath Bacon":
        case "Energize":
          this.thingFormula = "Heal: " + Math.floor((this.charStats[this.unit.Class.replace(/\s+/g, '')+[this.unit.Gender]].hp * 2) / 5) + " - Hurt: " + Math.floor((this.charStats[this.unit.Class.replace(/\s+/g, '')+[this.unit.Gender]].hp) / 5);
          return this.thingFormula;
        case "Choco Ball":
          this.thingFormula = (Math.floor((this.calcPA) / 2) * this.calcPA);
          return this.thingFormula;
        case "Golem":
          this.thingFormula = parseInt((this.unit.Faith / 100) * (this.calcMA + 225)) + "%";
          return this.thingFormula;
        case "Small Bomb":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 9));
          return this.thingFormula;
        case "Bolt":
        case "Fire":
        case "Ice":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 16));
          return this.thingFormula;
        case "Bolt 2":
        case "Fire 2":
        case "Ice 2":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 20));
          return this.thingFormula;
        case "Bolt 3":
        case "Fire 3":
        case "Ice 3":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 28));
          return this.thingFormula;
        case "Bolt 4":
        case "Fire 4":
        case "Ice 4":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 35));
          return this.thingFormula;
        case "Cure":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 15));
          return this.thingFormula;
        case "Cure 2":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 20));
          return this.thingFormula;
        case "Ifrit":
        case "Ramuh":
        case "Shiva":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 23));
          return this.thingFormula;
        case "Fairy":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 24));
          return this.thingFormula;
        case "Titan":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 27));
          return this.thingFormula;
        case "Cure 3":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 30));
          return this.thingFormula;
        case "Salamander":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 32));
          return this.thingFormula;
        case "Sylph":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 30));
          return this.thingFormula;
        case "Leviathan":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 36));
          return this.thingFormula;
        case "Quake":
        case "Tornado":
        case "Melt":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 38));
          return this.thingFormula;
        case "Odin":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 39));
          return this.thingFormula;
        case "Cure 4":
        case "Flare 2":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 40));
          return this.thingFormula;
        case "Bahamut":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 44));
          return this.thingFormula;
        case "Holy":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 46));
          return this.thingFormula;
        case "Flare":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 48));
          return this.thingFormula;
        case "Cyclops":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 50));
          return this.thingFormula;
        case "Meteor":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 60));
          return this.thingFormula;
        case "Zodiac":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 90));
          return this.thingFormula;
        case "Chicken":
        case "Blind":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 200)) + "%";
          return this.thingFormula;
        case "Magic Barrier":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 202)) + "%";
          return this.thingFormula;
        case "Dispel Magic":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 205)) + "%";
          return this.thingFormula;
        case "Despair":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 214)) + "%";
          return this.thingFormula;
        case "Noxious Gas":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 231)) + "%";
          return this.thingFormula;
        case "Toxic Frog":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 110)) + "%";
          return this.thingFormula;
        case "Bio Ranae":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 117)) + "%";
          return this.thingFormula;
        case "Death":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 113)) + "%";
          return this.thingFormula;
        case "Bio Sanctus":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 120)) + "%";
          return this.thingFormula;
        case "Bio Lapis":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 115)) + "%";
          return this.thingFormula;
        case "Bio Tardus":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 120)) + "%";
          return this.thingFormula;
        case "Frog":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 125)) + "%";
          return this.thingFormula;
        case "Petrify":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 130)) + "%";
          return this.thingFormula;
        case "Enrage":
        case "Blind Rage":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 135)) + "%";
          return this.thingFormula;
        case "Bio Silentium":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 123)) + "%";
          return this.thingFormula;
        case "Carbunkle":
        case "Stop":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 140)) + "%";
          return this.thingFormula;
        case "Wall":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 145)) + "%";
          return this.thingFormula;
        case "Zombie":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 150)) + "%";
          return this.thingFormula;
        case "Moogle":
        case "Reraise":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 155)) + "%";
          return this.thingFormula;
        case "Doubt Faith":
        case "Pray Faith":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 155)) + "%";
          return this.thingFormula;
        case "Dragon Pit":
        case "Obsidian Blade":
        case "Nether Demon":
          this.thingFormula = parseInt(((100 - this.unit.Faith) / 100) * (( 100 - this.targetUnitFaith) / 100) * (this.calcMA + 79) * (this.calcMA / 2));
          return this.thingFormula;
        case "Void Storage":
          this.thingFormula = parseInt(((100 - this.unit.Faith) / 100) * (( 100 - this.targetUnitFaith) / 100) * (this.calcMA + 66) * (this.calcMA / 2));
          return this.thingFormula;
        case "Confusion Song":
        case "Demi 3":
        case "Lich":
        case "Quick":
        case "Confuse":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 160)) + "%";
          return this.thingFormula;
        case "Steal Status":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 173)) + "%";
          return this.thingFormula;
        case "Demi 2":
        case "Raise 2":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 165)) + "%";
          return this.thingFormula;
        case "Float":
        case "Foxbird":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 170)) + "%";
          return this.thingFormula;
        case "Regen":
        case "Sleep":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 175)) + "%";
          return this.thingFormula;
        case "Reflect":
        case "Silence":
        case "Silence Song":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 180)) + "%";
          return this.thingFormula;
        case "Demi 3":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 182)) + "%";
          return this.thingFormula;
        case "Haste":
        case "Paralyze":
        case "Slow":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 185)) + "%";
          return this.thingFormula;
        case "Mute":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 210)) + "%";
          return this.thingFormula;
        case "Life Drain":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 185)) + "%";
          return this.thingFormula;
        case "Immobilize":
        case "Raise":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 190)) + "%";
          return this.thingFormula;
        case "Despair 2":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 196)) + "%";
          return this.thingFormula;
        case "Spell Absorb":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 192)) + "%";
          return this.thingFormula;
        case "Poison":
        case "Stabilize Time":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 195)) + "%";
          return this.thingFormula;
        case "Protect":
        case "Shell":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 200)) + "%";
          return this.thingFormula;
        case "Demi":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 205)) + "%";
          return this.thingFormula;
        case "Demi":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 208)) + "%";
          return this.thingFormula;
        case "Old":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 222)) + "%";
          return this.thingFormula;
        case "Esuna":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 205)) + "%";
          return this.thingFormula;
        case "Empower":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 236)) + "%";
          return this.thingFormula;
        case "Haste 2":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 240)) + "%";
          return this.thingFormula;
        case "Protect 2":
        case "Shell 2":
        case "Slow 2":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA + 245)) + "%";
          return this.thingFormula;
        case "Bio Tenebris":
        case "Bio Venenum":
        case "Bio Oleum":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 13));
          return this.thingFormula;
        case "Bio Immortuos":
        case "Bio Mortem":
        case "Bio Insanis":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 26));
          return this.thingFormula;
        case "Ultima":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 25));
          return this.thingFormula;
        case "Dark Holy":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 41));
          return this.thingFormula;
        case "All-Ultima":
          this.thingFormula = parseInt(((this.unit.Faith * this.targetUnitFaith) / 10000) * (this.calcMA * 55));
          return this.thingFormula;
        case "Poison Nail":
          this.thingFormula = this.calcMA + 58 + "%";
          return this.thingFormula;
        case "Insult":
          this.thingFormula = this.calcMA + 39 + "%";
          return this.thingFormula;
        case "Invitation":
          this.thingFormula = this.calcMA + 37 + "%";
          return this.thingFormula;
        case "Moldball Virus":
          this.thingFormula = this.calcMA + 18 + "%";
          return this.thingFormula;
        case "Choco Esuna":
          this.thingFormula = this.calcMA + 64 + "%";
          return this.thingFormula;
        case "Mutilate":
          this.thingFormula = this.calcMA + 35 + "%";
          return this.thingFormula;
        case "Death Sentence":
          this.thingFormula = this.calcMA + 34 + "%";
          return this.thingFormula;
        case "Gil Taking":
          this.thingFormula = this.calcSpeed + 63 + "%";
          return this.thingFormula;
        case "Beak":
          this.thingFormula = this.calcMA + 36 + "%";
          return this.thingFormula;
        case "Persuade":
          this.thingFormula = this.calcMA + 59 + "%";
          return this.thingFormula;
        case "Look of Devil":
          this.thingFormula = this.calcMA + 42 + "%";
          return this.thingFormula;
        case "Negotiate":
          this.thingFormula = this.calcMA + 41 + "%";
          return this.thingFormula;
        case "Sleep Touch":
          this.thingFormula = this.calcMA + 44 + "%";
          return this.thingFormula;
        case "Goblin Punch":
          this.thingFormula = this.calcMA + 46 + "%";
          return this.thingFormula;
        case "Doom":
          this.thingFormula = this.calcMA + 45 + "%";
          return this.thingFormula;
        case "Zombie Touch":
          this.thingFormula = this.calcMA + 47 + "%";
          return this.thingFormula;
        case "Galaxy Stop":
          this.thingFormula = this.calcMA + 47 + "%";
          return this.thingFormula;
        case "Toot":
          this.thingFormula = this.calcMA + 48 + "%";
          return this.thingFormula;
        case "Mind Blast":
          this.thingFormula = this.calcMA + 49 + "%";
          return this.thingFormula;
        case "Hurricane":
          this.thingFormula = this.calcMA + 56 + "%";
          return this.thingFormula;
        case "Secret Fist":
          this.thingFormula = this.calcMA + 50 + "%";
          return this.thingFormula;
        case "Grease Touch":
          this.thingFormula = this.calcMA + 52 + "%";
          return this.thingFormula;
        case "Beam":
          this.thingFormula = this.calcPA + 51 + "%";
          return this.thingFormula;
        case "Peck":
          this.thingFormula = this.calcMA + 53 + "%";
          return this.thingFormula;
        case "Black Ink":
          this.thingFormula = this.calcMA + 55 + "%";
          return this.thingFormula;
        case "Eye Gouge":
          this.thingFormula = this.calcMA + 54 + "%";
          return this.thingFormula;
        case "Look of Fright":
          this.thingFormula = this.calcPA + 57 + "%";
          return this.thingFormula;
        case "Shine Lover":
          this.thingFormula = this.calcMA + 66 + "%";
          return this.thingFormula;
        case "Goo":
          this.thingFormula = this.calcMA + 65 + "%";
          return this.thingFormula;
        case "Protect Spirit":
        case "Calm Spirit":
          this.thingFormula = this.calcMA + 60 + "%";
          return this.thingFormula;
        case "Tickle":
          this.thingFormula = this.calcMA + 67 + "%";
          return this.thingFormula;
        case "Drain Touch":
          this.thingFormula = this.calcMA + 62 + "%";
          return this.thingFormula;
        case "Refute":
          this.thingFormula = this.calcMA + 75 + "%";
          return this.thingFormula;
        case "Praise":
        case "Preach":
          this.thingFormula = this.calcMA + 81 + "%";
          return this.thingFormula;
        case "Cheer Up":
          this.thingFormula = this.calcMA + 87 + "%";
          return this.thingFormula;
        case "Threaten":
        case "Solution":
          this.thingFormula = this.calcMA + 89 + "%";
          return this.thingFormula;
        case "Triple Breath":
          this.thingFormula = this.calcMA + 84 + "%";
          return this.thingFormula;
        case "Snort":
          this.thingFormula = this.calcMA + 40 + "%";
          return this.thingFormula;
        case "Mimic Daravon":
          this.thingFormula = this.calcMA + 43 + "%";
          return this.thingFormula;
        case "Asura":
          this.thingFormula = this.calcMA * 8;
          return this.thingFormula;
        case "Blaster":
          this.thingFormula = this.calcMA + 38 + "%";
          return this.thingFormula;
        case "Earth Slash":
          this.thingFormula = Math.floor((this.calcPA / 2) * this.calcPA);
          return this.thingFormula;
        case "Frozen Cry":
          this.thingFormula = (Math.floor((this.calcPA + 2) / 2) * this.calcPA);
          return this.thingFormula;
        case "Turn Punch":
          this.thingFormula = (Math.floor((this.calcPA + 4) / 2) * this.calcPA);
          return this.thingFormula;
        case "Storm Around":
          this.thingFormula = (Math.floor((this.calcPA + 1) / 2) * this.calcPA);
          return this.thingFormula;
        case "Cat Kick":
          this.thingFormula = this.calcPA + " - " + (8 * this.calcPA);
          return this.thingFormula;
        case "Tail Swing":
          this.thingFormula = this.calcPA + " - " + (15 * this.calcPA);
          return this.thingFormula;
        case "Witch Hunt":
          this.thingFormula = "DamageMP: " + (this.calcPA + Math.floor((this.calcPA * this.unit.Brave) / 100));
          return this.thingFormula;
        case "Wiznaibus":
          this.thingFormula = (this.calcPA + Math.floor((this.calcPA * this.unit.Brave) / 100));
          return this.thingFormula;

        case "Shake Off":
        case "Bite":
        case "Slam":
        case "Scratch Up":
        case "Tackle":
        case "Triple Attack":
        case "Knife Hand":
        case "Stab Up":
        case "Throw Spirit":
        case "Wing Attack":
        case "Scratch":
        case "Straight Dash":
        case "Tentacle":
        case "Tendrils":
        case "Choco Attack":
          this.thingFormula = parseInt( this.calcPA * parseInt((this.calcPA * this.unit.Brave ) / 100) )
          return this.thingFormula;
        case "Magic Spirit":
        case "Feather Bomb":
          this.thingFormula = (this.calcMA * 2);
          return this.thingFormula;
        case "Spark":
        case "Mimic Titan":
        case "Thunder Soul":
        case "Ice Soul":
        case "Choco Cure":
        case "Aqua Soul":
        case "Frost Soul":
        case "Blow Fire":
        case "Rehabilitate":
          this.thingFormula = (this.calcMA * 3);
          return this.thingFormula;
        case "Wind Soul":
        case "Choco Meteor":
        case "Spirit of Life":
        case "Flame Attack":
          this.thingFormula = (this.calcMA * 4);
          return this.thingFormula;
        case "Fire Breath":
        case "Thunder Breath":
        case "Holy Breath":
        case "Leaf Dance":
        case "Ice Breath":
          this.thingFormula = (this.calcMA * 5);
          return this.thingFormula;
        case "Giga Flare":
          this.thingFormula = (this.calcMA * 7);
          return this.thingFormula;
        case "Murasame":
          this.thingFormula = (this.calcMA * 9);
          return this.thingFormula;
        case "Koutetsu":
          this.thingFormula = (this.calcMA * 10);
          return this.thingFormula;
        case "Heaven's Cloud":
          this.thingFormula = (this.calcMA * 11);
          return this.thingFormula;
        case "Braver":
        case "Kikuichimonji":
          this.thingFormula = (this.calcMA * 12);
          return this.thingFormula;
        case "Muramasa":
          this.thingFormula = (this.calcMA * 14);
          return this.thingFormula;
        case "Midgar Swarm":
          this.thingFormula = (this.calcMA * 16);
          return this.thingFormula;
        case "Chirijiraden":
          this.thingFormula = (this.calcMA * 17);
          return this.thingFormula;
        case "Cross-Slash":
          this.thingFormula = (this.calcMA * 22);
          return this.thingFormula;

        case "Meteorain":
          this.thingFormula = (this.calcMA * 26);
          return this.thingFormula;



        case "Omnislash":
          this.thingFormula = (this.calcMA * 40);
          return this.thingFormula;

        case "Cherry Blossom":
          this.thingFormula = (this.calcMA * 60);
          return this.thingFormula;
        case "Angel Song":
          this.thingFormula = "MP Restore: " + (this.calcMA + 20);
          return this.thingFormula;
        case "Life Song":
          this.thingFormula = "HP Restore: " + (this.calcMA + 20);
          return this.thingFormula;
        case "Bizen Boat":
          this.thingFormula = "MP Damage: " + (this.calcMA * 5);
          return this.thingFormula;



      }
    },
  },

  data() {
    return {

      showExtra : false,
      IMG_URL : "https://luxor-fftbg-assets.s3.amazonaws.com/units/",
      IMG_URL_ITEMS : "https://luxor-fftbg-assets.s3.amazonaws.com/icons/",
    }
  },
}
</script>