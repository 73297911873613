{
    "" : {"hp":0, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : ""},
    "Leather Helmet" : {"hp":34, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+34 HP, +0 MP, Helmet. Effect: +1 Speed."},
    "Bronze Helmet" : {"hp":40, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+40 HP, +0 MP, Helmet. "},
    "Iron Helmet" : {"hp":46, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+46 HP, +0 MP, Helmet. Effect: +1 PA."},
    "Barbuta" : {"hp":52, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+52 HP, +0 MP, Helmet. Effect: +1 Jump."},
    "Mythril Helmet" : {"hp":58, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+58 HP, +4 MP, Helmet. Effect: +1 Speed."},
    "Gold Helmet" : {"hp":66, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+66 HP, +8 MP, Helmet. "},
    "Cross Helmet" : {"hp":74, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+74 HP, +0 MP, Helmet. Effect: +1 MA."},
    "Diamond Helmet" : {"hp":82, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+82 HP, +6 MP, Helmet. "},
    "Platinum Helmet" : {"hp":90, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+90 HP, +0 MP, Helmet. "},
    "Circlet" : {"hp":100, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+100 HP, +0 MP, Helmet. "},
    "Crystal Helmet" : {"hp":110, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+110 HP, +2 MP, Helmet. "},
    "Genji Helmet" : {"hp":130, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+130 HP, +0 MP, Helmet. "},
    "Grand Helmet" : {"hp":150, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+150 HP, +0 MP, Helmet. Effect: Immune Darkness, Sleep."},
    "Leather Hat" : {"hp":28, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+28 HP, +3 MP, Hat. Effect: +1 Speed."},
    "Feather Hat" : {"hp":32, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+32 HP, +5 MP, Hat. Effect: +1 Jump; Absorb Wind."},
    "Red Hood" : {"hp":36, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+36 HP, +8 MP, Hat."},
    "Headgear" : {"hp":43, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+43 HP, +0 MP, Hat. Effect: +1 PA."},
    "Triangle Hat" : {"hp":46, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+46 HP, +12 MP, Hat. Effect: +1 MA, +1 Jump."},
    "Green Beret" : {"hp":52, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+52 HP, +0 MP, Hat. Effect: +1 Speed; Immune Slow."},
    "Twist Headband" : {"hp":60, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+58 HP, +0 MP, Hat. Effect: +2 PA."},
    "Holy Miter" : {"hp":72, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+72 HP, +20 MP, Hat. Effect: +1 MA."},
    "Black Hood" : {"hp":100, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+100 HP, +0 MP, Hat."},
    "Golden Hairpin" : {"hp":80, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+80 HP, +50 MP, Hat. Effect: Immune Silence."},
    "Flash Hat" : {"hp":88, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+88 HP, +15 MP, Hat. Effect: +1 MA, +1 Speed."},
    "Thief Hat" : {"hp":64, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+64 HP, +0 MP, Hat. Effect: +2 Speed; Immune Don't Move."},
    "Cachusha" : {"hp":25, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+25 HP, +5 MP, Hat. Effect: Immune Undead, Darkness, Silence, Oil, Frog, Poison, Slow, Don't Move, Don't Act."},
    "Barette" : {"hp":27, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+27 HP, +3 MP, Hat. Effect: Immune Death, Petrify, Confusion, Blood Suck, Berserk, Stop, Charm, Sleep, Death Sentence."},
    "Ribbon" : {"hp":20, "mp":0, "speed":0, "jump":0, "move":0, "pa":0, "ma":0, "ttip" : "+20 HP, +4 MP, Hat. Effect: Immune Death, Undead, Petrify, Darkness, Confusion, Silence, Blood Suck, Oil, Berserk, Frog, Poison, Slow, Stop, Charm, Sleep, Don't Move, Don't Act, Death Sentence."},
    "Leather Armor" : {"hp":38, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+38 HP, +0 MP, Armor. Effect: +1 Speed."},
    "Linen Cuirass" : {"hp":44, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+44 HP, +0 MP, Armor. Effect: Initial Regen."},
    "Bronze Armor" : {"hp":50, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+50 HP, +0 MP, Armor."},
    "Chain Mail" : {"hp":56, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+56 HP, +0 MP, Armor. Effect: +1 Jump."},
    "Mythril Armor" : {"hp":62, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+62 HP, +4 MP, Armor. Effect: +1 Speed."},
    "Plate Mail" : {"hp":68, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+68 HP, +0 MP, Armor. Effect: +1 PA."},
    "Gold Armor" : {"hp":76, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+76 HP, +8 MP, Armor."},
    "Diamond Armor" : {"hp":84, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+84 HP, +6 MP, Armor."},
    "Platinum Armor" : {"hp":92, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+92 HP, +0 MP, Armor."},
    "Carabini Mail" : {"hp":100, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+100 HP, +0 MP, Armor."},
    "Crystal Mail" : {"hp":110, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+110 HP, +2 MP, Armor."},
    "Genji Armor" : {"hp":140, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+140 HP, +0 MP, Armor."},
    "Reflect Mail" : {"hp":120, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+120 HP, +0 MP, Armor. Effect: Always Reflect."},
    "Maximillian" : {"hp":170, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+170 HP, +0 MP, Armor."},
    "Clothes" : {"hp":33, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+33 HP, +13 MP, Clothes. Effect: +1 Move."},
    "Leather Outfit" : {"hp":36, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+36 HP, +0 MP, Clothes. Effect: +1 Speed."},
    "Leather Vest" : {"hp":40, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+40 HP, +0 MP, Clothes. Effect: +1 Speed."},
    "Chain Vest" : {"hp":36, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+36 HP, +0 MP, Clothes. Effect: +1 Jump."},
    "Mystic Vest" : {"hp":40, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+40 HP, +17 MP, Clothes. Effect: +1 MA."},
    "Mythril Vest" : {"hp":56, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+56 HP, +4 MP, Clothes. Effect: +1 Speed."},
    "Adaman Vest" : {"hp":62, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+62 HP, +0 MP, Clothes. Effect: +1 PA."},
    "Wizard Outfit" : {"hp":68, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+68 HP, +15 MP, Clothes. Effect: +1 MA."},
    "Brigandine" : {"hp":74, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+74 HP, +6 MP, Clothes."},
    "Judo Outfit" : {"hp":80, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+80 HP, +2 MP, Clothes. Effect: +1 PA; Immune Death."},
    "Power Sleeve" : {"hp":86, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+86 HP, +0 MP, Clothes. Effect: +2 PA"},
    "Earth Clothes" : {"hp":92, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+92 HP, +10 MP, Clothes. Effect: Absorb Earth; Strengthen Earth."},
    "Secret Clothes" : {"hp":44, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+44 HP, +0 MP, Clothes. Effect: +2 Speed; Initial Transparent."},
    "Black Costume" : {"hp":100, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+100 HP, +0 MP, Clothes. Effect: Immune Stop."},
    "Rubber Costume" : {"hp":115, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+115 HP, +30 MP, Clothes. Effect: Cancel Lightning; Immune Don't Act."},
    "Linen Robe" : {"hp":36, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+36 HP, +12 MP, Robe. Effect: +1 MA."},
    "Silk Robe" : {"hp":40, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+40 HP, +16 MP, Robe. Effect: +1 Speed."},
    "Wizard Robe" : {"hp":45, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+45 HP, +22 MP, Robe. Effect: +2 MA."},
    "Chameleon Robe" : {"hp":51, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+51 HP, +28 MP, Robe. Effect: Absorb Holy, Strengthen Water; Immune Death."},
    "White Robe" : {"hp":58, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+58 HP, +34 MP, Robe. Effect: Half Fire, Lightning, Ice."},
    "Black Robe" : {"hp":66, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+66 HP, +30 MP, Robe. Effect: Strengthen Fire, Lightning, Ice."},
    "Light Robe" : {"hp":75, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+75 HP, +50 MP, Robe. Effect: +1 MA; Absorb Dark; Strengthen Holy."},
    "Robe of Lords" : {"hp":100, "mp":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "ttip" : "+100 HP, +80 MP, Robe. Effect: +2 PA, +1 MA; Permanent Protect, Shell."}
}