<template>
  <div v-if="loading_sources == false && loading_sources2 == false && loading_sources3 == false && loading_sources4 == false && loading_sources5 == false" class="overall-page">

  <div class="container">
    <div id="app">
      <a href="/"><h1>Luxor's Wiznaib.us</h1></a>
      <a :href="'/?tournament=' + this.previous_tournament_id"  style="margin-right: 5px; background:#fff; color: #333; padding: 3px 5px;">Previous Tournament</a>
      <a href="https://toc21.wiznaib.us" style="margin-right: 5px; background:#fff; color: #333; padding: 3px 5px;">TOC 21 Teams</a>
<button v-on:click="hideStats = !hideStats">Very Old Stats</button>




<div :class="{ hidden: hideStats }">
      <form id="search">
    Search <input name="query" v-model="searchQuery">
  </form>
  <DemoGrid
    :data="gridData"
    :columns="gridColumns"
    :filter-key="searchQuery">
  </DemoGrid>
  </div>


<div class="bracket-holder">
  <div class="tournament-bracket tournament-bracket--rounded">                                                     
    <div class="tournament-bracket__round tournament-bracket__round--quarterfinals">
      <h3 class="tournament-bracket__round-title">Quarterfinals</h3>
      <ul class="tournament-bracket__list">
        <li class="tournament-bracket__item">
          <div class="tournament-bracket__match" tabindex="0">
            <div class="bracket-match__content">
              <h5>Red vs. Blue</h5>
              <h5 class="hasToolTip">{{maps[0]}}
                <span class="toolTipSide">
                  <div class="map-holder">
                    <h3>{{maps[0]}}</h3>
                  <img :src="IMG_URL_MAPS + maps[0].split(')')[0] + '_1.gif'" />
                  <img :src="IMG_URL_MAPS + maps[0].split(')')[0] + '_2.gif'" />
                  <img :src="IMG_URL_MAPS + maps[0].split(')')[0] + '_3.gif'" />
                  <img :src="IMG_URL_MAPS + maps[0].split(')')[0] + '_4.gif'" />
                </div>
                </span>
              </h5>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('red')"  class="left-team red-team" :class="{ won : redBracketR1WIN, lost : blueBracketR1WIN }">
                <p class="hasToolTip" v-for="members in Redteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :classRank=classRank  :allunits=items :enemyColor=colorBlue :teamColor=colorRed :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('blue')" class="right-team blue-team" :class="{ won : blueBracketR1WIN, lost : redBracketR1WIN }">
                <p class="hasToolTip" v-for="members in Blueteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :classRank=classRank :allunits=items :teamColor=colorBlue :enemyColor=colorRed :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
            </div>
          </div>
        </li>


        <li class="tournament-bracket__item">
          <div class="tournament-bracket__match" tabindex="0">
            <div class="bracket-match__content">
              <h5>Green vs. Yellow</h5>
              <h5 class="hasToolTip">{{maps[1]}}
                <span class="toolTipSide">
                  <div class="map-holder">
                    <h3>{{maps[1]}}</h3>
                  <img :src="IMG_URL_MAPS + maps[1].split(')')[0] + '_1.gif'" />
                  <img :src="IMG_URL_MAPS + maps[1].split(')')[0] + '_2.gif'" />
                  <img :src="IMG_URL_MAPS + maps[1].split(')')[0] + '_3.gif'" />
                  <img :src="IMG_URL_MAPS + maps[1].split(')')[0] + '_4.gif'" />
                </div>
                </span>
              </h5>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('green')" class="left-team green-team" :class="{ won : greenBracketR1WIN, lost : yellowBracketR1WIN }">
                <p class="hasToolTip" v-for="members in Greenteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :classRank=classRank :allunits=items :enemyColor=colorYellow :teamColor=colorGreen :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('yellow')" class="right-team yellow-team" :class="{ won : yellowBracketR1WIN, lost : greenBracketR1WIN }">
                <p class="hasToolTip" v-for="members in Yellowteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :classRank=classRank :allunits=items :enemyColor=colorGreen :teamColor=colorYellow :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
            </div>
          </div>
        </li>
        <li class="tournament-bracket__item">
          <div class="tournament-bracket__match" tabindex="0">
            <div class="bracket-match__content">
              <h5>White vs. Black</h5>
              <h5 class="hasToolTip">{{maps[2]}}
                <span class="toolTipSide">
                  <div class="map-holder">
                    <h3>{{maps[2]}}</h3>
                  <img :src="IMG_URL_MAPS + maps[2].split(')')[0] + '_1.gif'" />
                  <img :src="IMG_URL_MAPS + maps[2].split(')')[0] + '_2.gif'" />
                  <img :src="IMG_URL_MAPS + maps[2].split(')')[0] + '_3.gif'" />
                  <img :src="IMG_URL_MAPS + maps[2].split(')')[0] + '_4.gif'" />
                </div>
                </span>
              </h5>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('white')" class="left-team white-team" :class="{ won : whiteBracketR1WIN, lost : blackBracketR1WIN }">
                <p class="hasToolTip" v-for="members in Whiteteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :classRank=classRank :allunits=items :enemyColor=colorBlack :teamColor=colorWhite  :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('black')" class="right-team black-team" :class="{ won : blackBracketR1WIN, lost : whiteBracketR1WIN }">
                <p class="hasToolTip" v-for="members in Blackteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :classRank=classRank :allunits=items :enemyColor=colorWhite :teamColor=colorBlack :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
            </div>
          </div>
        </li>

        <li class="tournament-bracket__item">
          <div class="tournament-bracket__match" tabindex="0">
            <div class="bracket-match__content">
              <h5>Purple vs. Brown</h5>
              <h5 class="hasToolTip">{{maps[3]}}
                <span class="toolTipSide">
                  <div class="map-holder">
                    <h3>{{maps[3]}}</h3>
                  <img :src="IMG_URL_MAPS + maps[3].split(')')[0] + '_1.gif'" />
                  <img :src="IMG_URL_MAPS + maps[3].split(')')[0] + '_2.gif'" />
                  <img :src="IMG_URL_MAPS + maps[3].split(')')[0] + '_3.gif'" />
                  <img :src="IMG_URL_MAPS + maps[3].split(')')[0] + '_4.gif'" />
                </div>
                </span>
              </h5>

              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('purple')" class="left-team purple-team" :class="{ won : purpleBracketR1WIN, lost : brownBracketR1WIN }">
                <p class="hasToolTip" v-for="members in Purpleteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :classRank=classRank :allunits=items :enemyColor=colorBrown :teamColor=colorPurple :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('brown')" class="right-team brown-team" :class="{ won : brownBracketR1WIN, lost : purpleBracketR1WIN }">
                <p class="hasToolTip" v-for="members in Brownteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :classRank=classRank :allunits=items :enemyColor=colorPurple :teamColor=colorBrown :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="tournament-bracket__round tournament-bracket__round--semifinals">
      <h3 class="tournament-bracket__round-title">Semifinals</h3>
      <ul class="tournament-bracket__list">
        <li class="tournament-bracket__item">
          <div class="tournament-bracket__match" tabindex="0">
            <div class="bracket-match__content semi">
              <h5><span v-if="redBracketR1WIN">Red</span><span v-if="blueBracketR1WIN">Blue</span> vs. <span v-if="greenBracketR1WIN">Green</span><span v-if="yellowBracketR1WIN">Yellow</span></h5>
              <h5 class="hasToolTip">{{maps[4]}}
                <span class="toolTipSide">
                  <div class="map-holder">
                    <h3>{{maps[4]}}</h3>
                  <img :src="IMG_URL_MAPS + maps[4].split(')')[0] + '_1.gif'" />
                  <img :src="IMG_URL_MAPS + maps[4].split(')')[0] + '_2.gif'" />
                  <img :src="IMG_URL_MAPS + maps[4].split(')')[0] + '_3.gif'" />
                  <img :src="IMG_URL_MAPS + maps[4].split(')')[0] + '_4.gif'" />
                </div>
                </span>
              </h5>

              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('red')" v-if="redBracketR1WIN" class="left-team red-team" :class="{ won : topleftBracketR2WIN, lost : toprightBracketR2WIN }">
                <p class="hasToolTip" v-for="members in Redteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=semiOneRight :classRank=classRank :teamColor=colorRed :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('blue')" v-if="blueBracketR1WIN" class="left-team blue-team" :class="{ won : topleftBracketR2WIN, lost : toprightBracketR2WIN }">
                <p class="hasToolTip" v-for="members in Blueteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=semiOneRight :classRank=classRank :teamColor=colorBlue :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('green')" v-if="greenBracketR1WIN" class="right-team green-team" :class="{ won : toprightBracketR2WIN, lost : topleftBracketR2WIN }">
                <p class="hasToolTip" v-for="members in Greenteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=semiOneLeft :classRank=classRank :teamColor=colorYellow :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('yellow')" v-if="yellowBracketR1WIN" class="right-team yellow-team" :class="{ won : toprightBracketR2WIN, lost : topleftBracketR2WIN }">
                <span v-if="semiOneRight = colorYellow"></span>
                <p class="hasToolTip" v-for="members in Yellowteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=semiOneLeft :classRank=classRank :teamColor=colorGreen :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
            </div>
          </div>
        </li>

        <li class="tournament-bracket__item">
          <div class="tournament-bracket__match" tabindex="0">
            <div class="bracket-match__content semi">
              <h5><span v-if="whiteBracketR1WIN">White</span><span v-if="blackBracketR1WIN">Black</span> vs. <span v-if="purpleBracketR1WIN">Purple</span><span v-if="brownBracketR1WIN">Brown</span></h5>
              <h5 class="hasToolTip">{{maps[5]}}
                <span class="toolTipSide">
                  <div class="map-holder">
                    <h3>{{maps[5]}}</h3>
                  <img :src="IMG_URL_MAPS + maps[5].split(')')[0] + '_1.gif'" />
                  <img :src="IMG_URL_MAPS + maps[5].split(')')[0] + '_2.gif'" />
                  <img :src="IMG_URL_MAPS + maps[5].split(')')[0] + '_3.gif'" />
                  <img :src="IMG_URL_MAPS + maps[5].split(')')[0] + '_4.gif'" />
                </div>
                </span>
              </h5>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('white')" v-if="whiteBracketR1WIN" class="left-team white-team" :class="{ won : bottomleftBracketR2WIN, lost : bottomrightBracketR2WIN }">
                <p class="hasToolTip" v-for="members in Whiteteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=semiTwoRight :classRank=classRank :teamColor=colorWhite :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('black')" v-if="blackBracketR1WIN" class="left-team black-team" :class="{ won : bottomleftBracketR2WIN, lost : bottomrightBracketR2WIN }">
                <p class="hasToolTip" v-for="members in Blackteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=semiTwoRight :classRank=classRank :teamColor=colorBlack :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('purple')" v-if="purpleBracketR1WIN" class="right-team purple-team" :class="{ won : bottomrightBracketR2WIN, lost : bottomleftBracketR2WIN }">
                <p class="hasToolTip" v-for="members in Purpleteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=semiTwoLeft :classRank=classRank :teamColor=colorPurple :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('brown')" v-if="brownBracketR1WIN" class="right-team brown-team" :class="{ won : bottomrightBracketR2WIN, lost : bottomleftBracketR2WIN }">
                <p class="hasToolTip" v-for="members in Brownteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=semiTwoLeft :classRank=classRank :teamColor=colorBrown :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="tournament-bracket__round tournament-bracket__round--bronze">
      <h3 class="tournament-bracket__round-title">Finals</h3>
      <ul class="tournament-bracket__list">
        <li class="tournament-bracket__item">
          <div class="tournament-bracket__match" tabindex="0">
            <div class="bracket-match__content final">
              <h5><span v-if="redBracketR1WIN && topleftBracketR2WIN">Red</span><span v-if="blueBracketR1WIN && topleftBracketR2WIN">Blue</span><span v-if="greenBracketR1WIN && toprightBracketR2WIN">Green</span><span v-if="yellowBracketR1WIN && toprightBracketR2WIN">Yellow</span> vs. <span v-if="whiteBracketR1WIN && bottomleftBracketR2WIN">White</span><span v-if="blackBracketR1WIN && bottomleftBracketR2WIN">Black</span><span v-if="purpleBracketR1WIN && bottomrightBracketR2WIN">Purple</span><span v-if="brownBracketR1WIN && bottomrightBracketR2WIN">Brown</span></h5>
              <h5 class="hasToolTip">{{maps[6]}}
                <span class="toolTipSide">
                  <div class="map-holder">
                    <h3>{{maps[6]}}</h3>
                  <img :src="IMG_URL_MAPS + maps[6].split(')')[0] + '_1.gif'" />
                  <img :src="IMG_URL_MAPS + maps[6].split(')')[0] + '_2.gif'" />
                  <img :src="IMG_URL_MAPS + maps[6].split(')')[0] + '_3.gif'" />
                  <img :src="IMG_URL_MAPS + maps[6].split(')')[0] + '_4.gif'" />
                </div>
                </span>
              </h5>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('red')" v-if="redBracketR1WIN && topleftBracketR2WIN" class="left-team red-team" :class="{ won : topleftBracketR3WIN, lost : toprightBracketR3WIN }">
                <p class="hasToolTip" v-for="members in Redteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=finalRight :classRank=classRank :teamColor=colorRed :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('blue')" v-if="blueBracketR1WIN && topleftBracketR2WIN" class="left-team blue-team" :class="{ won : topleftBracketR3WIN, lost : toprightBracketR3WIN }">
                <p class="hasToolTip" v-for="members in Blueteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=finalRight :classRank=classRank :teamColor=colorBlue :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('green')" v-if="greenBracketR1WIN && toprightBracketR2WIN" class="left-team green-team" :class="{ won : topleftBracketR3WIN, lost : toprightBracketR3WIN }">
                <p class="hasToolTip" v-for="members in Greenteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=finalRight :classRank=classRank :teamColor=colorGreen :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('yellow')" v-if="yellowBracketR1WIN && toprightBracketR2WIN" class="left-team yellow-team" :class="{ won : topleftBracketR3WIN, lost : toprightBracketR3WIN }">
                <p class="hasToolTip" v-for="members in Yellowteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=finalRight :classRank=classRank :teamColor=colorYellow :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('white')" v-if="whiteBracketR1WIN && bottomleftBracketR2WIN" class="right-team white-team" :class="{ won : toprightBracketR3WIN, lost : topleftBracketR3WIN }">
                <p class="hasToolTip" v-for="members in Whiteteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=finalLeft :classRank=classRank :teamColor=colorWhite :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('black')" v-if="blackBracketR1WIN && bottomleftBracketR2WIN" class="right-team black-team" :class="{ won : toprightBracketR3WIN, lost : topleftBracketR3WIN }">
                <p class="hasToolTip" v-for="members in Blackteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=finalLeft :classRank=classRank :teamColor=colorBlack :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('purple')" v-if="purpleBracketR1WIN && bottomrightBracketR2WIN" class="right-team purple-team" :class="{ won : toprightBracketR3WIN, lost : topleftBracketR3WIN }">
                <p class="hasToolTip" v-for="members in Purpleteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=finalLeft :classRank=classRank :teamColor=colorPurple :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('brown')" v-if="brownBracketR1WIN && bottomrightBracketR2WIN" class="right-team brown-team" :class="{ won : toprightBracketR3WIN, lost : topleftBracketR3WIN }">
                <p class="hasToolTip" v-for="members in Brownteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=finalLeft :classRank=classRank :teamColor=colorBrown :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="tournament-bracket__round tournament-bracket__round--gold">
      <h3 class="tournament-bracket__round-title">Champ Match</h3>
      <ul class="tournament-bracket__list">
        <li class="tournament-bracket__item">
          <div class="tournament-bracket__match" tabindex="0">
            <div class="bracket-match__content champ">
              <h5><span v-if="redBracketR1WIN && topleftBracketR2WIN && topleftBracketR3WIN">Red</span><span v-if="blueBracketR1WIN && topleftBracketR2WIN && topleftBracketR3WIN">Blue</span><span v-if="greenBracketR1WIN && toprightBracketR2WIN && topleftBracketR3WIN">Green</span><span v-if="yellowBracketR1WIN && toprightBracketR2WIN && topleftBracketR3WIN">Yellow</span><span v-if="whiteBracketR1WIN && bottomleftBracketR2WIN && toprightBracketR3WIN">White</span><span v-if="blackBracketR1WIN && bottomleftBracketR2WIN && toprightBracketR3WIN">Black</span><span v-if="purpleBracketR1WIN && bottomrightBracketR2WIN && toprightBracketR3WIN">Purple</span><span v-if="brownBracketR1WIN && bottomrightBracketR2WIN && toprightBracketR3WIN">Brown</span> vs. Champions</h5>
              <h5 class="hasToolTip">{{maps[7]}}
                <span class="toolTipSide">
                  <div class="map-holder">
                    <h3>{{maps[7]}}</h3>
                  <img :src="IMG_URL_MAPS + maps[7].split(')')[0] + '_1.gif'" />
                  <img :src="IMG_URL_MAPS + maps[7].split(')')[0] + '_2.gif'" />
                  <img :src="IMG_URL_MAPS + maps[7].split(')')[0] + '_3.gif'" />
                  <img :src="IMG_URL_MAPS + maps[7].split(')')[0] + '_4.gif'" />
                </div>
                </span>
              </h5>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('red')" v-if="redBracketR1WIN && topleftBracketR2WIN && topleftBracketR3WIN" class="red-team left-team" :class="{ won : topleftBracketR4WIN, lost : toprightBracketR4WIN }">
                <p class="hasToolTip" v-for="members in Redteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=colorChampion :classRank=classRank :teamColor=colorRed :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('blue')" v-if="blueBracketR1WIN && topleftBracketR2WIN && topleftBracketR3WIN" class="blue-team left-team" :class="{ won : topleftBracketR4WIN, lost : toprightBracketR4WIN }">
                <p class="hasToolTip" v-for="members in Blueteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=colorChampion :classRank=classRank :teamColor=colorBlue :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('green')" v-if="greenBracketR1WIN && toprightBracketR2WIN && topleftBracketR3WIN" class="green-team left-team" :class="{ won : topleftBracketR4WIN, lost : toprightBracketR4WIN }">
                <p class="hasToolTip" v-for="members in Greenteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=colorChampion :classRank=classRank :teamColor=colorGreen :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('yellow')" v-if="yellowBracketR1WIN && toprightBracketR2WIN && topleftBracketR3WIN" class="yellow-team left-team" :class="{ won : topleftBracketR4WIN, lost : toprightBracketR4WIN }">
                <p class="hasToolTip" v-for="members in Yellowteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=colorChampion :classRank=classRank :teamColor=colorYellow :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('white')" v-if="whiteBracketR1WIN && bottomleftBracketR2WIN && toprightBracketR3WIN" class="white-team left-team" :class="{ won : topleftBracketR4WIN, lost : toprightBracketR4WIN }">
                <p class="hasToolTip" v-for="members in Whiteteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=colorChampion :classRank=classRank :teamColor=colorWhite :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('black')" v-if="blackBracketR1WIN && bottomleftBracketR2WIN && toprightBracketR3WIN" class="black-team left-team" :class="{ won : topleftBracketR4WIN, lost : toprightBracketR4WIN }">
                <p class="hasToolTip" v-for="members in Blackteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=colorChampion :classRank=classRank :teamColor=colorBlack :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('purple')" v-if="purpleBracketR1WIN && bottomrightBracketR2WIN && toprightBracketR3WIN" class="purple-team left-team" :class="{ won : topleftBracketR4WIN, lost : toprightBracketR4WIN }">
                <p class="hasToolTip" v-for="members in Purpleteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=colorChampion :classRank=classRank :teamColor=colorPurple :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('brown')" v-if="brownBracketR1WIN && bottomrightBracketR2WIN && toprightBracketR3WIN" class="brown-team left-team" :class="{ won : topleftBracketR4WIN, lost : toprightBracketR4WIN }">
                <p class="hasToolTip" v-for="members in Brownteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=colorChampion :classRank=classRank :teamColor=colorBrown :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>

              <div v-on:mousedown="bracketClick()" v-on:mouseup="goto('champion')" class="right-team champ-team" :class="{ won : toprightBracketR4WIN, lost : topleftBracketR4WIN }">
                <p class="hasToolTip" v-for="members in Champteam">
                    <template v-if="members.RaidBoss">[!] </template>{{members.Name}} the {{members.Class}}
                    <div class="toolTipSide">
                      <HoverUnit :allunits=items :enemyColor=champLeft :classRank=classRank :teamColor=colorChampion :notAbles=notAbles :charStats=charStats :members=members :JobStats=JobStats :itemschanged=itemschanged />
                    </div>
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>






  <select v-model="product">
    <option value="" selected disabled hidden>Choose a past tournament</option>
    <option v-for="carrier in tournaments">{{ carrier.ID }}</option>
  </select>

   
  


      <div class="top-bar">

      <div class="buttons">
          <button v-on:click.shift="buttonAdjuster();" :class="{ hiding: redIsHidden }" v-on:click="redIsHidden = !redIsHidden">Red</button>
          <button v-on:click.shift="buttonAdjuster();" :class="{ hiding: blueIsHidden }" v-on:click="blueIsHidden = !blueIsHidden">Blue</button>
          <button v-on:click.shift="buttonAdjuster();" :class="{ hiding: greenIsHidden }" v-on:click="greenIsHidden = !greenIsHidden">Green</button>
          <button v-on:click.shift="buttonAdjuster();" :class="{ hiding: yellowIsHidden }" v-on:click="yellowIsHidden = !yellowIsHidden">Yellow</button>
          <button v-on:click.shift="buttonAdjuster();" :class="{ hiding: whiteIsHidden }" v-on:click="whiteIsHidden = !whiteIsHidden">White</button>
          <button v-on:click.shift="buttonAdjuster();" :class="{ hiding: blackIsHidden }" v-on:click="blackIsHidden = !blackIsHidden">Black</button>
          <button v-on:click.shift="buttonAdjuster();" :class="{ hiding: purpleIsHidden }" v-on:click="purpleIsHidden = !purpleIsHidden">Purple</button>
          <button v-on:click.shift="buttonAdjuster();" :class="{ hiding: brownIsHidden }" v-on:click="brownIsHidden = !brownIsHidden">Brown</button>
          <button v-on:click.shift="buttonAdjuster();" :class="{ hiding: champIsHidden }" v-on:click="champIsHidden = !champIsHidden">Champ</button>
      </div>


      
      <div class="snubs-entries">
        <div v-if="entrants.includes('luxordoesntframe') || entrants.includes('yoimbear') || entrants.includes('firstodie')" class="entries">
          <div v-for="entrant of entrants">
            <span v-if="entrant == 'luxordoesntframe'">{{entrant}} is in!</span>
            <span v-if="entrant == 'yoimbear'">{{entrant}} is in!</span>
            <span v-if="entrant == 'firstodie'">{{entrant}} is in!</span>
          </div>
        </div>


        <div v-if="snubs.includes('luxordoesntframe') || snubs.includes('yoimbear') || snubs.includes('firstodie')" class="snubs">
        <div v-for="snub of snubs">
        <span v-if="snub == 'luxordoesntframe'">{{snub}} was Snubbed.</span>
        <span v-if="snub == 'yoimbear'">{{snub}} was Snubbed.</span>
        <span v-if="snub == 'firstodie'">{{snub}} was Snubbed.</span>
        </div>
        </div>
      </div>
      </div>




<div v-if="searchActive">
  <h1>You Searched For {{unitName}}</h1>

  <div class="search-box">
    <div  class="red team">
      <div class="holder">
        <InlineUnit v-for="unit of monks" :teamColor=colorChampion :classRank=classRank :toolTips=toolTips :notAbles=notAbles :charStats=charStats :unit=unit :JobStats=JobStats :itemschanged=itemschanged />
      </div>
    </div>
  </div>
</div>

      
    <div class="teams"
    
    :class="{ hidered: redIsHidden, hideblue: blueIsHidden, hidegreen: greenIsHidden, hideyellow: yellowIsHidden, hidewhite: whiteIsHidden, hideblack: blackIsHidden, hidepurple: purpleIsHidden, hidebrown: brownIsHidden, hidechamp: champIsHidden }"
    >

    <div ref="red" class="red team">
      <h3>Red Team</h3>

      <div class="holder">
        <InlineUnit v-for="unit of Redteam" :teamColor=colorRed :classRank=classRank :toolTips=toolTips :notAbles=notAbles :charStats=charStats :unit=unit :JobStats=JobStats :itemschanged=itemschanged />
      </div>
    </div>

    <div ref="blue" class="blue team">
      <h3>Blue Team</h3>

      <div class="holder">
        <InlineUnit v-for="unit of Blueteam" :teamColor=colorBlue :classRank=classRank :toolTips=toolTips :notAbles=notAbles :charStats=charStats :unit=unit :JobStats=JobStats :itemschanged=itemschanged />
      </div>
    </div>

    <div ref="green" class="green team">
      <h3>Green Team</h3>

      <div class="holder">
        <InlineUnit v-for="unit of Greenteam" :teamColor=colorGreen :classRank=classRank :toolTips=toolTips :notAbles=notAbles :charStats=charStats :unit=unit :JobStats=JobStats :itemschanged=itemschanged />
      </div>
    </div>

    <div ref="yellow" class="yellow team">
      <h3>Yellow Team</h3>

      <div class="holder">
        <InlineUnit v-for="unit of Yellowteam" :teamColor=colorYellow :classRank=classRank :toolTips=toolTips :notAbles=notAbles :charStats=charStats :unit=unit :JobStats=JobStats :itemschanged=itemschanged />
      </div>
    </div>

    <div ref="white" class="white team">
      <h3>White Team</h3>

      <div class="holder">
        <InlineUnit v-for="unit of Whiteteam" :teamColor=colorWhite :classRank=classRank :toolTips=toolTips :notAbles=notAbles :charStats=charStats :unit=unit :JobStats=JobStats :itemschanged=itemschanged />
      </div>
    </div>

    <div ref="black" class="black team">
      <h3>Black Team</h3>

      <div class="holder">
        <InlineUnit v-for="unit of Blackteam" :teamColor=colorBlack :classRank=classRank :toolTips=toolTips :notAbles=notAbles :charStats=charStats :unit=unit :JobStats=JobStats :itemschanged=itemschanged />
      </div>
    </div>

    <div ref="purple" class="purple team">
      <h3>Purple Team</h3>

      <div class="holder">
        <InlineUnit v-for="unit of Purpleteam" :teamColor=colorPurple :classRank=classRank :toolTips=toolTips :notAbles=notAbles :charStats=charStats :unit=unit :JobStats=JobStats :itemschanged=itemschanged />
      </div>
    </div>

    <div ref="brown" class="brown team">
      <h3>Brown Team</h3>

      <div class="holder">
        <InlineUnit v-for="unit of Brownteam" :teamColor=colorBrown :classRank=classRank :toolTips=toolTips :notAbles=notAbles :charStats=charStats :unit=unit :JobStats=JobStats :itemschanged=itemschanged />
      </div>
    </div>

    <div ref="champion" class="champion team">
      <h3>Champ Team</h3>

      <div class="holder">
        <InlineUnit v-for="unit of Champteam" :teamColor=colorChampion :classRank=classRank :toolTips=toolTips :notAbles=notAbles :charStats=charStats :unit=unit :JobStats=JobStats :itemschanged=itemschanged />
      </div>
    </div>

      </div>
    



  </div>
  </div>
  </div>
  <div v-else>
    <h1>Luxor's Wiznaib.us</h1>
    <h2>Currently Awaiting Data.</h2>
    <h2>Loading Item Data From Mustad.io : {{loading_sources}}</h2>
    <h2>Loading Tournament Data from fftbg : {{loading_sources2}}</h2>
    <h2>Loading Tooltip Data from fftbg : {{loading_sources3}}</h2>
    <h2>Loading Unit Stats Data from Mustad.io : {{loading_sources4}}</h2>
    <h2>Loading Previous Tournament IDs from fftbg : {{loading_sources5}}</h2>
  </div>
</template>

<script>

import DemoGrid from './grid.vue';

import HoverUnit from './hoverUnit.vue';
import InlineUnit from './inlineUnit.vue';

import { initializeApp } from "firebase/app";

import { getFirestore, collection, getDocs } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyAUULbs_L2TFf6FyteflnGcAUad0XBF0vI",
  authDomain: "luxstadio.firebaseapp.com",
  projectId: "luxstadio",
  storageBucket: "luxstadio.appspot.com",
  messagingSenderId: "1019542062601",
  appId: "1:1019542062601:web:6a6880e750432615819ad8"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);


import {  doc, setDoc, where, query, addDoc,collection, getDocs, limit } from "firebase/firestore"; 

import jobStats from "./jobStats.json";
import noteables from "./notables.json";
import itemStats from "./itemStats.json";
import classRanks from "./classrank.json";
import tableDataq99m from "./tabledataq99m.json";

import axios from "axios";
export default {
  name: "App",
  components: {
    HoverUnit,
    InlineUnit,
    DemoGrid,
  },
  data() {
    return {
      firebaseData:[],
      product: "hi",
      data: false,
      classRank: classRanks,
      hideStats: true,
      db: [],
      items: [],
      unitName: "",
      red_hp: 0,
      colors: ["red" , "blue", "green", "yellow", "white", "black", "purple", "brown", "champ"],
      unit: [],
      teams: [],
      tournament_data_present: false,
      teams2: [],
      allunits: [],
      entrants: [],
      itemschanged: [],
      blackteam: [],
      actualitems: [],
      toolTips: [],
      maps: [],
      monks: [],
      redIsHidden : false,
      searchActive : false,
      blueIsHidden : false,
      greenIsHidden : false,
      yellowIsHidden : false,
      whiteIsHidden : false,
      blackIsHidden : false,
      purpleIsHidden : false,
      brownIsHidden : false,
      current_tournament_id : "",
      previous_tournament_id : "",
      winners: [],
      champIsHidden : false,
      loading_sources : true,
      loading_sources2 : true,
      loading_sources3 : true,
      loading_sources4 :true,
      loading_sources5 :true,
      snubs: [],
      IMG_URL : "https://luxor-fftbg-assets.s3.amazonaws.com/units/",
      IMG_URL_ITEMS : "https://luxor-fftbg-assets.s3.amazonaws.com/icons/",
      IMG_URL_MAPS : "https://luxor-fftbg-assets.s3.amazonaws.com/maps/",
      JobStats: jobStats,
      notAbles : noteables,
      ItemStats: itemStats,
      TOURNAMENT_API_URL : "https://fftbg.com/api/tournament/latest",
      TOURNAMENT_API_PREFIX : "https://fftbg.com/api/tournament/",
      itemName: "",
      redBracketR1WIN: false, 
      blueBracketR1WIN: false,
      greenBracketR1WIN: false,
      colorRed: "Red",
      colorBlue: "Blue",
      colorGreen: "Green",
      colorYellow: "Yellow",
      colorWhite: "White",
      colorBlack: "Black",
      colorPurple: "Purple",
      colorBrown: "Brown",
      colorChampion: "Champion",
      yellowBracketR1WIN: false,
      whiteBracketR1WIN: false,
      blackBracketR1WIN: false,
      purpleBracketR1WIN: false,
      brownBracketR1WIN: false,
      topleftBracketR2WIN: false,
      toprightBracketR2WIN: false,
      bottomleftBracketR2WIN: false,
      bottomrightBracketR2WIN: false,
      topleftBracketR3WIN: false,
      toprightBracketR3WIN: false,
      topleftBracketR4WIN: false,
      toprightBracketR4WIN: false,
      champLeft: "Champion",
      searchQuery: '',

    gridColumns: ['Class', 'Gender', 'Rank', 'ChampRate', 'AverageStreak', 'NumChamps', 'NumRolls', 'SumofStreaks'],
    gridData: tableDataq99m,
    };
  },
  
  watch: {
        LocalItemStats: function(value) {
            // If "pageData" ever changes, then we will console log its new value.

        },

        product: function(value) {
            window.location.href = "/?tournament=" + this.product;
        },



        

        winners: function(value) {
            // When Winners changes, check which teams won and hide losers.

          if (this.winners[0] == "red") {
            this.blueIsHidden = true;
            this.redBracketR1WIN = true;
            this.semiOneLeft = this.colorRed;

          }
          if (this.winners[0] == "blue") {
            this.redIsHidden = true;
            this.blueBracketR1WIN = true;
            this.semiOneLeft = this.colorBlue;
          }
          if (this.winners[1] == "green") {
            this.yellowIsHidden = true;
            this.greenBracketR1WIN = true;
            this.semiOneRight = this.colorGreen;

          }
          if (this.winners[1] == "yellow") {
            this.greenIsHidden = true;
            this.yellowBracketR1WIN = true;
            this.semiOneRight = this.colorYellow;
          }
          if (this.winners[2] == "white") {
            this.blackIsHidden = true;
            this.whiteBracketR1WIN = true;
            this.semiTwoLeft = this.colorWhite;
          }
          if (this.winners[2] == "black") {
            this.whiteIsHidden = true;
            this.blackBracketR1WIN = true;
            this.semiTwoLeft = this.colorBlack;
          }
          if (this.winners[3] == "purple") {
            this.brownIsHidden = true;
            this.purpleBracketR1WIN = true;
            this.semiTwoRight = this.colorPurple;
          }
          if (this.winners[3] == "brown") {
            this.purpleIsHidden = true;
            this.brownBracketR1WIN = true;
            this.semiTwoRight = this.colorBrown;
          }
          if (this.winners[4] == "red" || this.winners[4] == "blue") {
            this.yellowIsHidden = true;
            this.greenIsHidden = true;
            this.topleftBracketR2WIN = true;
          }

          if (this.winners[4] == "red") {
            this.finalLeft = this.colorRed;
          }
           if (this.winners[4] == "blue") {
            this.finalLeft = this.colorBlue;
          }
           if (this.winners[4] == "green") {
            this.finalLeft = this.colorGreen;
          }
           if (this.winners[4] == "yellow") {
            this.finalLeft = this.colorYellow;
          }

           if (this.winners[5] == "white") {
            this.finalRight = this.colorWhite;
          }
           if (this.winners[5] == "black") {
            this.finalRight = this.colorBlack;
          }
           if (this.winners[5] == "purple") {
            this.finalRight = this.colorPurple;
          }
           if (this.winners[5] == "brown") {
            this.finalRight = this.colorBrown;
          }

          if (this.winners[6] == "red") {
            this.champLeft = this.colorRed;
          }
           if (this.winners[6] == "blue") {
            this.champLeft = this.colorBlue;
          }
           if (this.winners[6] == "green") {
            this.champLeft = this.colorGreen;
          }
           if (this.winners[6] == "yellow") {
            this.champLeft = this.colorYellow;
          }

           if (this.winners[6] == "white") {
            this.champLeft = this.colorWhite;
          }
           if (this.winners[6] == "black") {
            this.champLeft = this.colorBlack;
          }
           if (this.winners[6] == "purple") {
            this.champLeft = this.colorPurple;
          }
           if (this.winners[6] == "brown") {
            this.champLeft = this.colorBrown;
          }

          if (this.winners[4] == "green" || this.winners[4] == "yellow") {
            this.redIsHidden = true;
            this.blueIsHidden = true;
            this.toprightBracketR2WIN = true;

          }
          if (this.winners[5] == "white" || this.winners[5] == "black") {
            this.purpleIsHidden = true;
            this.brownIsHidden = true;
            this.bottomleftBracketR2WIN = true;
          }
          if (this.winners[5] == "purple" || this.winners[5] == "brown") {
            this.blackIsHidden = true;
            this.whiteIsHidden = true;
            this.bottomrightBracketR2WIN = true;
          }

          if (this.winners[6] == "red" || this.winners[6] == "blue" || this.winners[6] == "green" || this.winners[6] == "yellow") {
            this.blackIsHidden = true;
            this.whiteIsHidden = true;
            this.purpleIsHidden = true;
            this.brownIsHidden = true;
            this.topleftBracketR3WIN = true;
          }
          if (this.winners[6] == "black" || this.winners[6] == "white" || this.winners[6] == "purple" || this.winners[6] == "brown") {
            this.redIsHidden = true;
            this.blueIsHidden = true;
            this.greenIsHidden = true;
            this.yellowIsHidden = true;
            this.toprightBracketR3WIN = true;
          }

          if (this.winners[7] == "red" || this.winners[7] == "blue" || this.winners[7] == "green" || this.winners[7] == "yellow" || this.winners[7] == "black" || this.winners[7] == "white" || this.winners[7] == "purple" || this.winners[7] == "brown") {
            this.topleftBracketR4WIN = true;
          }
          if (this.winners[7] == "champion") {
            this.toprightBracketR4WIN = true;
          }







        }
    },
 
  async created() {
    try {

      const urlParams = new URLSearchParams(window.location.search);
      this.OLD_TOURNAMENT_ID = urlParams.get("tournament");
      if(this.OLD_TOURNAMENT_ID) {
      this.TOURNAMENT_API_URL = this.TOURNAMENT_API_PREFIX + this.OLD_TOURNAMENT_ID;
      console.log(this.TOURNAMENT_API_URL);
      }
      const res = await axios.get(this.TOURNAMENT_API_URL);

      this.current_tournament_id = res.data.ID;
      console.log(this.current_tournament_id);
      this.items = res.data.Teams;

      this.maps = res.data.Maps;





      console.log(this.items);

      this.Blackteam = this.items.black.Units;
      this.Blueteam = this.items.blue.Units;
      this.Redteam = this.items.red.Units;
      this.Greenteam = this.items.green.Units;
      this.Yellowteam = this.items.yellow.Units;
      this.Whiteteam = this.items.white.Units;
      this.Purpleteam = this.items.purple.Units;
      this.Brownteam = this.items.brown.Units;
      this.Champteam = this.items.champion.Units;


     


      function pushArray(arr, arr2) {
          arr.push.apply(arr, arr2);
      }

      pushArray(this.allunits, this.Blueteam);
      pushArray(this.allunits, this.Blackteam);
      pushArray(this.allunits, this.Redteam);
      pushArray(this.allunits, this.Whiteteam);
      pushArray(this.allunits, this.Greenteam);
      pushArray(this.allunits, this.Yellowteam);
      pushArray(this.allunits, this.Purpleteam);
      pushArray(this.allunits, this.Brownteam);


      
console.log(this.allunits);


this.databaseDoStuff(this.allunits, this.current_tournament_id);

      
      this.winners = res.data.Winners;

      this.entrants = res.data.Entrants

      this.snubs = res.data.Snubs
      this.loading_sources2 = false;
    
    } catch (error) {
      console.log(error);
    }

    try {
      const res = await axios.get(`https://fftbg.com/api/tips`);
      this.toolTips = res.data;

      console.log(this.toolTips);

      this.loading_sources3 = false;
    
    } catch (error) {
      console.log(error);
    }


 try {
      const res = await axios.get(`https://fftbg.com/api/tournaments?limit=25`);
      this.tournaments = res.data;

      console.log(this.tournaments);

   this.tournamentlist = this.tournaments.map(a => a.ID);
   console.log(this.tournamentlist);

   this.indexxx = this.tournamentlist.findIndex(x => x == this.current_tournament_id);

   console.log(this.indexxx); // 1

   this.indexxx = this.indexxx+1;
   this.previous_tournament_id = this.tournamentlist[this.indexxx];
   console.log(this.previous_tournament_id);

      this.loading_sources5 = false;
    
    } catch (error) {
      console.log(error);
    }
    
    

    try {
      const res = await axios.get(`https://www.mustad.io/api/classes?include=stats`);
      this.charstatsunparsed = res.data.classes;

      console.log(this.charstatsunparsed);

      this.charStats = this.charstatsunparsed.map((item) => {
      return { 
        [item.name + item.gender] : {"name": item.name, "hp":item.baseStats.hp, "mp":item.baseStats.mp, "move":item.baseStats.move, "jump":item.baseStats.jump, "speed":item.baseStats.speed, "pa":item.baseStats.pa, "ma":item.baseStats.ma, "cEvPercent":item.baseStats.cEvPercent, "innates":item.innates },
      };
      }); 

      this.charStats = Object.assign({}, ...this.charStats);

      this.loading_sources4 = false;
      console.log(this.charStats);

    } catch (error) {
      console.log(error);
    }

    

    try {
      const res = await axios.get(`https://www.mustad.io/api/items?include=stats,info`);
      this.actualitems = res.data.items;

console.log(this.actualitems);

      this.itemschanged = this.actualitems.map((item) => {
      return {

        [item.name] : {"name": item.name, "type":item.type, "info":item.info, "hp":item.stats.hp || 0, "mp":item.stats.mp || 0, "wp":item.stats.wp || item.stats.absorbWp || 0, "move":item.stats.move || 0, "jump":item.stats.jump || 0, "speed":item.stats.speed || 0, "pa":item.stats.pa || 0, "ma":item.stats.ma || 0, "permstatus":item.stats.permStatuses || 0, "initstatus":item.stats.initialStatuses || 0, "mevade":item.stats.magicEvadePercent || 0, "pevade":item.stats.physEvadePercent || 0, "wevade":item.stats.evadePercent || 0, "element":item.stats.element || 0,},
      };
      }); 

            this.itemschanged.push({ "" : {"name": 'name', "type":"type", "info":"info", "hp":0, "mp":0, "move":0, "jump":0, "speed":0, "pa":0, "ma":0, "initstatus":0, "permstatus":0, "mevade":0, "pevade":0, "wevade":0, }});


            //Fixing Crashes by Manually Adding old Items that are not in other databases.
            this.itemschanged.push({ "Leather Vest" : {"name": 'Leather Vest', "type":"Armor", "info":"info", "hp":40, "mp":0, "jump":0, "speed":1, "jump":0, "move":1, "pa":0, "ma":0, "initstatus":0},})
            this.itemschanged.push({ "Broad Sword" : {"name": 'name', "type":"type", "info":"info", "hp":0, "mp":0, "move":0, "jump":0, "speed":0, "pa":1, "ma":0, "initstatus":0, }});
            this.itemschanged.push({ "Long Sword" : {"name": 'Long Sword', "type":"Knight Sword", "info":"info", "wp":5, "hp":0, "mp":0, "move":0, "jump":0, "speed":0, "pa":0, "ma":0, "initstatus":0, }});
            this.itemschanged.push({ "Ice Bow" : {"name": 'name', "type":"type", "info":"info", "hp":0, "mp":0, "move":0, "jump":0, "speed":0, "pa":0, "ma":0, "initstatus":0, }});





      this.itemschanged = Object.assign({}, ...this.itemschanged);

      this.loading_sources = false;
      console.log(this.itemschanged);


    } catch (error) {
      console.log(error);
    }
  },



  methods: {


    async userSearch() {

      this.monks = [];

      console.log(this.unitName);

        const q = query(collection(db, "Units"), where("Name", "==", this.unitName), limit(50));

        const querySnapshot = await getDocs(q);

        this.searchActive = true;

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          this.monks.push( doc.data() );
        });

        console.log(this.monks);

    },

    

    buttonAdjuster() {
        this.redIsHidden = true;
        this.blueIsHidden = true;
        this.greenIsHidden = true;
        this.yellowIsHidden = true;
        this.whiteIsHidden = true;
        this.blackIsHidden = true;
        this.purpleIsHidden = true;
        this.brownIsHidden = true;
        this.champIsHidden = true;
    },

    bracketClick() {
        this.redIsHidden = false;
        this.blueIsHidden = false;
        this.greenIsHidden = false;
        this.yellowIsHidden = false;
        this.whiteIsHidden = false;
        this.blackIsHidden = false;
        this.purpleIsHidden = false;
        this.brownIsHidden = false;
        this.champIsHidden = false;
    },

    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo({ top: top, behavior: 'smooth' });
    },


   
    totalBrave: function (values) {
      return values.reduce((acc, val) => {
        return acc + parseInt(val.Brave);
      }, 0);    
    },

    totalFaith: function (values) {
      return values.reduce((acc, val) => {
        return acc + parseInt(val.Faith);
      }, 0);    
    },

    async boughtItem(id) {
      await axios.patch(`http://localhost:3000/items/${id}`, {
        bought: true,
      });
      this.items = this.items.map((item) => {
        if (item.id === id) {
          item.bought = true;
        }
        return item;
      });
    },

    async databaseDoStuff(items, tournamentid) {
      /*
      getDocs(collection(db, "Units")).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            console.log(`${doc.id} => ${doc.data()}`);
            this.firebaseData.push( doc.data() );
        });
      });
      

      addDoc(collection(db, "Units", ), {
        items
      });
      */

   





  const tquery = query(collection(db, "TournamentData"), where("tournamentid", "==", tournamentid));

const querySnapshot2 = await getDocs(tquery);

querySnapshot2.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  console.log(doc.id, " => ", doc.data());

  this.tournament_data_present = true;
  
});


if (!this.tournament_data_present) {

addDoc(collection(db, "TournamentData", ), {
      tournamentid
    });

items.forEach(unit => {

   addDoc(collection(db, "Units"), {
        ...unit
      } , { update: true });
    });

};
},
    


  
    
     


    //on double clicking the item, it will call removeItem(id) method
    removeItem(id) {
      axios.delete(`http://localhost:3000/items/${id}`);
      this.items = this.items.filter((item) => item.id !== id);
    },
    //method for adding items in the list
    async addItem() {
      const res = await axios.post(`http://localhost:3000/items`, {
        name: this.itemName,
      });
      this.items = [...this.items, res.data];
      this.itemName = "";
    },
  },
};
</script>



