{
    "abilities" : ["Cure 4", "Raise", "Raise 2", "Holy", "Dark Holy", "Fire 4", "Bolt 4", "Ice 4", "Death", "Flare", "Demi 2", "Meteor", "Petrify", "Moogle", "Carbunkle", "Bahamut", "Zodiac", "Cyclops", "Kiyomori", "Kikuichimonji", "Chirijiraden", "Masamune", "Last Song", "Wiznaibus", "Nameless Dance", "Earth Slash", "Revive", "Purification", "Steal Heart", "Invitation", "Death Sentence", "Mimic Daravon", "Water Ball", "Hallowed Ground", "Ultima", "Stasis Sword", "Justice Sword", "Surging Sword", "Explosion Sword", "Night Sword", "Phoenix Down", "X-Potion", "Remedy", "Dragon Spirit", "Auto Potion", "Blade Grasp", "Hamedo", "Dual Wield", "Short Charge", "Move+3", "Jump+3", "Teleport 2", "Equip Shield", "Equip Sword", "Equip Knife", "Equip Bow", "Equip Polearm", "Equip Axe", "Equip Gun", "Doublehand", "Galaxy Stop", "Elixir", "Attack UP", "Magic Attack UP", "Martial Arts", "Lucavi Skill", "Limit", "Arm Aim", "Gil Taking", "Magic Barrier"],
    "items" : ["Air Knife", "Zorlin Shape", "Iga Knife", "Koga Knife", "Nagrarock", "Materia Blade", "Blood Sword", "Rune Blade", "Save the Queen", "Excalibur", "Ragnarok", "Chaos Blade", "Masamune", "Chirijiraden", "Slasher", "Faith Rod", "Dragon Rod", "Healing Staff", "Mace of Zeus", "Scorpion Tail", "Stone Gun", "Blaze Gun", "Glacier Gun", "Blast Gun", "Gastrafitis", "Yoichi Bow", "Perseus Bow", "Bloody Strings", "Fairy Harp", "Madlemgen", "Holy Lance", "Gungnir", "Dragon Whisker", "Whale Whisker", "Star Bag", "Ryozan Silk", "Venetian Shield", "Hero Shield", "Genji Shield", "Kaiser Plate", "Genji Helmet", "Grand Helmet", "Cachusha", "Barette", "Ribbon", "Black Hood", "Twist Headband", "Flash Hat", "Genji Armor", "Reflect Mail", "Maximillian", "Wizard Outfit", "Power Sleeve", "Secret Clothes", "Rubber Costume", "Wizard Robe", "Robe of Lords", "Genji Gauntlet", "Magic Gauntlet", "Bracer", "Cursed Ring", "Angel Ring", "Setiemson", "Chantage", "Serpentarius", "Vanish Mantle", "Salty Rage"],
    "movementSkills" : {
        "Move+1" : {"move":1, "jump":0},
        "Move+2" : {"move":2, "jump":0},
        "Move+3" : {"move":3, "jump":0},
        "Jump+1" : {"move":0, "jump":1},
        "Jump+2" : {"move":0, "jump":2},
        "Jump+3" : {"move":0, "jump":3},
        "Ignore Height" : {"move":0, "jump":50},
        "Move-HP Up" : {"move":0, "jump":0},
        "Move-MP Up" : {"move":0, "jump":0},
        "Lifefont" : {"move":0, "jump":0},
        "Manafont" : {"move":0, "jump":0},
        "Teleport" : {"move":0, "jump":50},
        "Pilgrimage" : {"move":0, "jump":0},
        "Teleport 2" : {"move":50, "jump":50},
        "Ignore Terrain" : {"move":0, "jump":0},
        "Swim" : {"move":0, "jump":0},
        "Lava Walking" : {"move":0, "jump":0},
        "Waterbreathing" : {"move":0, "jump":0},
        "Levitate" : {"move":0, "jump":0},
        "Fly" : {"move":0, "jump":0},
        "Retreat" : {"move":0, "jump":0},
        "Landlocked" : {"move":0, "jump":0},
        "Waterwalking" : {"move":0, "jump":0},
        "Sonic Step" : {"move": 0, "jump": 0},
        "" : {"move":0, "jump": 0},
    }
}