[
    {
      "Class": "Dark Behemoth",
      "Gender": "Monster",
      "Rank": 1,
      "ChampRate": 10.71,
      "AverageStreak": 2.55,
      "NumChamps": 269,
      "NumRolls": 2512,
      "SumofStreaks": 687
    },
    {
      "Class": "Ultima Demon",
      "Gender": "Monster",
      "Rank": 2,
      "ChampRate": 10.36,
      "AverageStreak": 2.8,
      "NumChamps": 358,
      "NumRolls": 3455,
      "SumofStreaks": 1002
    },
    {
      "Class": "Tiamat",
      "Gender": "Monster",
      "Rank": 3,
      "ChampRate": 10.29,
      "AverageStreak": 2.67,
      "NumChamps": 264,
      "NumRolls": 2565,
      "SumofStreaks": 704
    },
    {
      "Class": "Holy Dragon",
      "Gender": "Monster",
      "Rank": 4,
      "ChampRate": 9.83,
      "AverageStreak": 2.61,
      "NumChamps": 301,
      "NumRolls": 3063,
      "SumofStreaks": 786
    },
    {
      "Class": "Steel Giant",
      "Gender": "Monster",
      "Rank": 5,
      "ChampRate": 9.69,
      "AverageStreak": 2.73,
      "NumChamps": 359,
      "NumRolls": 3706,
      "SumofStreaks": 981
    },
    {
      "Class": "Red Dragon",
      "Gender": "Monster",
      "Rank": 6,
      "ChampRate": 9.18,
      "AverageStreak": 2.69,
      "NumChamps": 189,
      "NumRolls": 2059,
      "SumofStreaks": 509
    },
    {
      "Class": "Red Chocobo",
      "Gender": "Monster",
      "Rank": 7,
      "ChampRate": 9.05,
      "AverageStreak": 2.62,
      "NumChamps": 310,
      "NumRolls": 3425,
      "SumofStreaks": 812
    },
    {
      "Class": "Archaic Demon",
      "Gender": "Monster",
      "Rank": 8,
      "ChampRate": 9.05,
      "AverageStreak": 2.59,
      "NumChamps": 237,
      "NumRolls": 2620,
      "SumofStreaks": 615
    },
    {
      "Class": "Blue Dragon",
      "Gender": "Monster",
      "Rank": 9,
      "ChampRate": 8.47,
      "AverageStreak": 2.61,
      "NumChamps": 155,
      "NumRolls": 1831,
      "SumofStreaks": 405
    },
    {
      "Class": "King Behemoth",
      "Gender": "Monster",
      "Rank": 10,
      "ChampRate": 7.93,
      "AverageStreak": 2.36,
      "NumChamps": 173,
      "NumRolls": 2182,
      "SumofStreaks": 408
    },
    {
      "Class": "Dragon",
      "Gender": "Monster",
      "Rank": 11,
      "ChampRate": 7.28,
      "AverageStreak": 2.56,
      "NumChamps": 140,
      "NumRolls": 1922,
      "SumofStreaks": 359
    },
    {
      "Class": "Serpentarius",
      "Gender": "Monster",
      "Rank": 12,
      "ChampRate": 7.06,
      "AverageStreak": 2.66,
      "NumChamps": 151,
      "NumRolls": 2140,
      "SumofStreaks": 401
    },
    {
      "Class": "Hydra",
      "Gender": "Monster",
      "Rank": 13,
      "ChampRate": 6.55,
      "AverageStreak": 2.6,
      "NumChamps": 120,
      "NumRolls": 1833,
      "SumofStreaks": 312
    },
    {
      "Class": "Summoner",
      "Gender": "Female",
      "Rank": 14,
      "ChampRate": 6.52,
      "AverageStreak": 2.62,
      "NumChamps": 1367,
      "NumRolls": 20963,
      "SumofStreaks": 3586
    },
    {
      "Class": "Monk",
      "Gender": "Male",
      "Rank": 15,
      "ChampRate": 6.51,
      "AverageStreak": 2.59,
      "NumChamps": 1770,
      "NumRolls": 27188,
      "SumofStreaks": 4580
    },
    {
      "Class": "Wyvern",
      "Gender": "Monster",
      "Rank": 16,
      "ChampRate": 6.38,
      "AverageStreak": 2.07,
      "NumChamps": 122,
      "NumRolls": 1913,
      "SumofStreaks": 252
    },
    {
      "Class": "Wizard",
      "Gender": "Female",
      "Rank": 17,
      "ChampRate": 6.3,
      "AverageStreak": 2.62,
      "NumChamps": 1645,
      "NumRolls": 26121,
      "SumofStreaks": 4317
    },
    {
      "Class": "Byblos",
      "Gender": "Monster",
      "Rank": 18,
      "ChampRate": 6.23,
      "AverageStreak": 2.35,
      "NumChamps": 124,
      "NumRolls": 1989,
      "SumofStreaks": 291
    },
    {
      "Class": "Lancer",
      "Gender": "Male",
      "Rank": 19,
      "ChampRate": 6.1,
      "AverageStreak": 2.67,
      "NumChamps": 1826,
      "NumRolls": 29914,
      "SumofStreaks": 4878
    },
    {
      "Class": "Archer",
      "Gender": "Male",
      "Rank": 20,
      "ChampRate": 6.08,
      "AverageStreak": 2.56,
      "NumChamps": 1421,
      "NumRolls": 23362,
      "SumofStreaks": 3637
    },
    {
      "Class": "Ninja",
      "Gender": "Male",
      "Rank": 21,
      "ChampRate": 6.05,
      "AverageStreak": 2.55,
      "NumChamps": 1779,
      "NumRolls": 29400,
      "SumofStreaks": 4539
    },
    {
      "Class": "Knight",
      "Gender": "Male",
      "Rank": 22,
      "ChampRate": 5.91,
      "AverageStreak": 2.55,
      "NumChamps": 2133,
      "NumRolls": 36108,
      "SumofStreaks": 5442
    },
    {
      "Class": "Geomancer",
      "Gender": "Male",
      "Rank": 23,
      "ChampRate": 5.87,
      "AverageStreak": 2.36,
      "NumChamps": 939,
      "NumRolls": 16007,
      "SumofStreaks": 2213
    },
    {
      "Class": "Geomancer",
      "Gender": "Female",
      "Rank": 24,
      "ChampRate": 5.84,
      "AverageStreak": 2.71,
      "NumChamps": 1108,
      "NumRolls": 18975,
      "SumofStreaks": 2999
    },
    {
      "Class": "Behemoth",
      "Gender": "Monster",
      "Rank": 25,
      "ChampRate": 5.69,
      "AverageStreak": 2.48,
      "NumChamps": 113,
      "NumRolls": 1986,
      "SumofStreaks": 280
    },
    {
      "Class": "Apanda",
      "Gender": "Monster",
      "Rank": 26,
      "ChampRate": 5.64,
      "AverageStreak": 2.31,
      "NumChamps": 124,
      "NumRolls": 2198,
      "SumofStreaks": 287
    },
    {
      "Class": "Ninja",
      "Gender": "Female",
      "Rank": 27,
      "ChampRate": 5.36,
      "AverageStreak": 2.46,
      "NumChamps": 877,
      "NumRolls": 16354,
      "SumofStreaks": 2157
    },
    {
      "Class": "Mime",
      "Gender": "Male",
      "Rank": 28,
      "ChampRate": 5.28,
      "AverageStreak": 2.56,
      "NumChamps": 1074,
      "NumRolls": 20338,
      "SumofStreaks": 2750
    },
    {
      "Class": "Cockatrice",
      "Gender": "Monster",
      "Rank": 29,
      "ChampRate": 5.25,
      "AverageStreak": 2.23,
      "NumChamps": 115,
      "NumRolls": 2190,
      "SumofStreaks": 256
    },
    {
      "Class": "Samurai",
      "Gender": "Female",
      "Rank": 30,
      "ChampRate": 5.24,
      "AverageStreak": 2.39,
      "NumChamps": 962,
      "NumRolls": 18355,
      "SumofStreaks": 2301
    },
    {
      "Class": "Priest",
      "Gender": "Female",
      "Rank": 31,
      "ChampRate": 5.21,
      "AverageStreak": 2.51,
      "NumChamps": 1141,
      "NumRolls": 21920,
      "SumofStreaks": 2868
    },
    {
      "Class": "Mediator",
      "Gender": "Male",
      "Rank": 32,
      "ChampRate": 5.04,
      "AverageStreak": 2.5,
      "NumChamps": 662,
      "NumRolls": 13124,
      "SumofStreaks": 1656
    },
    {
      "Class": "Time Mage",
      "Gender": "Female",
      "Rank": 33,
      "ChampRate": 5.04,
      "AverageStreak": 2.57,
      "NumChamps": 930,
      "NumRolls": 18450,
      "SumofStreaks": 2393
    },
    {
      "Class": "Sekhret",
      "Gender": "Monster",
      "Rank": 34,
      "ChampRate": 5,
      "AverageStreak": 2.34,
      "NumChamps": 90,
      "NumRolls": 1801,
      "SumofStreaks": 211
    },
    {
      "Class": "Minotaur",
      "Gender": "Monster",
      "Rank": 35,
      "ChampRate": 4.92,
      "AverageStreak": 1.96,
      "NumChamps": 82,
      "NumRolls": 1666,
      "SumofStreaks": 161
    },
    {
      "Class": "Chemist",
      "Gender": "Male",
      "Rank": 36,
      "ChampRate": 4.91,
      "AverageStreak": 2.45,
      "NumChamps": 706,
      "NumRolls": 14367,
      "SumofStreaks": 1728
    },
    {
      "Class": "Oracle",
      "Gender": "Female",
      "Rank": 37,
      "ChampRate": 4.9,
      "AverageStreak": 2.29,
      "NumChamps": 882,
      "NumRolls": 17990,
      "SumofStreaks": 2017
    },
    {
      "Class": "Bull Demon",
      "Gender": "Monster",
      "Rank": 38,
      "ChampRate": 4.78,
      "AverageStreak": 1.88,
      "NumChamps": 88,
      "NumRolls": 1840,
      "SumofStreaks": 165
    },
    {
      "Class": "Thief",
      "Gender": "Male",
      "Rank": 39,
      "ChampRate": 4.7,
      "AverageStreak": 2.29,
      "NumChamps": 863,
      "NumRolls": 18370,
      "SumofStreaks": 1974
    },
    {
      "Class": "Coeurl",
      "Gender": "Monster",
      "Rank": 40,
      "ChampRate": 4.66,
      "AverageStreak": 2.33,
      "NumChamps": 84,
      "NumRolls": 1804,
      "SumofStreaks": 196
    },
    {
      "Class": "Black Chocobo",
      "Gender": "Monster",
      "Rank": 41,
      "ChampRate": 4.62,
      "AverageStreak": 2.14,
      "NumChamps": 107,
      "NumRolls": 2318,
      "SumofStreaks": 229
    },
    {
      "Class": "Chemist",
      "Gender": "Female",
      "Rank": 42,
      "ChampRate": 4.57,
      "AverageStreak": 2.57,
      "NumChamps": 741,
      "NumRolls": 16222,
      "SumofStreaks": 1904
    },
    {
      "Class": "Archer",
      "Gender": "Female",
      "Rank": 43,
      "ChampRate": 4.56,
      "AverageStreak": 2.53,
      "NumChamps": 655,
      "NumRolls": 14365,
      "SumofStreaks": 1657
    },
    {
      "Class": "Samurai",
      "Gender": "Male",
      "Rank": 44,
      "ChampRate": 4.55,
      "AverageStreak": 2.45,
      "NumChamps": 719,
      "NumRolls": 15796,
      "SumofStreaks": 1761
    },
    {
      "Class": "Priest",
      "Gender": "Male",
      "Rank": 45,
      "ChampRate": 4.5,
      "AverageStreak": 2.63,
      "NumChamps": 484,
      "NumRolls": 10757,
      "SumofStreaks": 1275
    },
    {
      "Class": "Mediator",
      "Gender": "Female",
      "Rank": 46,
      "ChampRate": 4.49,
      "AverageStreak": 2.6,
      "NumChamps": 592,
      "NumRolls": 13198,
      "SumofStreaks": 1537
    },
    {
      "Class": "Thief",
      "Gender": "Female",
      "Rank": 47,
      "ChampRate": 4.48,
      "AverageStreak": 2.6,
      "NumChamps": 566,
      "NumRolls": 12627,
      "SumofStreaks": 1473
    },
    {
      "Class": "Monk",
      "Gender": "Female",
      "Rank": 48,
      "ChampRate": 4.34,
      "AverageStreak": 2.36,
      "NumChamps": 517,
      "NumRolls": 11924,
      "SumofStreaks": 1218
    },
    {
      "Class": "Lancer",
      "Gender": "Female",
      "Rank": 49,
      "ChampRate": 4.22,
      "AverageStreak": 2.31,
      "NumChamps": 475,
      "NumRolls": 11255,
      "SumofStreaks": 1096
    },
    {
      "Class": "Oracle",
      "Gender": "Male",
      "Rank": 50,
      "ChampRate": 4.19,
      "AverageStreak": 2.3,
      "NumChamps": 471,
      "NumRolls": 11237,
      "SumofStreaks": 1083
    },
    {
      "Class": "Knight",
      "Gender": "Female",
      "Rank": 51,
      "ChampRate": 4.14,
      "AverageStreak": 2.14,
      "NumChamps": 567,
      "NumRolls": 13694,
      "SumofStreaks": 1214
    },
    {
      "Class": "Bard",
      "Gender": "Male",
      "Rank": 52,
      "ChampRate": 4.14,
      "AverageStreak": 2.4,
      "NumChamps": 756,
      "NumRolls": 18266,
      "SumofStreaks": 1811
    },
    {
      "Class": "Vampire",
      "Gender": "Monster",
      "Rank": 53,
      "ChampRate": 4.07,
      "AverageStreak": 2.16,
      "NumChamps": 100,
      "NumRolls": 2459,
      "SumofStreaks": 216
    },
    {
      "Class": "Dancer",
      "Gender": "Female",
      "Rank": 54,
      "ChampRate": 4.03,
      "AverageStreak": 2.29,
      "NumChamps": 746,
      "NumRolls": 18498,
      "SumofStreaks": 1707
    },
    {
      "Class": "Mindflayer",
      "Gender": "Monster",
      "Rank": 55,
      "ChampRate": 4.01,
      "AverageStreak": 1.68,
      "NumChamps": 72,
      "NumRolls": 1796,
      "SumofStreaks": 121
    },
    {
      "Class": "Calculator",
      "Gender": "Female",
      "Rank": 56,
      "ChampRate": 4,
      "AverageStreak": 2.24,
      "NumChamps": 839,
      "NumRolls": 20966,
      "SumofStreaks": 1879
    },
    {
      "Class": "Wizard",
      "Gender": "Male",
      "Rank": 57,
      "ChampRate": 3.99,
      "AverageStreak": 2.41,
      "NumChamps": 452,
      "NumRolls": 11335,
      "SumofStreaks": 1089
    },
    {
      "Class": "Taiju",
      "Gender": "Monster",
      "Rank": 58,
      "ChampRate": 3.98,
      "AverageStreak": 1.86,
      "NumChamps": 69,
      "NumRolls": 1735,
      "SumofStreaks": 128
    },
    {
      "Class": "Squire",
      "Gender": "Male",
      "Rank": 59,
      "ChampRate": 3.85,
      "AverageStreak": 2.41,
      "NumChamps": 635,
      "NumRolls": 16474,
      "SumofStreaks": 1532
    },
    {
      "Class": "Reaper",
      "Gender": "Monster",
      "Rank": 60,
      "ChampRate": 3.85,
      "AverageStreak": 1.84,
      "NumChamps": 67,
      "NumRolls": 1739,
      "SumofStreaks": 123
    },
    {
      "Class": "Summoner",
      "Gender": "Male",
      "Rank": 61,
      "ChampRate": 3.84,
      "AverageStreak": 2.32,
      "NumChamps": 378,
      "NumRolls": 9833,
      "SumofStreaks": 876
    },
    {
      "Class": "Squire",
      "Gender": "Female",
      "Rank": 62,
      "ChampRate": 3.78,
      "AverageStreak": 2.24,
      "NumChamps": 449,
      "NumRolls": 11872,
      "SumofStreaks": 1008
    },
    {
      "Class": "Time Mage",
      "Gender": "Male",
      "Rank": 63,
      "ChampRate": 3.76,
      "AverageStreak": 2.34,
      "NumChamps": 372,
      "NumRolls": 9887,
      "SumofStreaks": 869
    },
    {
      "Class": "Calculator",
      "Gender": "Male",
      "Rank": 64,
      "ChampRate": 3.75,
      "AverageStreak": 2.34,
      "NumChamps": 553,
      "NumRolls": 14737,
      "SumofStreaks": 1294
    },
    {
      "Class": "Explosive",
      "Gender": "Monster",
      "Rank": 65,
      "ChampRate": 3.62,
      "AverageStreak": 2.45,
      "NumChamps": 74,
      "NumRolls": 2047,
      "SumofStreaks": 181
    },
    {
      "Class": "Plague",
      "Gender": "Monster",
      "Rank": 66,
      "ChampRate": 3.33,
      "AverageStreak": 2.03,
      "NumChamps": 62,
      "NumRolls": 1863,
      "SumofStreaks": 126
    },
    {
      "Class": "Draugr",
      "Gender": "Monster",
      "Rank": 67,
      "ChampRate": 3.32,
      "AverageStreak": 1.89,
      "NumChamps": 54,
      "NumRolls": 1625,
      "SumofStreaks": 102
    },
    {
      "Class": "Juravis",
      "Gender": "Monster",
      "Rank": 68,
      "ChampRate": 3.29,
      "AverageStreak": 2.53,
      "NumChamps": 55,
      "NumRolls": 1670,
      "SumofStreaks": 139
    },
    {
      "Class": "Wild Boar",
      "Gender": "Monster",
      "Rank": 69,
      "ChampRate": 3.1,
      "AverageStreak": 2.22,
      "NumChamps": 67,
      "NumRolls": 2160,
      "SumofStreaks": 149
    },
    {
      "Class": "Treant",
      "Gender": "Monster",
      "Rank": 70,
      "ChampRate": 3.04,
      "AverageStreak": 1.98,
      "NumChamps": 50,
      "NumRolls": 1643,
      "SumofStreaks": 99
    },
    {
      "Class": "Revenant",
      "Gender": "Monster",
      "Rank": 71,
      "ChampRate": 2.98,
      "AverageStreak": 2.35,
      "NumChamps": 51,
      "NumRolls": 1709,
      "SumofStreaks": 120
    },
    {
      "Class": "Skeleton",
      "Gender": "Monster",
      "Rank": 72,
      "ChampRate": 2.96,
      "AverageStreak": 1.98,
      "NumChamps": 51,
      "NumRolls": 1721,
      "SumofStreaks": 101
    },
    {
      "Class": "Red Panther",
      "Gender": "Monster",
      "Rank": 73,
      "ChampRate": 2.95,
      "AverageStreak": 1.84,
      "NumChamps": 44,
      "NumRolls": 1490,
      "SumofStreaks": 81
    },
    {
      "Class": "Grenade",
      "Gender": "Monster",
      "Rank": 74,
      "ChampRate": 2.94,
      "AverageStreak": 2.16,
      "NumChamps": 51,
      "NumRolls": 1737,
      "SumofStreaks": 110
    },
    {
      "Class": "Bomb",
      "Gender": "Monster",
      "Rank": 75,
      "ChampRate": 2.92,
      "AverageStreak": 1.92,
      "NumChamps": 50,
      "NumRolls": 1710,
      "SumofStreaks": 96
    },
    {
      "Class": "Mime",
      "Gender": "Female",
      "Rank": 76,
      "ChampRate": 2.9,
      "AverageStreak": 2.19,
      "NumChamps": 372,
      "NumRolls": 12834,
      "SumofStreaks": 816
    },
    {
      "Class": "Porky",
      "Gender": "Monster",
      "Rank": 77,
      "ChampRate": 2.87,
      "AverageStreak": 2.33,
      "NumChamps": 60,
      "NumRolls": 2090,
      "SumofStreaks": 140
    },
    {
      "Class": "Ghost",
      "Gender": "Monster",
      "Rank": 78,
      "ChampRate": 2.8,
      "AverageStreak": 1.95,
      "NumChamps": 44,
      "NumRolls": 1571,
      "SumofStreaks": 86
    },
    {
      "Class": "Ahriman",
      "Gender": "Monster",
      "Rank": 79,
      "ChampRate": 2.75,
      "AverageStreak": 1.94,
      "NumChamps": 47,
      "NumRolls": 1712,
      "SumofStreaks": 91
    },
    {
      "Class": "Iron Hawk",
      "Gender": "Monster",
      "Rank": 80,
      "ChampRate": 2.7,
      "AverageStreak": 1.95,
      "NumChamps": 44,
      "NumRolls": 1631,
      "SumofStreaks": 86
    },
    {
      "Class": "Gobbledeguck",
      "Gender": "Monster",
      "Rank": 81,
      "ChampRate": 2.69,
      "AverageStreak": 2,
      "NumChamps": 49,
      "NumRolls": 1820,
      "SumofStreaks": 98
    },
    {
      "Class": "Great Malboro",
      "Gender": "Monster",
      "Rank": 82,
      "ChampRate": 2.62,
      "AverageStreak": 1.49,
      "NumChamps": 57,
      "NumRolls": 2175,
      "SumofStreaks": 85
    },
    {
      "Class": "Chocobo",
      "Gender": "Monster",
      "Rank": 83,
      "ChampRate": 2.54,
      "AverageStreak": 2.39,
      "NumChamps": 61,
      "NumRolls": 2402,
      "SumofStreaks": 146
    },
    {
      "Class": "Black Goblin",
      "Gender": "Monster",
      "Rank": 84,
      "ChampRate": 2.41,
      "AverageStreak": 1.79,
      "NumChamps": 43,
      "NumRolls": 1782,
      "SumofStreaks": 77
    },
    {
      "Class": "Dryad",
      "Gender": "Monster",
      "Rank": 85,
      "ChampRate": 2.36,
      "AverageStreak": 2.41,
      "NumChamps": 41,
      "NumRolls": 1739,
      "SumofStreaks": 99
    },
    {
      "Class": "Swine",
      "Gender": "Monster",
      "Rank": 86,
      "ChampRate": 2.33,
      "AverageStreak": 2.17,
      "NumChamps": 46,
      "NumRolls": 1974,
      "SumofStreaks": 100
    },
    {
      "Class": "Ghoul",
      "Gender": "Monster",
      "Rank": 87,
      "ChampRate": 2.26,
      "AverageStreak": 2.24,
      "NumChamps": 34,
      "NumRolls": 1503,
      "SumofStreaks": 76
    },
    {
      "Class": "Ochu",
      "Gender": "Monster",
      "Rank": 88,
      "ChampRate": 2.14,
      "AverageStreak": 1.9,
      "NumChamps": 40,
      "NumRolls": 1867,
      "SumofStreaks": 76
    },
    {
      "Class": "Goblin",
      "Gender": "Monster",
      "Rank": 89,
      "ChampRate": 1.97,
      "AverageStreak": 2.15,
      "NumChamps": 39,
      "NumRolls": 1981,
      "SumofStreaks": 84
    },
    {
      "Class": "Squidraken",
      "Gender": "Monster",
      "Rank": 90,
      "ChampRate": 1.83,
      "AverageStreak": 2.19,
      "NumChamps": 37,
      "NumRolls": 2023,
      "SumofStreaks": 81
    },
    {
      "Class": "Pisco Demon",
      "Gender": "Monster",
      "Rank": 91,
      "ChampRate": 1.71,
      "AverageStreak": 1.97,
      "NumChamps": 35,
      "NumRolls": 2044,
      "SumofStreaks": 69
    },
    {
      "Class": "Malboro",
      "Gender": "Monster",
      "Rank": 92,
      "ChampRate": 1.63,
      "AverageStreak": 2.46,
      "NumChamps": 28,
      "NumRolls": 1714,
      "SumofStreaks": 69
    },
    {
      "Class": "Floating Eye",
      "Gender": "Monster",
      "Rank": 93,
      "ChampRate": 1.44,
      "AverageStreak": 1.7,
      "NumChamps": 27,
      "NumRolls": 1870,
      "SumofStreaks": 46
    }
   ]