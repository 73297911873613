{
   "Dark BehemothMonster": {
      "champRate": "10.71%",
      "avStreak": 2.55,
      "numChamps": 269,
      "numRolls": 2512,
      "Sum(Streaks)": 687,
      "Rank": 1
   },
   "Ultima DemonMonster": {
      "champRate": "10.36%",
      "avStreak": 2.8,
      "numChamps": 358,
      "numRolls": 3455,
      "Sum(Streaks)": 1002,
      "Rank": 2
   },
   "TiamatMonster": {
      "champRate": "10.29%",
      "avStreak": 2.67,
      "numChamps": 264,
      "numRolls": 2565,
      "Sum(Streaks)": 704,
      "Rank": 3
   },
   "Holy DragonMonster": {
      "champRate": "9.83%",
      "avStreak": 2.61,
      "numChamps": 301,
      "numRolls": 3063,
      "Sum(Streaks)": 786,
      "Rank": 4
   },
   "Steel GiantMonster": {
      "champRate": "9.69%",
      "avStreak": 2.73,
      "numChamps": 359,
      "numRolls": 3706,
      "Sum(Streaks)": 981,
      "Rank": 5
   },
   "Red DragonMonster": {
      "champRate": "9.18%",
      "avStreak": 2.69,
      "numChamps": 189,
      "numRolls": 2059,
      "Sum(Streaks)": 509,
      "Rank": 6
   },
   "Red ChocoboMonster": {
      "champRate": "9.05%",
      "avStreak": 2.62,
      "numChamps": 310,
      "numRolls": 3425,
      "Sum(Streaks)": 812,
      "Rank": 7
   },
   "Archaic DemonMonster": {
      "champRate": "9.05%",
      "avStreak": 2.59,
      "numChamps": 237,
      "numRolls": 2620,
      "Sum(Streaks)": 615,
      "Rank": 8
   },
   "Blue DragonMonster": {
      "champRate": "8.47%",
      "avStreak": 2.61,
      "numChamps": 155,
      "numRolls": 1831,
      "Sum(Streaks)": 405,
      "Rank": 9
   },
   "King BehemothMonster": {
      "champRate": "7.93%",
      "avStreak": 2.36,
      "numChamps": 173,
      "numRolls": 2182,
      "Sum(Streaks)": 408,
      "Rank": 10
   },
   "DragonMonster": {
      "champRate": "7.28%",
      "avStreak": 2.56,
      "numChamps": 140,
      "numRolls": 1922,
      "Sum(Streaks)": 359,
      "Rank": 11
   },
   "SerpentariusMonster": {
      "champRate": "7.06%",
      "avStreak": 2.66,
      "numChamps": 151,
      "numRolls": 2140,
      "Sum(Streaks)": 401,
      "Rank": 12
   },
   "HydraMonster": {
      "champRate": "6.55%",
      "avStreak": 2.6,
      "numChamps": 120,
      "numRolls": 1833,
      "Sum(Streaks)": 312,
      "Rank": 13
   },
   "SummonerFemale": {
      "champRate": "6.52%",
      "avStreak": 2.62,
      "numChamps": 1367,
      "numRolls": 20963,
      "Sum(Streaks)": 3586,
      "Rank": 14
   },
   "MonkMale": {
      "champRate": "6.51%",
      "avStreak": 2.59,
      "numChamps": 1770,
      "numRolls": 27188,
      "Sum(Streaks)": 4580,
      "Rank": 15
   },
   "WyvernMonster": {
      "champRate": "6.38%",
      "avStreak": 2.07,
      "numChamps": 122,
      "numRolls": 1913,
      "Sum(Streaks)": 252,
      "Rank": 16
   },
   "WizardFemale": {
      "champRate": "6.3%",
      "avStreak": 2.62,
      "numChamps": 1645,
      "numRolls": 26121,
      "Sum(Streaks)": 4317,
      "Rank": 17
   },
   "ByblosMonster": {
      "champRate": "6.23%",
      "avStreak": 2.35,
      "numChamps": 124,
      "numRolls": 1989,
      "Sum(Streaks)": 291,
      "Rank": 18
   },
   "LancerMale": {
      "champRate": "6.1%",
      "avStreak": 2.67,
      "numChamps": 1826,
      "numRolls": 29914,
      "Sum(Streaks)": 4878,
      "Rank": 19
   },
   "ArcherMale": {
      "champRate": "6.08%",
      "avStreak": 2.56,
      "numChamps": 1421,
      "numRolls": 23362,
      "Sum(Streaks)": 3637,
      "Rank": 20
   },
   "NinjaMale": {
      "champRate": "6.05%",
      "avStreak": 2.55,
      "numChamps": 1779,
      "numRolls": 29400,
      "Sum(Streaks)": 4539,
      "Rank": 21
   },
   "KnightMale": {
      "champRate": "5.91%",
      "avStreak": 2.55,
      "numChamps": 2133,
      "numRolls": 36108,
      "Sum(Streaks)": 5442,
      "Rank": 22
   },
   "GeomancerMale": {
      "champRate": "5.87%",
      "avStreak": 2.36,
      "numChamps": 939,
      "numRolls": 16007,
      "Sum(Streaks)": 2213,
      "Rank": 23
   },
   "GeomancerFemale": {
      "champRate": "5.84%",
      "avStreak": 2.71,
      "numChamps": 1108,
      "numRolls": 18975,
      "Sum(Streaks)": 2999,
      "Rank": 24
   },
   "BehemothMonster": {
      "champRate": "5.69%",
      "avStreak": 2.48,
      "numChamps": 113,
      "numRolls": 1986,
      "Sum(Streaks)": 280,
      "Rank": 25
   },
   "ApandaMonster": {
      "champRate": "5.64%",
      "avStreak": 2.31,
      "numChamps": 124,
      "numRolls": 2198,
      "Sum(Streaks)": 287,
      "Rank": 26
   },
   "NinjaFemale": {
      "champRate": "5.36%",
      "avStreak": 2.46,
      "numChamps": 877,
      "numRolls": 16354,
      "Sum(Streaks)": 2157,
      "Rank": 27
   },
   "MimeMale": {
      "champRate": "5.28%",
      "avStreak": 2.56,
      "numChamps": 1074,
      "numRolls": 20338,
      "Sum(Streaks)": 2750,
      "Rank": 28
   },
   "CockatriceMonster": {
      "champRate": "5.25%",
      "avStreak": 2.23,
      "numChamps": 115,
      "numRolls": 2190,
      "Sum(Streaks)": 256,
      "Rank": 29
   },
   "SamuraiFemale": {
      "champRate": "5.24%",
      "avStreak": 2.39,
      "numChamps": 962,
      "numRolls": 18355,
      "Sum(Streaks)": 2301,
      "Rank": 30
   },
   "PriestFemale": {
      "champRate": "5.21%",
      "avStreak": 2.51,
      "numChamps": 1141,
      "numRolls": 21920,
      "Sum(Streaks)": 2868,
      "Rank": 31
   },
   "MediatorMale": {
      "champRate": "5.04%",
      "avStreak": 2.5,
      "numChamps": 662,
      "numRolls": 13124,
      "Sum(Streaks)": 1656,
      "Rank": 32
   },
   "Time MageFemale": {
      "champRate": "5.04%",
      "avStreak": 2.57,
      "numChamps": 930,
      "numRolls": 18450,
      "Sum(Streaks)": 2393,
      "Rank": 33
   },
   "SekhretMonster": {
      "champRate": "5.0%",
      "avStreak": 2.34,
      "numChamps": 90,
      "numRolls": 1801,
      "Sum(Streaks)": 211,
      "Rank": 34
   },
   "MinotaurMonster": {
      "champRate": "4.92%",
      "avStreak": 1.96,
      "numChamps": 82,
      "numRolls": 1666,
      "Sum(Streaks)": 161,
      "Rank": 35
   },
   "ChemistMale": {
      "champRate": "4.91%",
      "avStreak": 2.45,
      "numChamps": 706,
      "numRolls": 14367,
      "Sum(Streaks)": 1728,
      "Rank": 36
   },
   "OracleFemale": {
      "champRate": "4.9%",
      "avStreak": 2.29,
      "numChamps": 882,
      "numRolls": 17990,
      "Sum(Streaks)": 2017,
      "Rank": 37
   },
   "Bull DemonMonster": {
      "champRate": "4.78%",
      "avStreak": 1.88,
      "numChamps": 88,
      "numRolls": 1840,
      "Sum(Streaks)": 165,
      "Rank": 38
   },
   "ThiefMale": {
      "champRate": "4.7%",
      "avStreak": 2.29,
      "numChamps": 863,
      "numRolls": 18370,
      "Sum(Streaks)": 1974,
      "Rank": 39
   },
   "CoeurlMonster": {
      "champRate": "4.66%",
      "avStreak": 2.33,
      "numChamps": 84,
      "numRolls": 1804,
      "Sum(Streaks)": 196,
      "Rank": 40
   },
   "Black ChocoboMonster": {
      "champRate": "4.62%",
      "avStreak": 2.14,
      "numChamps": 107,
      "numRolls": 2318,
      "Sum(Streaks)": 229,
      "Rank": 41
   },
   "ChemistFemale": {
      "champRate": "4.57%",
      "avStreak": 2.57,
      "numChamps": 741,
      "numRolls": 16222,
      "Sum(Streaks)": 1904,
      "Rank": 42
   },
   "ArcherFemale": {
      "champRate": "4.56%",
      "avStreak": 2.53,
      "numChamps": 655,
      "numRolls": 14365,
      "Sum(Streaks)": 1657,
      "Rank": 43
   },
   "SamuraiMale": {
      "champRate": "4.55%",
      "avStreak": 2.45,
      "numChamps": 719,
      "numRolls": 15796,
      "Sum(Streaks)": 1761,
      "Rank": 44
   },
   "PriestMale": {
      "champRate": "4.5%",
      "avStreak": 2.63,
      "numChamps": 484,
      "numRolls": 10757,
      "Sum(Streaks)": 1275,
      "Rank": 45
   },
   "MediatorFemale": {
      "champRate": "4.49%",
      "avStreak": 2.6,
      "numChamps": 592,
      "numRolls": 13198,
      "Sum(Streaks)": 1537,
      "Rank": 46
   },
   "ThiefFemale": {
      "champRate": "4.48%",
      "avStreak": 2.6,
      "numChamps": 566,
      "numRolls": 12627,
      "Sum(Streaks)": 1473,
      "Rank": 47
   },
   "MonkFemale": {
      "champRate": "4.34%",
      "avStreak": 2.36,
      "numChamps": 517,
      "numRolls": 11924,
      "Sum(Streaks)": 1218,
      "Rank": 48
   },
   "LancerFemale": {
      "champRate": "4.22%",
      "avStreak": 2.31,
      "numChamps": 475,
      "numRolls": 11255,
      "Sum(Streaks)": 1096,
      "Rank": 49
   },
   "OracleMale": {
      "champRate": "4.19%",
      "avStreak": 2.3,
      "numChamps": 471,
      "numRolls": 11237,
      "Sum(Streaks)": 1083,
      "Rank": 50
   },
   "KnightFemale": {
      "champRate": "4.14%",
      "avStreak": 2.14,
      "numChamps": 567,
      "numRolls": 13694,
      "Sum(Streaks)": 1214,
      "Rank": 51
   },
   "BardMale": {
      "champRate": "4.14%",
      "avStreak": 2.4,
      "numChamps": 756,
      "numRolls": 18266,
      "Sum(Streaks)": 1811,
      "Rank": 52
   },
   "VampireMonster": {
      "champRate": "4.07%",
      "avStreak": 2.16,
      "numChamps": 100,
      "numRolls": 2459,
      "Sum(Streaks)": 216,
      "Rank": 53
   },
   "DancerFemale": {
      "champRate": "4.03%",
      "avStreak": 2.29,
      "numChamps": 746,
      "numRolls": 18498,
      "Sum(Streaks)": 1707,
      "Rank": 54
   },
   "MindflayerMonster": {
      "champRate": "4.01%",
      "avStreak": 1.68,
      "numChamps": 72,
      "numRolls": 1796,
      "Sum(Streaks)": 121,
      "Rank": 55
   },
   "CalculatorFemale": {
      "champRate": "4.0%",
      "avStreak": 2.24,
      "numChamps": 839,
      "numRolls": 20966,
      "Sum(Streaks)": 1879,
      "Rank": 56
   },
   "WizardMale": {
      "champRate": "3.99%",
      "avStreak": 2.41,
      "numChamps": 452,
      "numRolls": 11335,
      "Sum(Streaks)": 1089,
      "Rank": 57
   },
   "TaijuMonster": {
      "champRate": "3.98%",
      "avStreak": 1.86,
      "numChamps": 69,
      "numRolls": 1735,
      "Sum(Streaks)": 128,
      "Rank": 58
   },
   "SquireMale": {
      "champRate": "3.85%",
      "avStreak": 2.41,
      "numChamps": 635,
      "numRolls": 16474,
      "Sum(Streaks)": 1532,
      "Rank": 59
   },
   "ReaperMonster": {
      "champRate": "3.85%",
      "avStreak": 1.84,
      "numChamps": 67,
      "numRolls": 1739,
      "Sum(Streaks)": 123,
      "Rank": 60
   },
   "SummonerMale": {
      "champRate": "3.84%",
      "avStreak": 2.32,
      "numChamps": 378,
      "numRolls": 9833,
      "Sum(Streaks)": 876,
      "Rank": 61
   },
   "SquireFemale": {
      "champRate": "3.78%",
      "avStreak": 2.24,
      "numChamps": 449,
      "numRolls": 11872,
      "Sum(Streaks)": 1008,
      "Rank": 62
   },
   "Time MageMale": {
      "champRate": "3.76%",
      "avStreak": 2.34,
      "numChamps": 372,
      "numRolls": 9887,
      "Sum(Streaks)": 869,
      "Rank": 63
   },
   "CalculatorMale": {
      "champRate": "3.75%",
      "avStreak": 2.34,
      "numChamps": 553,
      "numRolls": 14737,
      "Sum(Streaks)": 1294,
      "Rank": 64
   },
   "ExplosiveMonster": {
      "champRate": "3.62%",
      "avStreak": 2.45,
      "numChamps": 74,
      "numRolls": 2047,
      "Sum(Streaks)": 181,
      "Rank": 65
   },
   "PlagueMonster": {
      "champRate": "3.33%",
      "avStreak": 2.03,
      "numChamps": 62,
      "numRolls": 1863,
      "Sum(Streaks)": 126,
      "Rank": 66
   },
   "DraugrMonster": {
      "champRate": "3.32%",
      "avStreak": 1.89,
      "numChamps": 54,
      "numRolls": 1625,
      "Sum(Streaks)": 102,
      "Rank": 67
   },
   "JuravisMonster": {
      "champRate": "3.29%",
      "avStreak": 2.53,
      "numChamps": 55,
      "numRolls": 1670,
      "Sum(Streaks)": 139,
      "Rank": 68
   },
   "Wild BoarMonster": {
      "champRate": "3.1%",
      "avStreak": 2.22,
      "numChamps": 67,
      "numRolls": 2160,
      "Sum(Streaks)": 149,
      "Rank": 69
   },
   "TreantMonster": {
      "champRate": "3.04%",
      "avStreak": 1.98,
      "numChamps": 50,
      "numRolls": 1643,
      "Sum(Streaks)": 99,
      "Rank": 70
   },
   "RevenantMonster": {
      "champRate": "2.98%",
      "avStreak": 2.35,
      "numChamps": 51,
      "numRolls": 1709,
      "Sum(Streaks)": 120,
      "Rank": 71
   },
   "SkeletonMonster": {
      "champRate": "2.96%",
      "avStreak": 1.98,
      "numChamps": 51,
      "numRolls": 1721,
      "Sum(Streaks)": 101,
      "Rank": 72
   },
   "Red PantherMonster": {
      "champRate": "2.95%",
      "avStreak": 1.84,
      "numChamps": 44,
      "numRolls": 1490,
      "Sum(Streaks)": 81,
      "Rank": 73
   },
   "GrenadeMonster": {
      "champRate": "2.94%",
      "avStreak": 2.16,
      "numChamps": 51,
      "numRolls": 1737,
      "Sum(Streaks)": 110,
      "Rank": 74
   },
   "BombMonster": {
      "champRate": "2.92%",
      "avStreak": 1.92,
      "numChamps": 50,
      "numRolls": 1710,
      "Sum(Streaks)": 96,
      "Rank": 75
   },
   "MimeFemale": {
      "champRate": "2.9%",
      "avStreak": 2.19,
      "numChamps": 372,
      "numRolls": 12834,
      "Sum(Streaks)": 816,
      "Rank": 76
   },
   "PorkyMonster": {
      "champRate": "2.87%",
      "avStreak": 2.33,
      "numChamps": 60,
      "numRolls": 2090,
      "Sum(Streaks)": 140,
      "Rank": 77
   },
   "GhostMonster": {
      "champRate": "2.8%",
      "avStreak": 1.95,
      "numChamps": 44,
      "numRolls": 1571,
      "Sum(Streaks)": 86,
      "Rank": 78
   },
   "AhrimanMonster": {
      "champRate": "2.75%",
      "avStreak": 1.94,
      "numChamps": 47,
      "numRolls": 1712,
      "Sum(Streaks)": 91,
      "Rank": 79
   },
   "Iron HawkMonster": {
      "champRate": "2.7%",
      "avStreak": 1.95,
      "numChamps": 44,
      "numRolls": 1631,
      "Sum(Streaks)": 86,
      "Rank": 80
   },
   "GobbledeguckMonster": {
      "champRate": "2.69%",
      "avStreak": 2,
      "numChamps": 49,
      "numRolls": 1820,
      "Sum(Streaks)": 98,
      "Rank": 81
   },
   "Great MalboroMonster": {
      "champRate": "2.62%",
      "avStreak": 1.49,
      "numChamps": 57,
      "numRolls": 2175,
      "Sum(Streaks)": 85,
      "Rank": 82
   },
   "ChocoboMonster": {
      "champRate": "2.54%",
      "avStreak": 2.39,
      "numChamps": 61,
      "numRolls": 2402,
      "Sum(Streaks)": 146,
      "Rank": 83
   },
   "Black GoblinMonster": {
      "champRate": "2.41%",
      "avStreak": 1.79,
      "numChamps": 43,
      "numRolls": 1782,
      "Sum(Streaks)": 77,
      "Rank": 84
   },
   "DryadMonster": {
      "champRate": "2.36%",
      "avStreak": 2.41,
      "numChamps": 41,
      "numRolls": 1739,
      "Sum(Streaks)": 99,
      "Rank": 85
   },
   "SwineMonster": {
      "champRate": "2.33%",
      "avStreak": 2.17,
      "numChamps": 46,
      "numRolls": 1974,
      "Sum(Streaks)": 100,
      "Rank": 86
   },
   "GhoulMonster": {
      "champRate": "2.26%",
      "avStreak": 2.24,
      "numChamps": 34,
      "numRolls": 1503,
      "Sum(Streaks)": 76,
      "Rank": 87
   },
   "OchuMonster": {
      "champRate": "2.14%",
      "avStreak": 1.9,
      "numChamps": 40,
      "numRolls": 1867,
      "Sum(Streaks)": 76,
      "Rank": 88
   },
   "GoblinMonster": {
      "champRate": "1.97%",
      "avStreak": 2.15,
      "numChamps": 39,
      "numRolls": 1981,
      "Sum(Streaks)": 84,
      "Rank": 89
   },
   "SquidrakenMonster": {
      "champRate": "1.83%",
      "avStreak": 2.19,
      "numChamps": 37,
      "numRolls": 2023,
      "Sum(Streaks)": 81,
      "Rank": 90
   },
   "Pisco DemonMonster": {
      "champRate": "1.71%",
      "avStreak": 1.97,
      "numChamps": 35,
      "numRolls": 2044,
      "Sum(Streaks)": 69,
      "Rank": 91
   },
   "MalboroMonster": {
      "champRate": "1.63%",
      "avStreak": 2.46,
      "numChamps": 28,
      "numRolls": 1714,
      "Sum(Streaks)": 69,
      "Rank": 92
   },
   "Floating EyeMonster": {
      "champRate": "1.44%",
      "avStreak": 1.7,
      "numChamps": 27,
      "numRolls": 1870,
      "Sum(Streaks)": 46,
      "Rank": 93
   }
}