<template>
    <div class="unit">
    <div class="image-stats">
    <div class="image-holder">





      
    <template v-if="members.Gender == 'Monster'">


        <template v-if="allunits">
        <div class="teamStars">
      <div v-for="teammates of allunits[teamColor.toLowerCase()].Units">

          <span v-if="compatibilityFormula(members.Gender, members.Sign, teammates.Gender, teammates.Sign)">

        </span>
        <img class="compat" :src="IMG_URL_COMP + compatNum + '.png'"  :alt='compatNum'/>

          
      </div>
      </div>


        <img   :src="IMG_URL + members.Class + '.gif'" />

        <div v-if="enemyColor" class="enemyStars">

          <div v-for="teammates of allunits[enemyColor.toLowerCase()].Units">

            <span v-if="compatibilityFormula(members.Gender, members.Sign, teammates.Gender, teammates.Sign)">
            </span>
            <img class="compat" :src="IMG_URL_COMP + compatNum + '.png'"  :alt='compatNum'/>
          
          </div>
        </div>
      </template>
    
    </template>
    <template v-else>


<template v-if="allunits">
      <div class="teamStars">
      <div v-for="teammates of allunits[teamColor.toLowerCase()].Units">
        

          <span v-if="compatibilityFormula(members.Gender, members.Sign, teammates.Gender, teammates.Sign)">
          </span>

          <img class="compat" :src="IMG_URL_COMP + compatNum + '.png'"  :alt='compatNum'/>
          
        

          
      </div>
      </div>

      <img v-if="teamColor != 'Champion'" :src="IMG_URL + members.Class + members.Gender[0] + '_' + teamColor + '.gif'" />
      <img v-else :src="IMG_URL + members.Class + members.Gender[0] + '.gif'" />

        <div v-if="enemyColor" class="enemyStars">
      <div v-for="teammates of allunits[enemyColor.toLowerCase()].Units">

          <span v-if="compatibilityFormula(members.Gender, members.Sign, teammates.Gender, teammates.Sign)">
          </span>

          <img class="compat" :src="IMG_URL_COMP + compatNum + '.png'"  :alt='compatNum'/>

          
          
      </div>
      </div>
      </template>


    
    </template>
    </div>

    <div class="name-and-stats">
    

      <div><b><template v-if="members.RaidBoss">[!] </template>{{ members.Name }}</b></div>
      <div>{{members.Sign}}</div>
      

      <div>{{ members.Class }} 
          <span v-if="members.Gender == 'Female'" > ({{JobStats[members.Class.replace(/\s+/g, '')+'Female'].rank}})</span> 
          <span v-if="members.Gender == 'Male'" > ({{JobStats[members.Class.replace(/\s+/g, '')+'Male'].rank}})</span> 
          <span v-if="members.Gender == 'Monster'" > ({{JobStats[members.Class.replace(/\s+/g, '')].rank}})</span> 
        </div> 

        <div>
              <span>Champ Rate: {{classRank[members.Class+members.Gender].champRate}} ({{classRank[members.Class+members.Gender].Rank}})
              </span> 
            </div>

      <div class="stats">
        <div class="stat">
          <span :class="{ goodBonus: ((itemschanged[members.Head].hp + itemschanged[members.Armor].hp + itemschanged[members.Mainhand].hp + itemschanged[members.Offhand].hp + itemschanged[members.Accessory].hp) > 100) }" > HP: {{ charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].hp + itemschanged[members.Head].hp + itemschanged[members.Armor].hp + itemschanged[members.Mainhand].hp + itemschanged[members.Offhand].hp + itemschanged[members.Accessory].hp}}
          </span>
          <span> - </span>
          
        <span @click="e => e.target.classList.toggle('active')" :class="{ goodBonus: ((itemschanged[members.Head].mp + itemschanged[members.Armor].mp + itemschanged[members.Mainhand].mp + itemschanged[members.Offhand].mp + itemschanged[members.Accessory].mp) > 40),  hasToolTip: ((itemschanged[members.Head].mp + itemschanged[members.Armor].mp + itemschanged[members.Mainhand].mp + itemschanged[members.Offhand].mp + itemschanged[members.Accessory].mp) > 0) }" >MP: {{ charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].mp + itemschanged[members.Head].mp + itemschanged[members.Armor].mp + itemschanged[members.Mainhand].mp + itemschanged[members.Offhand].mp + itemschanged[members.Accessory].mp}}


      </span>

        </div>
        <div class="stat">

          <span @click="e => e.target.classList.toggle('active')" :class="{ goodBonus: ((itemschanged[members.Head].move + itemschanged[members.Armor].move + itemschanged[members.Mainhand].move + itemschanged[members.Offhand].move + itemschanged[members.Accessory].move + notAbles.movementSkills[members.MoveSkill].move) > 2),  hasToolTip: ((itemschanged[members.Head].move + itemschanged[members.Armor].move + itemschanged[members.Mainhand].move + itemschanged[members.Offhand].move + itemschanged[members.Accessory].move + notAbles.movementSkills[members.MoveSkill].move) > 0) }" > Move: {{ charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].move + itemschanged[members.Head].move + itemschanged[members.Armor].move + itemschanged[members.Mainhand].move + itemschanged[members.Offhand].move + itemschanged[members.Accessory].move + notAbles.movementSkills[members.MoveSkill].move}}
              
          </span>
          <span> - </span>

          <template v-if="calcSpeed = charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].speed + itemschanged[members.Head].speed + itemschanged[members.Armor].speed + itemschanged[members.Mainhand].speed + itemschanged[members.Offhand].speed + itemschanged[members.Accessory].speed"></template>
          <span @click="e => e.target.classList.toggle('active')" :class="{ goodBonus: ((itemschanged[members.Head].speed + itemschanged[members.Armor].speed + itemschanged[members.Mainhand].speed + itemschanged[members.Offhand].speed + itemschanged[members.Accessory].speed) > 2),  hasToolTip: ((itemschanged[members.Head].speed + itemschanged[members.Armor].speed + itemschanged[members.Mainhand].speed + itemschanged[members.Offhand].speed + itemschanged[members.Accessory].speed) > 0) }" >Speed: {{ charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].speed + itemschanged[members.Head].speed + itemschanged[members.Armor].speed + itemschanged[members.Mainhand].speed + itemschanged[members.Offhand].speed + itemschanged[members.Accessory].speed}}
            
          </span>
          <span> - </span>

          <span @click="e => e.target.classList.toggle('active')" :class="{ goodBonus: ((itemschanged[members.Head].jump + itemschanged[members.Armor].jump + itemschanged[members.Mainhand].jump + itemschanged[members.Offhand].jump + itemschanged[members.Accessory].jump + notAbles.movementSkills[members.MoveSkill].jump) > 2),  hasToolTip: ((itemschanged[members.Head].jump + itemschanged[members.Armor].jump + itemschanged[members.Mainhand].jump + itemschanged[members.Offhand].jump + itemschanged[members.Accessory].jump + notAbles.movementSkills[members.MoveSkill].jump) > 0) }" >Jump: {{ charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].jump + itemschanged[members.Head].jump + itemschanged[members.Armor].jump + itemschanged[members.Mainhand].jump + itemschanged[members.Offhand].jump + itemschanged[members.Accessory].jump + notAbles.movementSkills[members.MoveSkill].jump}}
              
          </span>

        </div>
        <div class="stat">
          <template v-if="calcPA = charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].pa + itemschanged[members.Head].pa + itemschanged[members.Armor].pa + itemschanged[members.Mainhand].pa + itemschanged[members.Offhand].pa + itemschanged[members.Accessory].pa"></template>
          <template v-if="calcPAU = charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].pa + itemschanged[members.Head].pa + itemschanged[members.Armor].pa + itemschanged[members.Accessory].pa"></template>
          <span @click="e => e.target.classList.toggle('active')" :class="{  goodBonus: ((itemschanged[members.Head].pa + itemschanged[members.Armor].pa + itemschanged[members.Mainhand].pa + itemschanged[members.Offhand].pa + itemschanged[members.Accessory].pa) > 2), hasToolTip: ((itemschanged[members.Head].pa + itemschanged[members.Armor].pa + itemschanged[members.Mainhand].pa + itemschanged[members.Offhand].pa + itemschanged[members.Accessory].pa) > 0) }" >PA: {{ charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].pa + itemschanged[members.Head].pa + itemschanged[members.Armor].pa + itemschanged[members.Mainhand].pa + itemschanged[members.Offhand].pa + itemschanged[members.Accessory].pa}}
              
          </span>
          <span> - </span>

          <template v-if="calcMA = charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].ma + itemschanged[members.Head].ma + itemschanged[members.Armor].ma + itemschanged[members.Mainhand].ma + itemschanged[members.Offhand].ma + itemschanged[members.Accessory].ma"></template>

          <span @click="e => e.target.classList.toggle('active')" :class="{  goodBonus: ((itemschanged[members.Head].ma + itemschanged[members.Armor].ma + itemschanged[members.Mainhand].ma + itemschanged[members.Offhand].ma + itemschanged[members.Accessory].ma) > 2), hasToolTip: ((itemschanged[members.Head].ma + itemschanged[members.Armor].ma + itemschanged[members.Mainhand].ma + itemschanged[members.Offhand].ma + itemschanged[members.Accessory].ma) > 0) }" >MA: {{ charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].ma + itemschanged[members.Head].ma + itemschanged[members.Armor].ma + itemschanged[members.Mainhand].ma + itemschanged[members.Offhand].ma + itemschanged[members.Accessory].ma}}
            
        </span>


        </div>
        <div class="stat">
          <span :class="{ bold: (members.Brave > 70 || members.Brave < 45) }">Brave: {{members.Brave}}</span>
          - 
          <span :class="{ bold: (members.Faith > 70 || members.Faith < 45) }">Faith: {{members.Faith}}</span>
        </div>
      </div>
    </div>
    </div>
    <div v-if="members.Gender != 'Monster'" class="unit-equipment">
      <div class="equipment unarmed" :class="{ showUnarmed: showExtra, monster: members.Gender == 'Monster',  hasWeapon: members.Mainhand, hasShield: itemschanged[members.Offhand].type == 'Shield' }">
        <img class="ability-icon" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/hand.png" alt="hand">
        <img class="gear-icon flip" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/hand.png" alt="hand">
        <span class="equipment-text" @click="e => e.target.classList.toggle('active')">Unarmed

              <span class="damage-number">
              <template v-if="members.Gender == 'Monster'">
                ({{parseInt( charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].pa * parseInt((charStats[members.Class.replace(/\s+/g, '')+[members.Gender]].pa * members.Brave ) / 100) ) }})
              </template>
              <template v-else>
                <template v-if="members.SupportSkill == 'Martial Arts' || members.Class == 'Monk'">
                    ({{parseInt( 1.5 * (calcPAU * parseInt((calcPAU * members.Brave ) / 100) )) }})
                </template>
                <template v-else>
                    ({{parseInt( calcPAU * parseInt((calcPAU * members.Brave ) / 100) ) }})
                </template>
              </template>
                </span>
            </span>
      </div>

      <div v-if="members.Mainhand" class="equipment mainhand">
        <img @click="showExtra = !showExtra" class="ability-icon cursor-pointer" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/hand.png" alt="hand">
        <img class="gear-icon"  :src="IMG_URL_ITEMS + members.Mainhand.replace(/\s+/g, '') + '.gif'"  :alt='members.Mainhand'>
        <span class="equipment-text" :class="{ bold: (notAbles.items.includes(members.Mainhand)),  showUnarmed: showExtra }"  @click="e => e.target.classList.toggle('active')">{{ members.Mainhand }}

              <span class="damage-number">
              <template v-if="itemschanged[members.Mainhand].type == 'Rod' || itemschanged[members.Mainhand].type == 'Crossbow' || itemschanged[members.Mainhand].type == 'Spear' || itemschanged[members.Mainhand].type == 'Sword' ">
                <template v-if="members.SupportSkill == 'Doublehand'">
                  ({{(calcPA * itemschanged[members.Mainhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{calcPA * itemschanged[members.Mainhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[members.Mainhand].type == 'Pole' || itemschanged[members.Mainhand].type == 'Staff'">
                <template v-if="members.SupportSkill == 'Doublehand'">
                  ({{(calcMA * itemschanged[members.Mainhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{calcMA * itemschanged[members.Mainhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[members.Mainhand].type == 'Knight Sword' || itemschanged[members.Mainhand].type == 'Katana' ">
                  <template v-if="members.SupportSkill == 'Doublehand'">
                    ({{(parseInt(parseInt(calcPA * (members.Brave / 100)) * itemschanged[members.Mainhand].wp)) * 2}})
                  </template>
                  <template v-else>
                    ({{parseInt(parseInt(calcPA * (members.Brave / 100)) * itemschanged[members.Mainhand].wp)}})
                  </template>

              </template>

              <template v-if="itemschanged[members.Mainhand].type == 'Harp' || itemschanged[members.Mainhand].type == 'Book' || itemschanged[members.Mainhand].type == 'Fabric'">
                    <template v-if="members.SupportSkill == 'Doublehand'">
                      ({{ (Math.floor((calcPA + calcMA) /2) * itemschanged[members.Mainhand].wp) * 2}})
                    </template>
                    <template v-else>
                      ({{ Math.floor((calcPA + calcMA) /2) * itemschanged[members.Mainhand].wp}})
                    </template>
              </template>

              <template v-if="itemschanged[members.Mainhand].type == 'Axe' || itemschanged[members.Mainhand].type == 'Bag' || itemschanged[members.Mainhand].type == 'Flail'">
                <template v-if="members.SupportSkill == 'Doublehand'">
                  {{(1 * itemschanged[members.Mainhand].wp) * 2}} - {{(calcPA * itemschanged[members.Mainhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{1 * itemschanged[members.Mainhand].wp}} - {{calcPA * itemschanged[members.Mainhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[members.Mainhand].type == 'Ninja Blade' || itemschanged[members.Mainhand].type == 'Knife' || itemschanged[members.Mainhand].type == 'Bow'">
                <template v-if="members.SupportSkill == 'Doublehand'">
                  ({{ (Math.floor((calcPA + calcSpeed)/2) * itemschanged[members.Mainhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{ Math.floor((calcPA + calcSpeed)/2) * itemschanged[members.Mainhand].wp}})
                </template>
              </template>


              <template v-if="itemschanged[members.Mainhand].type == 'Gun' && itemschanged[members.Mainhand].element ">
                <div class="aventail">
                  70% ({{ Math.floor((members.Faith * 60 * 16 * itemschanged[members.Mainhand].wp) / 10000)}}) -
                  20% ({{ Math.floor((members.Faith * 60 * 20 * itemschanged[members.Mainhand].wp) / 10000)}}) -
                  10% ({{ Math.floor((members.Faith * 60 * 28 * itemschanged[members.Mainhand].wp) / 10000)}})
                </div>
              </template>

              <template v-if="itemschanged[members.Mainhand].type == 'Gun' && !itemschanged[members.Mainhand].element ">
                ({{itemschanged[members.Mainhand].wp * itemschanged[members.Mainhand].wp}})
              </template>
                </span>
            </span>
      </div>
      <div v-if="members.Offhand" class="equipment offhand">
        <img class="ability-icon" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/hand.png" alt="hand">
        <img class="gear-icon" :src="IMG_URL_ITEMS + members.Offhand.replace(/\s+/g, '') + '.gif'" :alt='members.Offhand'>
        <span class="equipment-text" :class="{ bold: (notAbles.items.includes(members.Offhand)) }"  @click="e => e.target.classList.toggle('active')">{{ members.Offhand }}

            <span class="damage-number">
              <template v-if="itemschanged[members.Offhand].type == 'Rod' || itemschanged[members.Offhand].type == 'Crossbow' || itemschanged[members.Offhand].type == 'Spear' || itemschanged[members.Offhand].type == 'Sword' ">
                <template v-if="members.SupportSkill == 'Doublehand'">
                  ({{(calcPA * itemschanged[members.Offhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{calcPA * itemschanged[members.Offhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[members.Offhand].type == 'Pole' || itemschanged[members.Offhand].type == 'Staff'">
                <template v-if="members.SupportSkill == 'Doublehand'">
                  ({{(calcMA * itemschanged[members.Offhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{calcMA * itemschanged[members.Offhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[members.Offhand].type == 'Knight Sword' || itemschanged[members.Offhand].type == 'Katana' ">
                  <template v-if="members.SupportSkill == 'Doublehand'">
                    ({{(parseInt(parseInt(calcPA * (members.Brave / 100)) * itemschanged[members.Offhand].wp)) * 2}})
                  </template>
                  <template v-else>
                    ({{parseInt(parseInt(calcPA * (members.Brave / 100)) * itemschanged[members.Offhand].wp)}})
                  </template>

              </template>

              <template v-if="itemschanged[members.Offhand].type == 'Harp' || itemschanged[members.Offhand].type == 'Book' || itemschanged[members.Offhand].type == 'Fabric'">
                    <template v-if="members.SupportSkill == 'Doublehand'">
                      ({{ (Math.floor((calcPA + calcMA) /2) * itemschanged[members.Offhand].wp) * 2}})
                    </template>
                    <template v-else>
                      ({{ Math.floor((calcPA + calcMA) /2) * itemschanged[members.Offhand].wp}})
                    </template>
              </template>

              <template v-if="itemschanged[members.Offhand].type == 'Axe' || itemschanged[members.Offhand].type == 'Bag' || itemschanged[members.Offhand].type == 'Flail'">
                <template v-if="members.SupportSkill == 'Doublehand'">
                  {{(1 * itemschanged[members.Offhand].wp) * 2}} - {{(calcPA * itemschanged[members.Offhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{1 * itemschanged[members.Offhand].wp}} - {{calcPA * itemschanged[members.Offhand].wp}})
                </template>
              </template>

              <template v-if="itemschanged[members.Offhand].type == 'Ninja Blade' || itemschanged[members.Offhand].type == 'Knife' || itemschanged[members.Offhand].type == 'Bow'">
                <template v-if="members.SupportSkill == 'Doublehand'">
                  ({{ (Math.floor((calcPA + calcSpeed)/2) * itemschanged[members.Offhand].wp) * 2}})
                </template>
                <template v-else>
                  ({{ Math.floor((calcPA + calcSpeed)/2) * itemschanged[members.Offhand].wp}})
                </template>
              </template>


              <template v-if="itemschanged[members.Offhand].type == 'Gun' && itemschanged[members.Offhand].element ">

            <div class="aventail">
                70% ({{ Math.floor((members.Faith * 60 * 16 * itemschanged[members.Offhand].wp) / 10000)}}) -
                20% ({{ Math.floor((members.Faith * 60 * 20 * itemschanged[members.Offhand].wp) / 10000)}}) -
                10% ({{ Math.floor((members.Faith * 60 * 28 * itemschanged[members.Offhand].wp) / 10000)}})
            </div>

              </template>

              <template v-if="itemschanged[members.Offhand].type == 'Gun' && !itemschanged[members.Offhand].element ">
                ({{itemschanged[members.Offhand].wp * itemschanged[members.Offhand].wp}})
              </template>
                </span>


            </span>
      </div>





    <div v-if="members.Head" class="equipment head">
      <img class="ability-icon" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/head.png" alt="head">
      <img class="gear-icon" :src="IMG_URL_ITEMS + members.Head.replace(/\s+/g, '') + '.gif'" :alt='members.Head'>
      <span class="equipment-text" :class="{ bold: (notAbles.items.includes(members.Head)) }"  @click="e => e.target.classList.toggle('active')">{{ members.Head }}</span> 
    </div>
    <div v-if="members.Armor" class="equipment armor">
      <img class="ability-icon" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/body.png" alt="body">
      <img class="gear-icon" :src="IMG_URL_ITEMS + members.Armor.replace(/\s+/g, '') + '.gif'" :alt='members.Armor'>
      <span class="equipment-text" :class="{ bold: (notAbles.items.includes(members.Armor)) }"  @click="e => e.target.classList.toggle('active')">{{ members.Armor }}</span> 
    </div>
    <div v-if="members.Accessory" class="equipment accessory">
      <img class="ability-icon" src="https://luxor-fftbg-assets.s3.amazonaws.com/icons/accessory.png" alt="accessory">
      <img class="gear-icon" :src="IMG_URL_ITEMS + members.Accessory.replace(/\s+/g, '') + '.gif'" :alt='members.Accessory'>
      <span class="equipment-text" :class="{ bold: (notAbles.items.includes(members.Accessory)) }"  @click="e => e.target.classList.toggle('active')">{{ members.Accessory }}</span> 
    </div>
    <div class="status" v-if="itemschanged[members.Mainhand].permstatus || itemschanged[members.Offhand].permstatus || itemschanged[members.Head].permstatus || itemschanged[members.Armor].permstatus || itemschanged[members.Accessory].permstatus">
      <h3>Permanent Status</h3>
      <div v-for="perm of itemschanged[members.Mainhand].permstatus">
        {{perm.name}} ({{members.Mainhand}})
      </div>
      <div v-for="perm of itemschanged[members.Offhand].permstatus">
        {{perm.name}} ({{members.Offhand}})
      </div>
      <div v-for="perm of itemschanged[members.Head].permstatus">
        {{perm.name}} ({{members.Head}})
      </div>
      <div v-for="perm of itemschanged[members.Armor].permstatus">
        {{perm.name}} ({{members.Armor}})
      </div>
      <div v-for="perm of itemschanged[members.Accessory].permstatus">
        {{perm.name}} ({{members.Accessory}})
      </div>
    </div>
<div class="status" v-if="itemschanged[members.Mainhand].initstatus || itemschanged[members.Offhand].initstatus || itemschanged[members.Head].initstatus || itemschanged[members.Armor].initstatus || itemschanged[members.Accessory].initstatus">
  <h3>Initial Status</h3>
  <div v-for="init of itemschanged[members.Mainhand].initstatus">
    {{init.name}} ({{members.Mainhand}})
  </div>
  <div v-for="init of itemschanged[members.Offhand].initstatus">
    {{init.name}} ({{members.Offhand}})
  </div>
  <div v-for="init of itemschanged[members.Head].initstatus">
    {{init.name}} ({{members.Head}})
  </div>
  <div v-for="init of itemschanged[members.Armor].initstatus">
    {{init.name}} ({{members.Armor}})
  </div>
  <div v-for="init of itemschanged[members.Accessory].initstatus">
    {{init.name}} ({{members.Accessory}})
  </div>
</div>
  </div>
</div>
</template>
<script>


export default {
  props: {
    members: {},
    itemschanged: {},
    JobStats: {},
    charStats: {},
    thisTeamColor : "",
    notAbles: {},
    teamColor : "",
    enemyColor : "",
    classRank: {},
    allunits: {},
  },
  methods: {
      


    compatibilityFormula(myGender, mySign, theyGender, theySign) {




          switch (mySign) {

                  case "Serpentarius":
                    this.compatNum = "Neutral";
                    return this.compatNum

                  case "Gemini":

                    switch (theySign) {

                      case "Pisces":
                      case "Virgo":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Libra":
                      case "Aquarius":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Sagittarius":

                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum


                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                    
                   

                    case "Taurus":

                    switch (theySign) {

                      case "Leo":
                      case "Aquarius":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Virgo":
                      case "Capricorn":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Scorpio":

                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum


                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                    

                    case "Aries":

                    switch (theySign) {

                      case "Cancer":
                      case "Capricorn":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Sagittarius":
                      case "Leo":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Libra":

                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum


                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                    

                    //Pisces
                    case "Pisces":

                    switch (theySign) {

                      case "Sagittarius":
                      case "Gemini":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Cancer":
                      case "Scorpio":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Virgo":

                      
                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum



                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                    

                    //Aquarius
                    case "Aquarius":

                    switch (theySign) {

                      case "Taurus":
                      case "Scorpio":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Gemini":
                      case "Libra":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Leo":

                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum


                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                    

                    //Capricorn
                    case "Capricorn":

                    switch (theySign) {

                      case "Libra":
                      case "Aries":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Taurus":
                      case "Virgo":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Cancer":

                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum


                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                   

                    //Sagittarius

                    case "Sagittarius":

                    switch (theySign) {

                      case "Pisces":
                      case "Virgo":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Aries":
                      case "Leo":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Gemini":

                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum


                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                   

                    //Scorpio

                    case "Scorpio":

                    switch (theySign) {

                      case "Aquarius":
                      case "Leo":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Pisces":
                      case "Cancer":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Taurus":

                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum


                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                   

                    //Libra

                    case "Libra":

                    switch (theySign) {

                      case "Cancer":
                      case "Capricorn":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Aquarius":
                      case "Gemini":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Aries":

                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum


                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                   


                    // Virgo
                    case "Virgo":

                    switch (theySign) {

                      case "Sagittarius":
                      case "Gemini":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Capricorn":
                      case "Taurus":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Pisces":

                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum


                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                    


                    //Leo
                    case "Leo":

                    switch (theySign) {

                      case "Scorpio":
                      case "Taurus":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Sagittarius":
                      case "Aries":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Aquarius":

                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum


                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                 

                    //Cancer

                    case "Cancer":

                    switch (theySign) {

                      case "Libra":
                      case "Aries":
                        this.compatNum = "Bad";
                        return this.compatNum


                      case "Scorpio":
                      case "Pisces":
                        this.compatNum = "Good";
                        return this.compatNum


                      case "Capricorn":

                        if ( myGender == "Monster" || theyGender == "Monster") {
                          this.compatNum = "Bad";

                          return this.compatNum
                        }

                        if ( myGender == theyGender ) {
                          this.compatNum = "Worst";
                        } else {
                        this.compatNum = "Best";
                        }
                        return this.compatNum


                      default:
                        this.compatNum = "Neutral";
                        return this.compatNum
                    }
                   



          }
              
    }


      
  },
  data() {

    
    return {
      showExtra : false,
   
      IMG_URL : "https://luxor-fftbg-assets.s3.amazonaws.com/units/",
      IMG_URL_ITEMS : "https://luxor-fftbg-assets.s3.amazonaws.com/icons/",
      IMG_URL_COMP : "https://luxor-fftbg-assets.s3.amazonaws.com/compat/",
    }
  },
}
</script>